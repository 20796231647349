import { gql } from "@apollo/client";

export const AdminRemoveSubscription = gql`
  mutation AdminRemoveSubscription($subscriptionUid: String!) {
    adminRemoveSubscription(input: { subscriptionUid: $subscriptionUid }) {
      subscription {
        uid
      }
      errors
    }
  }
`;
