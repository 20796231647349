const getStallReservationPillFormat = state => {
  const successStates = ['approved']
  const pendingStates = ['requested', 'cancellation_requested', 'changes_requested']
  const failureStates = ['cancelled']
  const inbetweenStates = ['waitlisted']

  if (successStates.includes(state)) {
    return 'bg-green-300 text-green-700'
  }

  if (pendingStates.includes(state)) {
    return "bg-yellow-100 text-yellow-600"
  }

  if (failureStates.includes(state)) {
    return "bg-red-300 text-red-700"
  }

  if (inbetweenStates.includes(state)) {
    return "bg-blue-300 text-blue-700"
  }
  
  return "bg-gray-100 text-gray-700"
}

export default getStallReservationPillFormat
