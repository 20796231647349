import moment from "moment-timezone";

export const formatDateRange = (startDate, endDate) => {
  const startMonth = moment(startDate).format("MMMM");
  const endMonth = moment(endDate).format("MMMM");
  const startYear = moment(startDate).format("YYYY");
  const endYear = moment(endDate).format("YYYY");

  // Different years
  if (startYear !== endYear) {
    return [
      moment(startDate).format("MMMM Do YYYY"),
      "-",
      moment(endDate).format("MMMM Do YYYY"),
    ].join(" ");
  }

  // Different months
  if (startMonth !== endMonth) {
    return [
      moment(startDate).format("MMMM Do"),
      "-",
      moment(endDate).format("MMMM Do YYYY"),
    ].join(" ");
  }

  // Different days
  if (startDate !== endDate) {
    return [
      moment(startDate).format("MMMM Do"),
      "-",
      moment(endDate).format("Do YYYY"),
    ].join(" ");
  }

  // Same day
  return moment(endDate).format("MMMM Do YYYY");
};

export const formatDate = date => {
  return moment(date).format("MMMM Do YYYY");
};

// Check if date not passed
export const returnMinDateBasedOnToday = (today, start, end, inclusive) => {
  // Get difference of days between show start date and current date
  const differenceInDay = today.diff(start, "days");
  return calcMinDate(start, end, differenceInDay, inclusive);
};

export const returnMinDateBasedOnDate = (date, start, end, inclusive) => {
  const differenceInDay = start.diff(date, "days");
  return calcMinDate(date, end, differenceInDay, inclusive);
};

export const calcMinDate = (date, end, differenceInDay, inclusive) => {
  if (differenceInDay >= 1) {
    let returnDate;
    if (inclusive) {
      returnDate = moment(date).add(differenceInDay, "d");
    } else {
      returnDate = moment(date).add(differenceInDay + 1, "d");
    }
    if (moment(returnDate).isAfter(end)) {
      return end;
    }
    return returnDate;
  }
  if (date.isAfter(end)) {
    return end;
  }
  return date;
};
