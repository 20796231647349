import { gql } from "@apollo/client";

export default gql`
  query TaxRatesByShops {
    allShopTaxRates {
      uid
      displayName
      stripeTaxType
      internalTaxType
      percentage
      enabled
      createdAt
      updatedAt
      country
      description
      shop {
        uid
        name
        organization {
          uid
          name
        }
        show {
          uid
          name
        }
      }
    }
  }
`;
