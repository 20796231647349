import {gql} from '@apollo/client'
// FIXME: This should be a .gql file

export default gql`
  query($filterPastShows: Boolean) {
    shows(filterPastShows: $filterPastShows) {
      nodes {
        uid
        name
        logoUrl
        showDetail
        theme
        createdAt
        updatedAt
        venueUid
        organization {
          uid
          name
          createdAt
          updatedAt
        }
        showSegments {
          uid
          name
          startDate
          endDate
          createdAt
          updatedAt
        }
        venue {
          uid
          name
          stallTypes {
            uid
            name
            weeklyPrice
            circuitPrice
            seasonPrice
            cancellationFee
            imageUrl
          }
        }
      }
    }
  }
`
