import React from "react";

const TermsAndConditionsScreen = () => {
  React.useEffect(() => {
    // This is rendered by Rails, so reload to bypass react-router while also avoiding the wildcard
    window.location.reload();
  });
  return '';
};

export default TermsAndConditionsScreen;
