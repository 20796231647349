import { gql } from "@apollo/client";

export default gql`
  query GetAllVenues {
    allVenues {
      uid
      name
      createdAt
      organization {
        uid
        name
      }
      updatedAt
      stallTypes {
        uid
        name
        imageUrl
      }
    }
  }
`;
