import React, { useContext } from "react";
import { trainerTableContext } from "../../../utils/trainerTableContext";
import { orgTableContext } from "../../../utils/orgTableContext";

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  type,
  // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const { editRowNumber, setStallCount } = useContext.apply(
    this,
    type === "organization" ? [orgTableContext] : [trainerTableContext],
  );

  React.useEffect(() => {
    if (editRowNumber === index) {
      setStallCount(parseInt(initialValue));
    }
  }, [editRowNumber]);

  //   Changing stall count
  const onChange = e => {
    setValue(e.target.value);
    setStallCount(
      e.target.value === undefined ? parseInt(initialValue) : parseInt(e.target.value),
    );
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (index === editRowNumber) {
    return (
      <div className="w-full flex justify-center">
        <input
          type="number"
          className="w-full text-center rounded-lg"
          value={value}
          onChange={onChange}
        />
      </div>
    );
  } else {
    return <div className="w-full">{value}</div>;
  }
};

export default EditableCell;
