import { gql } from "@apollo/client";

export const AdminEditActiveProduct = gql`
  mutation adminEditActiveProduct($activeProductUid: String!, $enabledStatus: Boolean!) {
    adminEditActiveProduct(
      input: { activeProductUid: $activeProductUid, enabledStatus: $enabledStatus }
    ) {
      activeProduct {
        uid
      }
      errors
    }
  }
`;
