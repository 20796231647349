import React from "react";
import { useQuery } from "@apollo/client";
import GetShopsByOrganization from "../../../../apollo/query/admins/organizations/GetShopsByOrganization";
import Loading from "../../../shared/Loading";
import OrganizationShopEdit from "./OrganizationShopEdit";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const OrganizationCard = ({
  organization,
  removeOrganization,
  refetchOrganizationList,
}) => {
  const { loading, error, data, refetch } = useQuery(GetShopsByOrganization, {
    variables: {
      organizationUid: organization.uid,
    },
  });
  const navigate = useNavigate();

  let state = {
    state: {
      organization: organization,
      organizationShops: data ? data.organizationShops : null,
    },
  };

  const [showShopEdit, setShowShopEdit] = React.useState(false);

  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{organization.name}</p>
        </li>
        {/* Shops */}
        <li className="flex flex-basis-5 items-center justify-start">
          {loading ? (
            <Loading inline={true} color="gray" size="xl" />
          ) : (
            <button
              className="text-sm underline font-semibold"
              style={{ color: "#4286f4" }}
              disabled={data.organizationShops.length < 1}
              onClick={e => {
                setShowShopEdit(true);
              }}
            >
              {data.organizationShops.length}
            </button>
          )}
        </li>
        {/*  URL */}
        <li className="flex flex-basis-25 items-center justify-center">
          <p className="text-sm italic text-gray-400">{organization.url}</p>
        </li>
        {/* Info */}
        <li className="flex flex-basis-5 items-center justify-center">
          {loading ? (
            <Loading inline={true} color="gray" size="xl" />
          ) : (
            <FontAwesomeIcon
              icon={faInfoCircle}
              fixedWidth
              className="cursor-pointer text-blue-600"
              onClick={() => {
                navigate(PATHS.SUMMARY, state);
              }}
            />
          )}
        </li>
      </ul>
      {showShopEdit ? (
        <OrganizationShopEdit
          setShowShopEdit={setShowShopEdit}
          refetchOrganizationList={refetchOrganizationList}
          refetchOrganizationShop={refetch}
          shops={data.organizationShops}
        />
      ) : null}
    </div>
  );
};

export default OrganizationCard;
