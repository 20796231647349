import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { AddUserRole } from "../../../../apollo/mutation/admins/user_roles/AddUserRole";
import { RemoveUserRole } from "../../../../apollo/mutation/admins/user_roles/RemoveUserRole";
import Select from "react-select";
import { ORG_ROLES } from "../../../../constants/consts";

const UserEditRoleForm = ({
  user,
  setShowModal,
  refetchUsersData,
  setSearchFilter,
  setUserUid,
}) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [existingRoles, setExistingRoles] = useState([]);
  const [newRole, setNewRole] = useState("");

  const [addUserRole] = useMutation(AddUserRole);
  const [removeUserRole] = useMutation(RemoveUserRole);

  const options = user.organizations.map(organization => {
    return { value: organization.uid, label: organization.name };
  });

  let roleOptions = [];

  for (let [key, value] of Object.entries(ORG_ROLES)) {
    roleOptions.push({ value: value, label: key });
  }

  // Update role lists on user update
  React.useEffect(() => {
    setExistingRoles(
      user.organizationRoles.filter(
        role => role.organizationUid === selectedOrganization.value,
      ),
    );
  }, [user]);

  function onChangeOrganization(selected) {
    setSelectedOrganization(selected);
    setExistingRoles(
      user.organizationRoles.filter(role => role.organizationUid === selected.value),
    );
  }

  function removeRoleFromUser(roleUid) {
    removeUserRole({
      variables: { roleUid: roleUid },
    })
      .then(res => {
        setUserUid(user.uid);
        refetchUsersData();
      })
      .catch(e => console.error(e));
  }

  function addRole() {
    addUserRole({
      variables: {
        organizationUid: selectedOrganization.value,
        userUid: user.uid,
        role: newRole,
      },
    })
      .then(res => {
        setUserUid(user.uid);
        setSearchFilter("");
        refetchUsersData();
      })
      .catch(e => console.error(e));
  }

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6">
            {/* Heading */}
            <p className="text-3xl font-bold ">Edit User</p>
            {/* Org select dropdown */}
            <div className="flex flex-col">
              <p className="font-bold text-xl mt-4">Select Organization</p>
              <Select
                placeholder={"Select Organization"}
                className="mt-3"
                options={options}
                isSearchable={false}
                closeMenuOnSelect={true}
                closeMenuOnScroll={() => true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => onChangeOrganization(selected)}
              />
            </div>
            {/* Existing Roles */}
            <div className="flex flex-col h-48 mt-3 overflow-y-auto">
              {selectedOrganization ? (
                existingRoles.length > 0 ? (
                  existingRoles.map(role => (
                    <div key={role.uid} className="w-3/6 flex justify-between ">
                      <p>{role.role}</p>
                      <button
                        className="text-warning-red"
                        onClick={() => removeRoleFromUser(role.uid)}
                      >
                        Remove Role
                      </button>
                    </div>
                  ))
                ) : (
                  <p>No roles associated with this user</p>
                )
              ) : null}
            </div>
            {/* Add new Role */}
            {selectedOrganization ? (
              <>
                <Select
                  placeholder={"Select Role"}
                  className="mt-3"
                  options={roleOptions}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={() => true}
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={selected => setNewRole(selected.value)}
                />
                <button
                  className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                  disabled={newRole.length < 1}
                  onClick={addRole}
                >
                  Add New Role
                </button>
              </>
            ) : null}
          </div>
          <button
            onClick={() => setShowModal(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UserEditRoleForm;
