import { gql } from "@apollo/client";

export const AdminAddShow = gql`
  mutation AdminAddShow(
    $organizationUid: String!
    $name: String!
    $showDetail: String
    $venueUid: String!
    $logo: String!
    $contactUid: String
  ) {
    adminAddShow(
      input: {
        organizationUid: $organizationUid
        name: $name
        showDetail: $showDetail
        venueUid: $venueUid
        logo: $logo
        contactUid: $contactUid
      }
    ) {
      show {
        uid
        name
      }
      errors
    }
  }
`;
