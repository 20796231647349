import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const SubscriptionCard = ({ subscription, removeSubscritpion }) => {
  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{subscription.user.name}</p>
        </li>
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{subscription.shop.name}</p>
        </li>
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{subscription.notificationType}</p>
        </li>
        <li className="flex flex-basis-5 items-center justify-start">
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="cursor-pointer text-warning-red"
            onClick={() => {
              removeSubscritpion(subscription.uid);
            }}
          />
        </li>
      </ul>
    </div>
  );
};

export default SubscriptionCard;
