import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation UpdateUserProfile(
    $displayName: String
    $barnName: String
    $bio: String
    $avatar: String
  ) {
    updateUserProfile(
      input: {
        displayName: $displayName
        barnName: $barnName
        bio: $bio
        avatar: $avatar
      }
    ) {
      user {
        uid
        displayName
        firstName
        lastName
        bio
        barnName
        phone
        phoneVerified
        email
        emailVerified
        photoUrl
        firebaseId
        providerId
        avatarUrl
        organizations {
          uid
          name
          theme
          createdAt
          updatedAt
        }
        organizationRoles {
          uid
          organizationUid
          role
        }
      }
      errors
    }
  }
`;
