import React from "react";

const AddEmailModal = ({
  emails,
  setShowModal,
  text,
  setEmails,
  removeEmail,
  handleSubmitEmails,
  emailsInvalid,
  setEmailsInvalid,
}) => {
  // Performs updation/deletion/removal for a new email
  function handleEmailChange(email, index) {
    if (email.length < 1) {
      removeEmail(index);
      return;
    }
    const updateEmails = [...emails];
    updateEmails.splice(index, 1, email);
    setEmails(updateEmails);
  }

  return (
    <>
      <div className="md:hidden w-11/12 sm:w-8/12 h-97 sm:h-5/6 px-4 bg-white shadow rounded-lg absolute bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full py-3 px-4">
          {/* Heading */}
          <p className="font-semibold text-xl mt-4">Additional Emails</p>
          {/* Text */}
          <p style={{ color: "#9C9C9C" }}>{text}</p>
          {/* Warning */}
          {emailsInvalid && (
            <p className="text-sm text-warning-red font-semibold">
              One of the added email is invalid please check again!
            </p>
          )}
          {/* Email Inputs */}
          <div className="h-4/6 overflow-y-auto w-full">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Enter Email"
              className="rounded mt-3 w-full"
              value={emails[0] || ""}
              onChange={e => handleEmailChange(e.target.value, 0)}
              style={{ borderColor: "#B4B4BB" }}
            />
            {emails.length > 0 &&
              emails.map((email, index) => (
                <input
                  key={index}
                  type="text"
                  name="email"
                  value={emails[index + 1] || ""}
                  id={`email${index}`}
                  placeholder="Enter Email"
                  className="rounded mt-3 w-full"
                  style={{ borderColor: "#B4B4BB" }}
                  onChange={e => handleEmailChange(e.target.value, index + 1)}
                />
              ))}
          </div>
          {/* Action buttons */}
          <div className="flex flex-row w-full justify-end mt-auto mb-3">
            {/* Save Button */}
            <button
              className="text-white font-bold rounded bg-button-green shadow-md px-6 h-11 mr-3"
              onClick={handleSubmitEmails}
            >
              Confirm
            </button>
            {/* Discard Button */}
            <button
              className="text-button-green border font-bold rounded border bg-white px-6 h-11"
              style={{ borderColor: "#036D19" }}
              onClick={() => {
                setEmails([]);
                setShowModal(false);
                setEmailsInvalid(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div className="md:hidden opacity-80 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddEmailModal;
