import React from "react";
import _ from "lodash";

const DeliveryOrdersSummary = ({ orders }) => {
  const summary = _.sortBy(Object.entries(_.flatten(orders.map(({lineItems}) => lineItems)).reduce((h, lineItem) => {
    if (h.hasOwnProperty(lineItem.activeProduct.product.name)) {
      h[lineItem.activeProduct.product.name] += lineItem.calculatedQuantity;
    }
    else {
      h[lineItem.activeProduct.product.name] = lineItem.calculatedQuantity;
    }
    return h;
  }, {})), a => a[0]).map(([k,v]) => ({name: k, calculatedQuantity: v}));

  return (
    <div>
      <ul className="flex flex-row justify-between items-center mt-4">
        <li className="text-xs">MATERIALS</li>
        <li className="text-xs">QUANTITY NEEDED</li>
      </ul>
      {/* List of Materials alongwith required quantity */}
      <div className="h-full overflow-y-auto mt-2">
        {summary.length > 0 ? (

          summary.map(item => (
            <div
              key={item.name}
              className="flex flex-row items-center justify-between h-12 border-b"
              style={{ borderColor: "#E8E8E8" }}
            >
              {/* Material Name */}
              <p className="font-bold">{item.name}</p>
              {/* Required Quantity */}
              <p className="font-bold">{item.calculatedQuantity}</p>
            </div>
          ))
        ) : (
          <div className="mt-5 text-center">No Summary Available!</div>
        )}
      </div>
    </div>
  );
};

export default DeliveryOrdersSummary;
