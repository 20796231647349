import React from 'react';

const SummaryStat = ({name, value}) => {
  return (
    <div className="flex flex-col p-4 rounded border border-gray h-full justify-start items-start m-1">
      <div className="text-gray-700">{name}</div>
      <div className="text-black text-xl font-semibold">{value}</div>
    </div>
  )
};

export default SummaryStat;
