import React from "react";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { ChangeShopStatus } from "../../../../apollo/mutation/admins/shops/ChangeShopStatus";

const OrganizationShopEdit = ({
  shops,
  refetchOrganizationList,
  setShowShopEdit,
  refetchOrganizationShop,
}) => {
  const [changeShopStatus] = useMutation(ChangeShopStatus);

  //   options for enabling disbaling shop
  const options = [
    { value: true, label: "Enabled" },
    { value: false, label: "Disabled" },
  ];

  function handleChangeShopStatus(uid, status) {
    changeShopStatus({
      variables: {
        shopUid: uid,
        enabledStatus: status,
      },
    })
      .then(res => {
        refetchOrganizationList();
        refetchOrganizationShop();
        setShowShopEdit(false);
      })
      .catch(e => console.error(e));
  }
  return (
    <>
      <div className="w-11/12 sm:w-8/12 md:w-7/12 lg:w-6/12 xl:w-4/12 h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full">
          {/* Heading */}
          <p className="text-3xl font-bold mt-3">Organization Shops</p>
          {/* List */}
          <div className="py-8 px-6 mb-8 overflow-y-auto mt-4">
            {shops.map((shop, i) => (
              <div className={`flex mt-3 ${i + 1 === shops.length ? 'mb-16' : ''}`} key={shop.uid}>
                <p className="flex-basis-60">{shop.name}</p>
                <Select
                  className={`flex-basis-30`}
                  defaultValue={{
                    label: shop.enabled ? "Enabled" : "Disabled",
                    value: shop.enabled,
                  }}
                  styles={{
                    container: (base, state) => ({
                      ...base,
                      zIndex: state.selectProps.menuIsOpen ? 30 : 0,
                      overflow: 'visible',
                    }),
                  }}
                  options={options}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={() => true}
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={selected => handleChangeShopStatus(shop.uid, selected.value)}
                />
              </div>
            ))}
          </div>
          {/* Close Button */}
          <button
            onClick={() => {
              setShowShopEdit(false);
            }}
            className="bg-black h-12 text-white mt-auto mb-3"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default OrganizationShopEdit;
