import React, { useState } from "react";
import UpdateUserProfile from "../../../apollo/mutation/UpdateUserProfile";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faExclamationCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import AvatarCircle from "../../shared/AvatarCircle";
import { DirectUpload } from "@rails/activestorage";
import { DIRECT_UPLOAD_URL } from "../../../constants/consts";
import { displayableName } from "../../../utils/user.utils";

const UserCard = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [name, setName] = useState(displayableName(currentUser));
  const [barn, setBarn] = useState(currentUser.barnName ? currentUser.barnName : "");
  const [bio, setBio] = useState(currentUser.bio ? currentUser.bio : "");
  const [textCount, setTextCount] = useState(
    currentUser.bio ? currentUser.bio.length : 0,
  );
  const [maxCountReached, setMaxCountReached] = useState(false);
  const [nameNull, setNameNull] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [blob, setBlob] = useState({});

  const isBlobPresent = Object.keys(blob).length > 0;

  const [
    updateUserProfile,
    { loading: approveLoading, data: approveData, error: approveError },
  ] = useMutation(UpdateUserProfile);

  //Function called on pressing save changes to update profile
  const handleSubmit = e => {
    e.preventDefault();
    // if name not provided throw error
    if (name.length === 0) {
      setNameNull(true);
      return;
    } else {
      updateUserProfile({
        variables: {
          displayName: name,
          barnName: barn,
          bio: bio,
          avatar: isBlobPresent ? blob.signed_id : null,
        },
      }).then(data => {
        const userData = data.data.updateUserProfile.user;
        userData.viewMode = "trainer";
        setCurrentUser(userData);
        setToggleEdit(false);
      });
      return;
    }
  };

  // Function to update description text count
  const updateTextCount = text => {
    if (text.length < 110) {
      setMaxCountReached(false);
      setTextCount(text.length);
    } else {
      setMaxCountReached(true);
      setTextCount(text.length);
    }
  };

  // To reset name null error
  const resetNameNull = text => {
    if (text.length > 0) {
      setNameNull(false);
      return;
    }
  };

  function handleImageUpload(e) {
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    setImage({
      preview: fileUrl,
      raw: file,
    });

    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
      } else {
        setBlob(blob);
      }
    });
  }

  return (
    <div
      className={`bg-profile-gray rounded rounded-md w-full ${
        toggleEdit ? "md:w-7/12 h-72" : "md:w-3/6 h-64"
      }  px-6`}
    >
      <div className="flex flex-row w-full items-center pt-5">
        {/* Avatar image */}
        {!toggleEdit ? (
          <AvatarCircle
            currentUser={currentUser}
            size={16}
            borderSize={12}
            innerClassName={"w-full h-full object-fill"}
            containerClassName={"border-button-green flex-basis-30"}
          />
        ) : (
          <div className="flex">
            <div className="h-16 w-16 rounded-full border-button-green bg-white flex-basis-30">
              <label
                htmlFor="upload-button"
                className="flex h-full w-full items-center justify-center cursor-pointer"
              >
                {image.preview ? (
                  <img
                    src={image.preview}
                    className="w-full h-full object-fill object-cover rounded-full"
                  />
                ) : (
                  <>
                    <FontAwesomeIcon
                      className="w-full h-full object-fill object-cover rounded-full text-medium-gray"
                      icon={faPlus}
                      fixedWidth
                    />
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                className="hidden"
                onChange={handleImageUpload}
              />
            </div>
          </div>
        )}
        {!toggleEdit ? (
          // Non Editing View
          <div className="flex flex-row justify-between flex-basis-70 ml-6">
            {/* User Name */}
            <div className="flex flex-col flex-basis-60">
              <div className="text-black text-xs pb-1">TRAINER</div>
              <div
                className="text-black text-md pb-1"
                style={{ overflowWrap: "break-word" }}
              >
                {displayableName(currentUser)}
              </div>
            </div>
            {/* Barn Name */}
            <div className="flex flex-col flex-basis-40 ml-8">
              <div className="text-black text-xs pb-1">BARN</div>
              <div
                className="text-black text-md pb-1"
                style={{ overflowWrap: "break-word" }}
              >
                {currentUser.barnName ? currentUser.barnName : "N/A"}
              </div>
            </div>
          </div>
        ) : (
          // Editing View
          <form className="flex flex-col ml-4" onSubmit={e => handleSubmit(e)}>
            {/* Name */}
            <label className="text-black text-xs pb-1 mb-2" htmlFor="name">
              NAME
              <div className="relative">
                <input
                  id="name"
                  type="text"
                  className="focus:ring-2 focus:ring-blue-500 focus:text-black rounded pl-2 pr-3 h-9"
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                    resetNameNull(e.target.value);
                  }}
                />
                {/* warning icon */}
                {nameNull ? (
                  <span className="absolute text-warning-red top-1/2 left-full transform -translate-x-full -translate-y-2/4">
                    <FontAwesomeIcon icon={faExclamationCircle} fixedWidth size="lg" />
                  </span>
                ) : null}
              </div>
            </label>
            {/* Barn */}
            <label className="text-black text-xs pb-1 mb-2" htmlFor="barn">
              BARN
              <div>
                <input
                  id="barn"
                  type="text"
                  className="focus:ring-2 focus:ring-blue-500 focus:text-black rounded pl-2 pr-3 h-9"
                  value={barn}
                  onChange={e => setBarn(e.target.value)}
                />
              </div>
            </label>
            {/* Description */}
            <div className="flex flex-col relative w-56 lg:w-96 xl:w-97 sm:w-52">
              <label className="text-black text-xs pb-1 mb-2" htmlFor="bio">
                DESCRIPTION
                <div>
                  <input
                    id="bio"
                    type="text"
                    className="focus:ring-2 focus:ring-blue-500 focus:text-black pl-2 pr-8 absolute lg:w-96 xl:w-97 rounded h-9 sm:w-52"
                    value={bio}
                    maxLength="110"
                    onChange={e => {
                      setBio(e.target.value);
                      updateTextCount(e.target.value);
                    }}
                  />
                  {/* warning icon */}
                  {maxCountReached ? (
                    <span
                      data-testid="exclamation"
                      className="absolute text-warning-red top-1/2 ml-2 sm:ml-0 left-full transform -translate-x-full -translate-y-3/4"
                    >
                      <FontAwesomeIcon icon={faExclamationCircle} fixedWidth size="lg" />
                    </span>
                  ) : null}
                </div>
              </label>
              {/* Counter for descrption text */}
              <p
                className={
                  !maxCountReached
                    ? "text-black mt-6 self-end "
                    : "text-warning-red mt-6 self-end"
                }
              >
                {textCount}/110
              </p>
            </div>
            {/* Action Buttons */}
            <div className="flex w-full items-center justify-center mt-3">
              {/* Cancel button */}
              <button
                name="cancel"
                className="bg-gray-300 text-white text-sm justify-self-end px-3 py-1 rounded mr-2 lg:w-48 h-9"
                onClick={() => setToggleEdit(false)}
              >
                Cancel
              </button>
              {/* Save button */}
              <button
                name="save"
                className="bg-light-blue text-white text-sm justify-self-end px-3 py-1 rounded w-32 md:w-48 h-9"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
      {!toggleEdit && (
        <>
          {/* Description */}
          <div className="mt-6">
            <div className="text-black text-xs pb-1">DESCRIPTION</div>
            <div
              className="text-black text-md pb-1"
              style={{ overflowWrap: "break-word" }}
            >
              {currentUser.bio ? currentUser.bio : "No bio added!"}
            </div>
          </div>
          {/* Edit Button */}
          <div className="mt-1 lg:mt-4 flex justify-center items-center w-full">
            <button
              name="edit"
              className="font-medium text-base text-medium-gray"
              onClick={() => setToggleEdit(true)}
            >
              <FontAwesomeIcon icon={faPen} fixedWidth />
              Edit Profile
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UserCard;
