import { gql } from "@apollo/client";

export const AddUserRole = gql`
  mutation AddUserRole($organizationUid: String!, $userUid: String!, $role: String!) {
    adminAddRole(
      input: { organizationUid: $organizationUid, userUid: $userUid, role: $role }
    ) {
      organizationRole {
        role
      }
      errors
    }
  }
`;
