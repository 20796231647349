import React from 'react';
import { useQuery } from "@apollo/client";

import SummaryStats from './SummaryStats';
import SummarySection from './SummarySection';
import Loading from "../../shared/Loading";

import ShopsQuery from './apollo/ShopsQuery';

const InternalAdminHomeScreen = () => {
  const { loading, data, error, refetch } = useQuery(ShopsQuery);

  return (
    <>
      {loading ? (
        <div className="w-full flex flex-col justify-center items-center">
          <Loading size="4x" color="gray" inline={true} />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-start py-2">
          <SummaryStats />
          <SummarySection shops={data.allShops} />
        </div>
      )}
    </>
  )
}

export default InternalAdminHomeScreen
