import React, { useState, useContext } from "react";
import { orgTableContext } from "../../../utils/orgTableContext";
import Select from "react-select";

const StallTypeDropdown = ({ value, row: { index }, data, stallTypeSelectRef }) => {
  const { editRowNumber, setStallType, stallType } = useContext(orgTableContext);
  const defaultStallType = data[index].show.venue.stallTypes.filter(item => {
    if (data[index].stallType === item.name) {
      return item;
    }
  });

  React.useEffect(() => {
    if (editRowNumber === index) {
      if (defaultStallType) {
        setStallType({ name: defaultStallType[0].name, uid: defaultStallType[0].uid });
      }
    }
  }, [editRowNumber]);

  // Getting all possible stall type options
  const options = data[index].show.venue.stallTypes.map(data => {
    return { value: data.uid, label: data.name };
  });

  const handleSelect = selected => {
    setStallType({ name: selected.label, uid: selected.value });
  };

  if (index === editRowNumber) {
    return (
      <div className="flex flex-wrap">
        <Select
          className="w-full"
          options={options}
          defaultValue={{
            label: defaultStallType[0].name,
            value: defaultStallType[0].uid,
          }}
          isSearchable={false}
          closeMenuOnSelect={true}
          closeMenuOnScroll={() => true}
          hideSelectedOptions={false}
          isClearable={false}
          onChange={handleSelect}
          components={{
            IndicatorSeparator: () => null,
          }}
          menuPortalTarget={stallTypeSelectRef.target}
          menuPosition={"fixed"}
        />
      </div>
    );
  } else {
    return <div>{value}</div>;
  }
};

export default StallTypeDropdown;
