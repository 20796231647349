/* ===================================== AUDIT LOG ========================================= *
 * Title: Deliverer Home
 * Author: Faizan Omer
 * Created on: 03/25/22
 * Description: The screen that shows the entire deliverer's home view
 * ========================================================================================== */

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import OrdersToDeliver from "./OrdersToDeliver";
import DeliveryOrdersSummary from "./DeliveryOrdersSummary";
import { useQuery } from "@apollo/client";
import GetDeliveryOrdersByOrg from "../../../apollo/query/deliverers/GetOrdersByOrg";
import { useAuth } from "../../../contexts/AuthContext";
import ErrorModal from "../../shared/ErrorModal";
import { MODAL_MESSAGES, ORG_ROLES } from "../../../constants/consts";
import Loading from "../../shared/Loading";
import { useNavigate } from "react-router-dom";
import { default as _ } from "lodash";
import moment from 'moment-timezone';

const DelivererHome = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [summaryView, setSummaryView] = useState(false);
  const [ordersView, setOrdersView] = useState(true);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState("all");

  const { selectedOrganization, organizationRoles } = currentUser;
  const fetchOrganizationUid =
    selectedOrganization?.uid ||
    organizationRoles.filter(({ role }) => role === ORG_ROLES.DELIVERY)[0]
      ?.organizationUid;

  const { loading, error, data } = useQuery(GetDeliveryOrdersByOrg, {
    variables: {
      organizationUid: fetchOrganizationUid,
      deliveryDate: moment(deliveryDate).tz("America/Los_Angeles").format("YYYY-MM-DD"),
    },
  });

  let deliveryTimeOptions;
  let filteredOrders;

  //   Function renders the summary view
  function onViewSummary() {
    setOrdersView(false);
    setSummaryView(true);
  }

  //   Function renders the orders view
  function onViewOrders() {
    setSummaryView(false);
    setOrdersView(true);
  }

  if (data) {
    deliveryTimeOptions = Object.values(
      data.deliveryOrdersByOrg.reduce(
        (agg, item) => {
          return Object.assign(agg, {
            [item.deliveryTime.uid]: {
              value: item.deliveryTime.uid,
              label: _.capitalize(item.deliveryTime.name),
            },
          });
        },
        {
          all: {
            value: "all",
            label: "All",
          },
        },
      ),
    );

    filteredOrders = data.deliveryOrdersByOrg.filter(order => {
      return (
        order.deliveryTime.uid === deliveryTime || !deliveryTime || deliveryTime === "all"
      );
    });
  }

  // should render orders if returned data is not zero
  function shouldRenderOrders() {
    return data.deliveryOrdersByOrg && data.deliveryOrdersByOrg.length > 0;
  }

  // Function to handle time select and update state accordingly
  function handleTimeSelect({ value }) {
    setDeliveryTime(value);
  }

  //custom components for dropdown
  const customComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={faCaretDown} fixedWidth color="#036D19" size="lg" />
        </components.DropdownIndicator>
      );
    },
  };

  // custom dropdown styles for select dropdown
  const customDropdownStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFFF",
      "&:hover": {
        backgroundColor: "#036D19",
        color: "#FFFF",
      },
      color: state.isSelected ? "inherit" : "inherit",
    }),
  };

  // Custom calendar icon for datepicker
  const DatePickerCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="border rounded bg-white w-full flex flex-row justify-between items-center px-2 h-10"
      style={{ borderColor: "#B4B4BB" }}
      onClick={onClick}
      ref={ref}
    >
      <p className="text-sm">{value}</p>
      <FontAwesomeIcon icon={faCalendarDay} fixedWidth color="#036D19" />
    </button>
  ));

  if (error) {
    return (
      <ErrorModal
        buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
        onClick={() => window.location.reload()}
        heading={MODAL_MESSAGES.ERRORHEADING}
        text={MODAL_MESSAGES.ERRORTEXT}
      />
    );
  }
  return (
    <div className="w-screen h-full mt-10 px-4 sm:px-6 md:pl-20 md:pr-28 lg:pl-28 lg:pr-60 2xl:pr-96">
      {/* Orders Heading */}
      <p className="font-bold text-xl xl:text-2xl">Orders</p>
      <div className="flex flex-col w-full xl:flex-row">
        <div className="flex justify-between w-full mt-2 xl:w-6/12 xl:justify-start">
          <div className="w-3/6 mr-3 xl:w-4/12">
            {/* Date Picker */}
            <DatePicker
              className="w-4/12 sm:w-full"
              selected={deliveryDate}
              onChange={date => setDeliveryDate(date)}
              customInput={<DatePickerCustomInput />}
            />
          </div>
          {/* Time Picker */}
          <Select
            className="w-full sm:w-1/2 xl:w-7/12"
            placeholder={"Time of day"}
            options={deliveryTimeOptions}
            components={customComponents}
            isSearchable={false}
            closeMenuOnSelect={true}
            closeMenuOnScroll={() => true}
            hideSelectedOptions={false}
            isClearable={false}
            onChange={handleTimeSelect}
            styles={customDropdownStyles}
          />
        </div>
        {/* Order & summary Tabs */}
        <div
          className="flex flex-row items-center justify-between w-full xl:w-3/6 lg:ml-1 h-10 mt-2 rounded px-0.5"
          style={{ backgroundColor: "#E0E0E0" }}
        >
          <button
            className={
              ordersView ? "bg-white rounded shadow w-3/6 font-bold h-9" : "w-3/6"
            }
            onClick={onViewOrders}
          >
            Orders
          </button>
          <button
            className={
              summaryView ? "bg-white rounded shadow w-3/6 font-bold h-9" : "w-3/6"
            }
            onClick={onViewSummary}
          >
            Summary
          </button>
        </div>
      </div>
      {/* Render Orders or Summary Views based on tabs clicked */}
      {!loading ? (
        shouldRenderOrders() ? (
          ordersView ? (
            <OrdersToDeliver allOrders={filteredOrders} />
          ) : (
            <DeliveryOrdersSummary orders={filteredOrders} />
          )
        ) : (
          <div className="flex h-full items-center justify-center mt-20">
            No orders available for selected date!
          </div>
        )
      ) : (
        <div className="flex items-center justify-center mt-20">
          <Loading size="4x" color="gray" inline={true} />
        </div>
      )}
    </div>
  );
};

export default DelivererHome;
