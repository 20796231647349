import React from "react";
import { components } from "react-select";

export const Option = props => {
  return (
    <components.Option {...props}>
      <div className="flex">
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label className="pl-4">{props.label}</label>
      </div>
    </components.Option>
  );
};

export const ValueContainer = props => {
  var selectInput = React.Children.toArray(props.children).find(
    input => input.type.name === "Input" || input.type.name === "DummyInput",
  );
  return (
    <components.ValueContainer {...props}>
      <div className="flex flex-row">
        {/* Filter icon */}
        <img
          src={require("../../assets/filter.png")}
          className="h-4 w-4 opacity-40 mt-1"
        />
        <p className="text-gray-500 pl-2">FILTER</p>
        {selectInput}
      </div>
    </components.ValueContainer>
  );
};

export const SelectContainer = props => {
  return (
    <components.SelectContainer {...props}>
      <div className="border-white"></div>
    </components.SelectContainer>
  );
};
