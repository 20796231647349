import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import * as _ from 'lodash';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import OrdersTable from "./OrdersTable";
import Pagination from "./Pagination";

import { useAuth } from "../../../contexts/AuthContext";
import useLocalStorage from "../../../hook/useLocalStorage";
import Loading from "../../shared/Loading";
import ErrorModal from "../../shared/ErrorModal";
import { MODAL_MESSAGES, ORG_ROLES } from "../../../constants/consts";
import { formatDateRange } from "../../../utils/date.utils";

import Select, { components } from "react-select";

// import GetOrganizationOrders from "../../../apollo/query/orgAdmins/GetOrders";
import GetPaginatedOrganizationOrders from "../../../apollo/query/orgAdmins/GetPaginatedOrders";
import GetOrganizationShops from "../../../apollo/query/orgAdmins/GetShops";

const OrganizationOrdersScreen = () => {
  const { currentUser } = useAuth();
  const { selectedOrganization, organizationRoles } = currentUser;
  const [filterShopUid, setFilterShopUid] = useState(null);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useLocalStorage('perPage', 10);
  const [query, setQuery] = useState(null);

  const fetchOrganizationUid =
    selectedOrganization?.uid ||
    organizationRoles.filter(({ role }) => role === ORG_ROLES.DASHBOARD)[0]
      ?.organizationUid;

  const { loading, error, data, refetch } = useQuery(GetPaginatedOrganizationOrders, {
    variables: {
      organizationUid: fetchOrganizationUid,
      filterShopUid,
      perPage,
      page,
      q: query,
    },
  }, [filterShopUid, perPage, page]);

  const {
    loading: shopsLoading,
    error: shopsError,
    data: shopsData,
  } = useQuery(GetOrganizationShops, {
    variables: {
      organizationUid: fetchOrganizationUid,
    },
  });

  useEffect(() => {
    console.log(`current filterShopUid: ${filterShopUid}`)
  }, [filterShopUid])

  if (error || shopsError) {
    return (
      <ErrorModal
        buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
        onClick={() => window.location.reload()}
        heading={MODAL_MESSAGES.ERRORHEADING}
        text={MODAL_MESSAGES.ERRORTEXT}
      />
    );
  }

  useEffect(() => {
    refetch();
  }, [filterShopUid, page, perPage, query])

  const selectShopFilter = selected => {
    if (selected?.value !== filterShopUid) {
      setFilterShopUid(selected?.value);
    }
  }

  const selectPerPage = selected => {
    if (selected?.value !== perPage) {
      setPerPage(selected?.value);
    }
  }

  const selectPage = selected => {
    if (selected !== page) {
      setPage(selected);
    }
  }

  const submitSearch = q => {
    if (q !== query) {
      setQuery(q);
    }
  }

  return (
    <div className="flex flex-row justify-center px-12">
      <div className="flex flex-col min-w-lg mt-8">
        <div className="text-4xl flex flex-row justify-between px-6">
          <div className="text-4xl">Orders</div>
          <div className="flex flex-row justify-end items-center gap-3 pr-3">
            <div className="bg-button-green text-white h-full w-full rounded-full">
              <FontAwesomeIcon icon={faArrowDown} fixedWidth size="sm" />
            </div>
            <div className="text-button-green font-bold text-base">Export</div>
          </div>
        </div>
        <div className="flex flex-row justify-start w-full mb-4 px-6 mt-2">
          <form className="flex-shrink flex flex-row justify-start mt-3 mr-3">
            <input
              type="search"
              className="rounded text-xs 2xl:text-md"
              placeholder="Search..."
              onChange={({target}) => {
                submitSearch(target.value);
              }}
            />
          </form>
          <Select
            placeholder={"Filter by Shop"}
            className="flex-shrink mt-3 w-96 z-30 text-xs 2xl:text-md"
            options={shopsData ? shopsData.organizationShops.map(shop => ({
              label: `${shop.name} - ${formatDateRange(shop.startDate, shop.endDate)}`,
              value: shop.uid,
            })) : []}
            getValue={() => filterShopUid}
            isSearchable={true}
            isLoading={shopsLoading}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            isClearable={true}
            onChange={selected => selectShopFilter(selected)}
          />
        </div>
        {loading ? (
          <div className="min-w-full w-screen flex flex-col justify-center items-center">
            <Loading size="4x" color="gray" inline={true} />
          </div>
        ) : (
          <OrdersTable data={data} refetch={refetch} />
        )}
        {data && (
          <Pagination
            selectPage={newPage => selectPage(newPage)}
            selectPerPage={newPerPage => selectPerPage(newPerPage)}
            page={data.paginatedOrganizationOrders.pagination.page}
            perPage={data.paginatedOrganizationOrders.pagination.perPage}
            total={data.paginatedOrganizationOrders.pagination.total}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizationOrdersScreen;
