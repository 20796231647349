import {gql} from '@apollo/client'
// FIXME: This should be a .gql file
export default gql`
  mutation WaitlistStallReservation(
      $stallReservationUid: String!,
  ) {
    waitlistStallReservation(input: {
      stallReservationUid: $stallReservationUid
    }) {
      stallReservation {
        uid
        state
      }
      errors
    }
  }
`
