import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import * as _ from 'lodash';
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";

import Graphs from "./Graphs";
import Loading from "../../shared/Loading";
import ErrorModal from "../../shared/ErrorModal";
import { MODAL_MESSAGES } from "../../../constants/consts";

import AdminOrderStatsByDateQuery from "./apollo/AdminOrderStatsByDateQuery";

const thirtyDaysAgo = moment().tz("America/Los_Angeles").subtract(30, 'days').format('YYYY-MM-DD');
const dateToday = moment().tz("America/Los_Angeles").format('YYYY-MM-DD');
const beginningOfMonth = moment().tz("America/Los_Angeles").startOf('month').format('YYYY-MM-DD');
const beginningOfYear = moment().tz("America/Los_Angeles").startOf('year').format('YYYY-MM-DD');

const rangeFormatOptions = [
  {
    label: 'Last 30 Days',
    value: {
      startDate: thirtyDaysAgo,
      endDate: dateToday,
      customRange: false,
    },
  },
  {
    label: 'MTD',
    value: {
      startDate: beginningOfMonth,
      endDate: dateToday,
      customRange: false,
    },
  },
  {
    label: 'YTD',
    value: {
      startDate: beginningOfYear,
      endDate: dateToday,
      customRange: false,
    },
  },
  {
    label: 'Custom...',
    value: {
      customRange: true,
    },
  },
];

const DatePickerCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <button
    className="rounded bg-white flex flex-row justify-start items-center px-2 h-8"
    style={{ border: `1px solid #cccccc` }}
    onClick={onClick}
    ref={ref}
  >
    <p className="text-sm">{value}</p>
    <FontAwesomeIcon icon={faCalendarDay} fixedWidth color="#036D19" />
  </button>
));

const SummarySection = ({shops}) => {
  const [organizationUid, setOrganizationUid] = useState(null);
  const [shopUid, setShopUid] = useState(null);
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(dateToday);
  const [showDatePickers, setShowDatePickers] = useState(false);

  const { loading, data, error, refetch } = useQuery(AdminOrderStatsByDateQuery, {
    variables: {
      organizationUid,
      shopUid,
      startDate,
      endDate,
    },
  })

  useEffect(() => {
    refetch({
      variables: {
        organizationUid,
        shopUid,
        startDate,
        endDate,
      },
    });
  }, [organizationUid, shopUid, startDate, endDate])

  const organizationOptions = _.uniqBy(shops.map(shop => {
    return {
      label: shop.organization.name,
      value: shop.organization.uid,
    };
  }), ({value}) => value);

  const shopOptions = (orgUid) => {
    const filteredShops = orgUid ? shops.filter(shop => shop.organization.uid === orgUid) : shops
    return filteredShops.map(shop => {
      return {
        label: shop.name,
        value: shop.uid,
      }
    })
  }

  // Switch to grid
  return (
    <div className="flex flex-col bg-white rounded-lg shadow w-full p-4">
      <div className="flex flex-row justify-start items-center">
        <Select className="w-1/5"
          placeholder={'Filter by Organization'}
          autofocus
          backspaceRemovesValue={false}
          options={organizationOptions}
          isSearchable={false}
          isClearable={true}
          onChange={(option) => {
            setOrganizationUid(option?.value);
            setShopUid(null);
          }}
        />
        <Select className="w-1/5"
          placeholder={'Filter by Show'}
          autofocus
          backspaceRemovesValue={false}
          options={shopOptions(organizationUid)}
          isSearchable={false}
          isClearable={true}
          onChange={(option) => {
            setShopUid(option?.value);
          }}
        />
        <Select className="w-1/5"
          defaultValue={rangeFormatOptions[0]}
          autofocus
          backspaceRemovesValue={false}
          options={rangeFormatOptions}
          isSearchable={false}
          isClearable={false}
          onChange={(option) => {
            if (!option.value.customRange) {
              setStartDate(option.value.startDate);
              setEndDate(option.value.endDate);
            }
            setShowDatePickers(option.value.customRange);
          }}
        />
        {showDatePickers ? (
          <div className="flex flex-row justify-start items-center h-full">
            <DatePicker
              className=""
              minDate={moment(beginningOfYear, 'YYYY-MM-DD').toDate()}
              maxDate={moment(endDate, 'YYYY-MM-DD').toDate()}
              selected={moment(startDate, 'YYYY-MM-DD').toDate()}
              onChange={date => setStartDate(moment(date).format('YYYY-MM-DD'))}
              customInput={<DatePickerCustomInput />}
            />
            <DatePicker
              className=""
              minDate={moment(startDate, 'YYYY-MM-DD').toDate()}
              maxDate={moment(dateToday, 'YYYY-MM-DD').toDate()}
              selected={moment(endDate, 'YYYY-MM-DD').toDate()}
              onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
              customInput={<DatePickerCustomInput />}
            />
          </div>
        ) : ''}
      </div>
      {loading ? (
        <div className="w-full flex flex-col justify-center items-center">
          <Loading size="4x" color="gray" inline={true} />
        </div>
      ) : (
        <Graphs stats={data.adminOrderStatsByDay} />
      )}
    </div>
  )
}

export default SummarySection;
