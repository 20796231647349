import React from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase/app";
import "firebase/auth";

const validateFirstName = firstName => (!firstName ? "First Name is Required." : null);
const validateLastName = lastName => (!lastName ? "Last Name is Required." : null);
const validatePassword = password => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

  if (password.length < 8) {
    return "Passwords must be at least 8 characters";
  } else if (!password.match(passwordRegex)) {
    return "Passwords must contain at least: one numeric digit, one uppercase and one lowercase letter";
  }
};

const validatePasswordMatch = ({ password, confirmPassword }) => {
  const errors = {};

  if (password !== confirmPassword) {
    errors.password = "Password and Password Confirmation do not match";
  }

  return errors;
};

const validateAgreement = agreed =>
  !agreed ? "Please agree to terms and conditions to continue" : null;

const validateForm = values => {
  const errors = validatePasswordMatch(values);
  const agreementValidation = validateAgreement(values.agreed);
  if (agreementValidation) {
    errors.agreed = agreementValidation;
  }

  return errors;
};

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase())) {
    return "Email is not valid";
  }
};

const validatePhone = phone => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!re.test(String(phone).toLowerCase())) {
    return "Phone number is not valid";
  }
};

const handleFormErrors = (errors, touched) => {
  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <>
      {Object.keys(errors).map(key => {
        if (touched.hasOwnProperty(key)) {
          return (
            <div key={key} className="text-red-400 font-semibold text-sm">
              {errors[key]}
            </div>
          );
        }
      })}
    </>
  );
};

const RegistrationForm = ({ authError, setAuthError, loading, findOrCreateUser }) => {
  const { setCurrentUser } = useAuth();
  const { signUp } = useAuth();
  const navigate = useNavigate();

  // Function for signing up a user
  const handleSignUp = (values, actions) => {
    // e.preventDefault();
    signUp(values.email, values.password)
      .then(({ user, additionalUserInfo }) => {
        setAuthError(true);
        return findOrCreateUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            providerId: additionalUserInfo.providerId,
            firebaseId: user.uid,
          },
        });
      })
      .then(({ data }) => setCurrentUser(data.findOrCreateUser.user))
      .then(args => {
        navigate(PATHS.HOME);
        return args;
      })
      .catch(e => {
        setAuthError(e);
      });
  };

  return (
    <div className="w-full flex flex-col items-start mt-6">
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          email: "",
          phone: "",
          agreed: false,
        }}
        validate={validateForm}
        onSubmit={(values, actions) => {
          handleSignUp(values, actions);
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <div className="flex flex-col items-start">
              {handleFormErrors(props.errors, props.touched)}
              {authError && (
                <div key={authError.code} className="text-red-400 font-semibold text-sm">
                  {authError.message}
                </div>
              )}
              <div className="flex flex-row flex-wrap items-end">
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="firstName"
                    placeholder="First Name"
                    className=" rounded border-gray-400 shadow-sm text-xs"
                    validate={validateFirstName}
                  />
                </div>
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="lastName"
                    placeholder="Last Name"
                    className="rounded border-gray-400 shadow-sm text-xs"
                    validate={validateLastName}
                  />
                </div>
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="email"
                    placeholder="Email"
                    className="rounded border-gray-400 shadow-sm text-xs"
                    validate={validateEmail}
                  />
                </div>
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="phone"
                    placeholder="Phone Number"
                    className="rounded border-gray-400 shadow-sm text-xs"
                    validate={validatePhone}
                  />
                </div>
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="password"
                    placeholder="Password"
                    className="rounded border-gray-400 shadow-sm text-xs"
                    validate={validatePassword}
                  />
                </div>
                <div className="flex flex-col w-5/12 mr-2 my-0.5">
                  <Field
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="rounded border-gray-400 shadow-sm text-xs"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex flex-row items-center">
                  <Field
                    className={`rounded-sm ${
                      props.values.agreed ? "bg-light-blue" : "bg-light-gray"
                    }`}
                    type="checkbox"
                    name="agreed"
                    validate={validateAgreement}
                  />
                  <div className="text-xs ml-3">
                    I agree to all the{" "}
                    <Link to={PATHS.TERMS} className="font-normal text-blue-400">
                      Terms
                    </Link>{" "}
                    and{" "}
                    <Link to={PATHS.PRIVACY} className="font-normal text-blue-400">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="text-white rounded-sm shadow-sm px-6 py-1 w-36 h-8 my-4 bg-light-blue"
                onClick={args => setAuthError(null)}
                disabled={loading || Object.keys(props.errors) > 0}
              >
                <p className="text-xs font-bold">Create Account</p>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegistrationForm;
