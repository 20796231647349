import React from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_SCREEN_MODES, PATHS } from "../../../constants/consts";
import AppleLogo from "../../../assets/apple-sso-logo.png";
import FacebookLogo from "../../../assets/facebook-sso-logo.png";
import GoogleLogo from "../../../assets/google-sso-logo.png";
import firebase from "firebase/app";
import "firebase/auth";
import { useAuth } from "../../../contexts/AuthContext";

const isLogin = mode => mode === AUTH_SCREEN_MODES.LOGIN;

const SsoLinks = ({ mode, findOrCreateUser, loading, setAuthError, handleSuccess }) => {
  const { setCurrentUser } = useAuth();
  const navigate = useNavigate();

  // Handle Google Sign In/ Sign up
  function googleSignIn() {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    googleAuthProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
    return firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ user, additionalUserInfo, ...rest }) => {
        setAuthError(null);
        const { profile } = additionalUserInfo;
        const requestData = {
          variables: {
            firstName: profile && profile.given_name,
            lastName: profile && profile.family_name,
            email: profile.email,
            emailVerified: profile.verified_email,
            phone: user.phoneNumber,
            photoUrl: user.photoURL,
            displayName: user.displayName,
            providerId: additionalUserInfo.providerId,
            firebaseId: user.uid,
          },
        };
        return findOrCreateUser(requestData);
      })
      .then(({ data }) => {
        setCurrentUser(data.findOrCreateUser.user);
      })
      .then(args => {
        // If called from checkout guest flow it navigates to checkout screen
        if (handleSuccess) {
          handleSuccess();
          return;
        }
        navigate(PATHS.HOME);
        return args;
      })
      .catch(e => setAuthError(e));
  }

  return (
    <div className="flex flex-col w-full sm:w-3/4 items-start">
      <div className="flex flex-row py-4 w-full">
        <div className="flex-grow h-1/2 border-b-2 border-gray-300"></div>
        <div className="flex-grow-0 px-2 text-black font-base text-xs">
          Or sign {isLogin(mode) ? "in" : "up"} with
        </div>
        <div className="flex-grow h-1/2 border-b-2 border-gray-300"></div>
      </div>
      <div className="flex flex-row w-full space-x-2">
        <button
          className="w-full flex-grow flex-1 flex flex-col items-center ring-1 ring-black ring-opacity-2.5 shadow-lg bg-white rounded  py-3 w-full"
          onClick={googleSignIn}
        >
          <img className="h-6" src={GoogleLogo} alt="google logo" />
        </button>
        <button
          className="w-full flex-grow flex-1 flex flex-col items-center ring-1 ring-black ring-opacity-2.5 shadow-lg bg-white rounded  py-3"
          onClick={() => {
            const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
            facebookAuthProvider.addScope("email");
            return firebase
              .auth()
              .signInWithPopup(facebookAuthProvider)
              .then(({ user, additionalUserInfo, ...rest }) => {
                setAuthError(null);
                const { profile } = additionalUserInfo;
                const requestData = {
                  variables: {
                    firstName: profile && profile.given_name,
                    lastName: profile && profile.family_name,
                    email: user.email || profile.email,
                    emailVerified: user.emailVerified || profile.verified_email,
                    phone: user.phoneNumber,
                    photoUrl: user.photoURL,
                    displayName: user.displayName,
                    providerId: additionalUserInfo.providerId,
                    firebaseId: user.uid,
                  },
                };

                return findOrCreateUser(requestData);
              })
              .then(({ data }) => setCurrentUser(data.findOrCreateUser.user))
              .then(args => {
                navigate(PATHS.HOME);
                return args;
              })
              .catch(e => setAuthError(e));
          }}
        >
          <img className="h-6" src={FacebookLogo} alt="facebook logo" />
        </button>
        <button
          className="w-full flex-grow flex-1 flex flex-col items-center ring-1 ring-black ring-opacity-2.5 shadow-lg bg-white rounded  py-3"
          onClick={() => {
            const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");
            appleAuthProvider.addScope("email");
            appleAuthProvider.addScope("name");
            return firebase
              .auth()
              .signInWithPopup(appleAuthProvider)
              .then(({ user, additionalUserInfo, ...rest }) => {
                setAuthError(null);
                const { profile } = additionalUserInfo;
                const requestData = {
                  variables: {
                    firstName: profile && profile.given_name,
                    lastName: profile && profile.family_name,
                    email: profile.email,
                    emailVerified: profile.email_verified === "true",
                    phone: user.phoneNumber,
                    photoUrl: user.photoURL,
                    displayName: user.displayName,
                    providerId: additionalUserInfo.providerId,
                    firebaseId: user.uid,
                  },
                };

                return findOrCreateUser(requestData);
              })
              .then(({ data }) => {
                setCurrentUser(data.findOrCreateUser.user);
              })
              .then(args => {
                navigate(PATHS.HOME);
                return args;
              })
              .catch(e => setAuthError(e));
          }}
        >
          <img className="h-6" src={AppleLogo} alt="apple logo" />
        </button>
      </div>
    </div>
  );
};

export default SsoLinks;
