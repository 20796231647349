import { gql } from "@apollo/client";

export default gql`
  query GetAllUsers {
    allUsers {
      uid
      name
      email
      createdAt
      organizationRoles {
        uid
        organizationUid
        role
      }
      organizations {
        uid
        name
      }
      emailVerified
      phone
    }
  }
`;
