import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment-timezone';


const OrganizationNetRevenueGraph = ({stats}) => {
  const data = [{
    id: "Partner Revenue",
    color: "hsl(261, 70%, 50%)",
    data: stats.map(stat => {
      return {
        x: moment(stat.date, 'YYYY-MM-DD').toDate(),
        y: stat.stats.organizationNetRevenue/100
      }
    }),
  }]

  return (
    <ResponsiveLine

      data={data}
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      enablePoints={false}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day',
        min: 'auto',
        max: 'auto',
      }}
      xFormat={date => moment(date).format('MMM Do, YYYY')}
      yFormat={amount => `$${amount.toFixed(2)}`}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false
      }}
      axisTop={{
        legend: 'Partner Revenue',
        legendPosition: 'middle',
        legendOffset: -10,
        tickValues: 0,
      }}
      axisRight={null}
      axisBottom={{
        tickValues: 5,
        format: '%Y-%m-%d',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Date',
        legendOffset: 36,
        legendPosition: 'middle'
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}

    />
  )
}

export default OrganizationNetRevenueGraph;
