import React, { useState } from "react";
import Modal from "../../shared/Modal";
import { formatDate } from "../../../utils/date.utils";

const DetailsCell = ({ row }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleOpenDetails = e => {
    e.preventDefault();

    setDetailsOpen(true);
  };

  return (
    <>
      <button
        className="hover:underline text-xs 2xl:text-md font-bold text-button-green"
        onClick={handleOpenDetails}
      >
        Details
      </button>
      {detailsOpen && (
        <Modal
          buttonMessage={"Close"}
          onClick={e => {
            e.preventDefault();
            setDetailsOpen(false);
          }}
          heading={"Delivery Details"}
        >
          <div className="flex flex-col">
            {/* User and show details */}
            <div className="flex flex-row justify-between mt-3">
              {/* user details */}
              <div className="flex flex-basis-50 flex-col">
                <p className="text-xs font-semibold">USER DETAILS</p>
                <p className="capitalize text-xs">{row.values.userName}</p>
                <p className="text-xs">{row.original.user.email}</p>
                <p className="text-xs">{row.original.user.phone}</p>
              </div>
              {/* Show details */}
              <div className="flex flex-basis-50 flex-col">
                <p className="text-xs font-semibold">SHOW DETAILS</p>
                <p className="text-xs">{row.values.showName}</p>
              </div>
            </div>
            {/* DELIVERY DETAILS */}
            <div className="flex mt-4">
              <div className="flex flex-basis-50 flex-col">
                <p className="text-xs font-semibold">DELIVERY DETAILS</p>
                <p className="text-xs">
                  {row.original.deliveryDate && formatDate(row.original.deliveryDate)}{" "}
                  <span style={{ color: "#9C9C9C" }}>
                    ({row.original.deliveryTime.name})
                  </span>
                </p>
                <p className="text-xs">Delivered By: {row.original.deliveredBy?.name}</p>
                <p className="text-xs">Total: {row.original.totalStr}</p>
              </div>
              {/* Stabling With */}
              <div className="flex flex-basis-50 flex-col">
                <p className="text-xs font-semibold">STABLING WITH</p>
                <p className="text-xs capitalize">{row.original.stablingWith}</p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DetailsCell;
