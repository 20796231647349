import { gql } from "@apollo/client";

export const AdminAddShop = gql`
  mutation AdminAddShop(
    $uid: String
    $organizationUid: String!
    $name: String!
    $enabled: Boolean!
    $description: String
    $image: String!
    $showUid: String!
    $products: [CreateActiveProductInput!]!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $withholdFee: Boolean!
    $sameDay: Boolean!
    $contactUid: String!
  ) {
    adminAddShop(
      input: {
        uid: $uid
        organizationUid: $organizationUid
        products: $products
        enabled: $enabled
        startDate: $startDate
        endDate: $endDate
        description: $description
        showUid: $showUid
        name: $name
        image: $image
        withholdFee: $withholdFee
        sameDay: $sameDay
        contactUid: $contactUid
      }
    ) {
      shop {
        uid
        name
        activeProducts {
          uid
          product {
            uid
          }
        }
      }
      errors
    }
  }
`;
