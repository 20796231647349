import React from "react";
import Container from "./Container";
import { AuthProvider } from "./contexts/AuthContext";
import { ConfigProvider } from "./contexts/ConfigContext";

const App = ({config}) => {
  return (
    <ConfigProvider config={config}>
      <AuthProvider>
        <Container />
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
