import {gql} from '@apollo/client';

export const DeliveryUpdateOrder = gql`
  mutation DeliveryUpdateOrder($orderUid: String!, $lineItem: UpdateLineItemInput!, $confirmDelivery: Boolean) {
    deliveryUpdateOrder(
      input: {
        orderUid: $orderUid,
        lineItem: $lineItem,
        confirmDelivery: $confirmDelivery,
      }
    ) {
      order {
        uid
        deliveryStatus
        paymentStatus
        completedAt
        deliveredBy {
          uid
          displayName
        }
        shop {
          uid
        }
        user {
          uid
          name
          displayName
          firstName
          lastName
        }
        deliveryDate
        deliveryTime {
          uid
          name
        }
        lineItems {
          uid
          quantity
          isRefund
          calculatedQuantity
          delivered
          deliveredAt

          activeProduct {
            uid
            displayPrice
            price
            product {
              uid
              name
            }
          }
        }
      }
      errors
    }
  }
`;

// Sample Input
// {
//   "orderUid": "6005da",
//   "lineItem": {
//     "uid": "7c5985",
//     "delivered": true
//   },
//   "confirmDelivery": false
// }

// NOTE: The above was generated via a test, not seeds so you'll need ot replace values
