import React, { useState } from "react";
import Select from "react-select";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const BulkActionSelect = ({
  setSelectedOption,
  setShowConfirmationModal,
  isDisabled,
}) => {
  // Get Dropdown options for bulk actions
  const options = [
    { value: "approved", label: "Approve" },
    { value: "waitlist", label: "Waitlist" },
    { value: "cancel", label: "Cancel" },
  ];

  //  Selecting a Bulk Action
  const handleSelect = selected => {
    setSelectedOption(selected);
    setShowConfirmationModal(true);
  };

  // components passed to select component to be fully customized
  const customComponents = {
    ValueContainer: props => {
      return (
        <components.ValueContainer {...props}>
          <p className="text-gray-500 pl-2" style={{ color: "rgb(150,150,160)" }}>
            Bulk Actions
          </p>
        </components.ValueContainer>
      );
    },
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null,
    DropdownIndicator: props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon
            icon={faCaretDown}
            fixedWidth
            style={{ color: "#9696A0" }}
            size="lg"
          />
        </components.DropdownIndicator>
      );
    },
  };

  // custom CSS styles for react select component
  const customStyle = {
    container: (base, state) => ({
      ...base,
      zIndex: state.selectProps.menuIsOpen ? 30 : 0,
    }),
    control: base => ({
      ...base,
      // This line disable the blue border
      boxShadow: "none",
      //   "0px 4px 8px rgba(58, 58, 68, 0.16), 0px 8px 16px rgba(90, 91, 106, 0.16);",
      width: "100%",
      border: "1px solid #B4B4BB",
      "&:hover": {
        border: "1px solid #B4B4BB",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFFF",
      "&:hover": {
        backgroundColor: "#EDEDF0",
      },
      color: state.isSelected ? "inherit" : "inherit",
    }),
  };

  return (
    <div>
      <Select
        className="w-11/12 ml-5 sm:ml-12 mt-2"
        options={options}
        isSearchable={false}
        options={options}
        closeMenuOnSelect={true}
        components={customComponents}
        hideSelectedOptions={false}
        isClearable={false}
        styles={customStyle}
        onChange={handleSelect}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default BulkActionSelect;
