import React from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";

const Loader = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Outlet /> : <div>Loading Profile wait a moment</div>;
};

export default Loader;
