import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ShopSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { shop } = location.state;

  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Shop Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p className="mt-2"> {shop.name}</p>
        {/* Logo */}
        <p className="font-bold mt-3">Logo:</p>
        <img src={shop.imageUrl} className="mt-2 w-36 h-28" />
        {/* Organization */}
        <p className="font-bold mt-3">Organization:</p>
        <p className="mt-2"> {shop.organization.name}</p>

        {/* Show */}
        <p className="font-bold mt-3">Show:</p>
        <p className="mt-2"> {shop.show.name}</p>

        {/* Enabled Active Products */}

        <p className="font-bold mt-3">Enabled Active Products:</p>
        {shop.enabledActiveProducts.map(activeProduct => (
          <div className="flex justify-between mt-2" key={activeProduct.uid}>
            <img
              src={activeProduct.product.imageUrl}
              className="w-10 h-10 rounded-full"
            />
            <p>Name: {activeProduct.product.name}</p>
            <p>Display Price: {activeProduct.displayPrice}</p>
          </div>
        ))}

        {/* Delivery Times */}
        <p className="font-bold mt-3">Delivery Times:</p>
        {shop.deliveryTimes.map(deliveryTime => (
          <p className="mt-2" key={deliveryTime.uid}>
            {" "}
            {deliveryTime.name}
          </p>
        ))}

        {/* Description */}
        <p className="font-bold mt-3">Description:</p>
        <p className="mt-2"> {shop.description}</p>

        <p className="font-bold mt-3">Currency:</p>
        <p className="mt-2"> {shop.currency}</p>

        <p className="font-bold mt-3">Same Day Deliveries:</p>
        <p className="mt-2"> {shop.allowSameDayDeliveries ? "true" : "false"}</p>

        <p className="font-bold mt-3">Enabled Status:</p>
        <p className="mt-2"> {shop.enabled ? "true" : "false"}</p>

        <p className="font-bold mt-3">Start Date:</p>
        <p className="mt-2"> {shop.startDate}</p>
        <p className="font-bold mt-3">End Date:</p>
        <p className="mt-2"> {shop.endDate}</p>
        {/* Created At/Update At */}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {shop.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{shop.updatedAt}</p>
      </div>
    </div>
  );
};

export default ShopSummary;
