import React, { useEffect, useState, useContext } from "react";
import "firebase/auth";
import { auth } from "../config/firebaseConfig";
import { useConfig } from './ConfigContext';
import useLocalStorage from "../hook/useLocalStorage";
import Loading from "../components/shared/Loading";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [firebaseUser, setFirebaseUser] = useState("");
  const [currentUser, setCurrentUser] = useLocalStorage("currentUser", null);
  const [loading, setLoading] = useState(true);

  // on component mount we add a observer/listener to check if user has been set
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setFirebaseUser(user);
      setLoading(false);
    });
    // call unsubscrible function when component unmounted
    return unsubscribe;
  }, []);

  // Sign up a user with email and password
  const signUp = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  //login a user with email and password
  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    setCurrentUser(null);
    setFirebaseUser(null);
    return auth.signOut();
  };

  // When user requests password reset
  const resetPassword = email => {
    return auth.sendPasswordResetEmail(email, {
      url: 'https://app.backgate.io',
    });
  };

  // Confirm new password
  const confirmPasswordReset = (code, newPassword) => {
    return auth.confirmPasswordReset(code, newPassword);
  };

  const value = {
    currentUser,
    setCurrentUser,
    firebaseUser,
    setFirebaseUser,
    currentUser,
    setCurrentUser,
    login,
    logout,
    signUp,
    resetPassword,
    confirmPasswordReset,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <Loading size="6x" color="gray" />}
    </AuthContext.Provider>
  );
};
