import { gql } from "@apollo/client";

export default gql`
  query getProductsByOrganization($organizationUid: String!) {
    organizationProducts(organizationUid: $organizationUid) {
      uid
      name
      enabled
      imageUrl
    }
  }
`;
