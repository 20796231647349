import React from "react";

import Select from "react-select";
// import "bootstrap/dist/css/bootstrap.min.css";

import { setViewModeAndSelectedOrganization } from "../../../utils/user.utils";

import { VIEW_MODES } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";

const OrgSelect = ({ onOrgSelect, history }) => {
  const { currentUser, setCurrentUser } = useAuth();
  const { organizations, selectedOrganization, refreshCounter } = currentUser;

  const options = organizations.map(org => ({ label: org.name, value: org }));

  return (
    <Select
      className="w-96"
      value={options.filter(option => option.label === selectedOrganization?.name)}
      options={options}
      onChange={({ value: org }) => {
        setViewModeAndSelectedOrganization(
          currentUser,
          setCurrentUser,
          VIEW_MODES.ORG_USER,
          org,
        );
        typeof onOrgSelect === "function" && onOrgSelect();
      }}
      isSearchable={false}
      placeholder="Select Organization"
    />
  );
};

export default OrgSelect;
