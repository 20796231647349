import {gql} from '@apollo/client'
// FIXME: This should be a .gql file
export default gql`
  mutation RequestCancellationStallReservation(
      $stallReservationUid: String!,
      $note: String
  ) {
    requestCancellationStallReservation(input: {
      stallReservationUid: $stallReservationUid,
      note: $note
    }) {
      stallReservation {
        uid
        note
        state
      }
      errors
    }
  }
`
