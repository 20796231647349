import { gql } from "@apollo/client";

export default gql`
  query TaxRatesByProducts {
    allProductsTaxRates {
      uid
      displayName
      stripeTaxType
      internalTaxType
      percentage
      enabled
      createdAt
      updatedAt
      country
      description
      product {
        uid
        name
      }
    }
  }
`;
