import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query ($shopUid: String!) {
    shop(shopUid: $shopUid) {
      uid
      name
      imageUrl
      description
      organization {
        uid
        name
      }
      show {
        uid
        name
        showSegments {
          uid
          name
          startDate
          endDate
        }
      }
      enabledActiveProducts {
        uid
        price
        displayPrice
        sortOrder
        product {
          uid
          name
          imageUrl
          description
          shortDescription
          itemType
        }
      }
      activeProducts {
        uid
        price
        displayPrice
        sortOrder
        product {
          uid
          name
          imageUrl
          description
          shortDescription
        }
      }
      createdAt
      updatedAt
      endDate
      startDate
      allowSameDayDeliveries
      deliveryTimes {
        uid
        name
      }
    }
  }
`;
