import React, { useState } from 'react';
import Modal from '../../shared/Modal';
import { MODAL_MESSAGES } from '../../../constants/consts';
import { centsToDollarStr } from '../../../utils/money.utils';

const ItemsCell = ({
  row,
  value,
}) => {
  const [itemsOpen, setItemsOpen] = useState(false);

  const handleOpenItems = e => {
    e.preventDefault();
    setItemsOpen(true);
  }

  return (
    <>
      <button className="hover:underline text-xs 2xl:text-md font-bold text-button-green" onClick={handleOpenItems}>
        {value}
      </button>
      {
        itemsOpen && (
          <Modal
            buttonMessage={'Close'}
            onClick={e => {
              e.preventDefault();
              setItemsOpen(false);
            }}
            onClose={() => setItemsOpen(false)}
            heading={'Order Details'}
          >
            <div className="flex flex-col w-full overflow-y-scroll">
              <div className="flex justify-between z-20 sticky mt-3" style={{ color: "#D0D0D2" }}>
                <p className="flex flex-basis-50 items-center justify-start">ITEM</p>
                <p className="flex flex-basis-15 items-center justify-center">QUANTITY</p>
                <p className="flex flex-basis-25 items-center justify-end">COST</p>
              </div>
              {row.original.lineItems.filter(li => !li.isRefund).map(item => (
                <ul key={item.uid} className="flex justify-between mt-2">
                  <li className="flex flex-basis-50 items-center justify-start">{item.activeProduct.product.name}</li>
                  <li className="flex flex-basis-15 items-center justify-center">{item.calculatedQuantity}</li>
                  <li className="flex flex-basis-25 items-center justify-end">{centsToDollarStr(item.activeProduct.price*item.calculatedQuantity)}</li>
                </ul>
              ))}
            </div>
          </Modal>
        )
      }
    </>
  )
};

export default ItemsCell;
