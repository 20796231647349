import { gql } from "@apollo/client";

export default gql`
  query GetAllActiveProducts {
    allActiveProducts {
      uid
      product {
        uid
        name
        imageUrl
        organization {
          uid
          name
        }
      }
      shop {
        uid
        name
      }
      price
      displayPrice
      enabled
    }
  }
`;
