import React from "react";
import { ApolloProvider } from "@apollo/client";
import buildClient from "./apollo/buildClient";
import { useAuth } from "./contexts/AuthContext";
import { useConfig } from "./contexts/ConfigContext";
import Routing from "./components/navigation/Routing";
import BreakpointSizer from "./components/shared/BreakpointSizer";

const Container = () => {
  const { firebaseUser } = useAuth();
  const config = useConfig();
  let apolloClient = buildClient(firebaseUser, config);

  return (
    <ApolloProvider client={apolloClient}>
      <Routing />
      {config.SHOW_BREAKPOINT_SIZER && <BreakpointSizer />}
    </ApolloProvider>
  );
};

export default Container;
