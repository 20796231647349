import { gql } from "@apollo/client";

export const AddProduct = gql`
  mutation AddProduct(
    $organizationUid: String!
    $name: String!
    $enabled: Boolean!
    $description: String
    $shortDescription: String
    $image: String
    $itemType: String!
  ) {
    adminAddProduct(
      input: {
        organizationUid: $organizationUid
        name: $name
        enabled: $enabled
        shortDescription: $shortDescription
        description: $description
        image: $image
        itemType: $itemType
      }
    ) {
      product {
        uid
        name
        itemType
      }
      errors
    }
  }
`;
