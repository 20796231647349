/* ===================================== AUDIT LOG ========================================= *
 * title: QuantityCounter
 * Author: Faizan Omer
 * Created on: 03/02/22
 * Description: A small component for incrementing and decrementing quantity count
 * ========================================================================================== */

import React from "react";

const QuantityCounter = ({
  quantityCount,
  onChangeQuantity,
  onIncrementQuantity,
  onDecrementQuantity,
}) => {
  return (
    <div className="flex items-center mx-3 h-8 w-fit">
      {/* button for decrement */}
      <button
        className="p-2 w-full text-xs text-white mr-px"
        style={{ backgroundColor: "#D0D0D2" }}
        onClick={onDecrementQuantity}
      >
        &mdash;
      </button>
      <input
        className="w-11 h-8 p-1 text-center"
        style={{ border: "1px solid #CFCFCF" }}
        type="number"
        name="quantity"
        value={Number(quantityCount).toString()}
        onChange={e => onChangeQuantity(e)}
      />
      {/* button for increment */}
      <button
        className="p-2 w-full text-xs  text-white ml-px"
        style={{ backgroundColor: "#D0D0D2" }}
        onClick={onIncrementQuantity}
      >
        &#xff0b;
      </button>
    </div>
  );
};

export default QuantityCounter;
