import React, { useState, useEffect } from 'react';

const RefundQuantityInput = ({lineItem, order, enabled, refundQuantities, setRefundQuantities, classNames}) => {
  const { calculatedQuantity } = lineItem;

  const [currentRefundQuantity, setCurrentRefundQuantity] = useState(refundQuantities[lineItem.uid] || 0);
  let displayValue;

  if (enabled) {
    displayValue = parseInt(lineItem.calculatedQuantity - currentRefundQuantity);
  }
  else {
    displayValue = 0;
  }

  useEffect(() => {
    setCurrentRefundQuantity(refundQuantities[lineItem.uid] || 0)
  }, [refundQuantities])

  const handleChange = (e) => {
    let newVal = calculatedQuantity - e.target.value;
    if (newVal > calculatedQuantity) {
      newVal = calculatedQuantity;
    }
    else if (newVal < 0) {
      newVal = 0;
    }
    setRefundQuantities({
      ...refundQuantities,
      ...{
        [lineItem.uid]: newVal,
      },
    });
  };


  return (
    <div className={`flex flex-row justify-end ${classNames} w-1/5 border focus-within:ring-2 mx-2 rounded focus-within:ring-inset-2`}>
      <div className={`flex flex-basis-30 w-1/5 text-right`}>
        <input
          className={`border-0 focus:ring-0 focus:ring-offset-0 focust:ring-inset-0 rounded mr-3 ${enabled ? '' : 'bg-light-gray text-subdued-gray'}`}
          type="number"
          value={displayValue}
          onChange={handleChange}
          disabled={!enabled}
        />
      </div>

      <div className={`flex flex-basis-10 w-1/5 text-subdued-gray`}>{` / ${calculatedQuantity}`}</div>
    </div>
  )
};

export default RefundQuantityInput;
