/* ===================================== AUDIT LOG ========================================= *
 * Title: StoreSearch
 * Author: Faizan Omer
 * Created on: 03/24/22
 * Description: This component searches for a store
 * ========================================================================================== */

import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { PATHS } from "../../constants/consts";

const StoreSearch = () => {
  const navigate = useNavigate();
  const [searchShop, setSearchShop] = React.useState("");

  function handleSearchInputChange(e) {
    const inputValue = e.target.value;
    setSearchShop(inputValue.toLowerCase());
  }

  function handleSearchShop() {
    navigate(PATHS.HOME, {
      state: {
        searchShop,
      },
    });
  }

  return (
    <div className="relative">
      <form onSubmit={handleSearchShop}>
        <input
          className="border px-2  w-100% h-8 lg:h-10 2xl:h-12 md:w-auto rounded"
          style={{ borderColor: "#B4B4BB" }}
          name="search"
          onChange={handleSearchInputChange}
        />
        <span
          data-testid="search"
          className="absolute top-1/2 text-search-gray left-full transform -translate-x-full -translate-y-2/4 pr-2"
        >
          <button type="submit">
            <FontAwesomeIcon
              icon={faSearch}
              fixedWidth
              style={{ color: "#B4B4BB" }}
              size="lg"
            />
          </button>
        </span>
      </form>
    </div>
  );
};

export default StoreSearch;
