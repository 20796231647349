import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { AuthScreen } from "../../screens";
import { PATHS } from "../../../constants/consts";
import { useLocation, Navigate } from "react-router-dom";

const AuthRouting = ({ mode }) => {
  const { firebaseUser } = useAuth();
  const location = useLocation();
  return firebaseUser ? (
    <Navigate to={PATHS.HOME} state={{ from: location }} />
  ) : (
    <AuthScreen mode={mode} />
  );
};

export default AuthRouting;
