/* ===================================== AUDIT LOG ========================================= *
 * Title: Expand order
 * Author: Faizan Omer
 * Created on: 04/04/22
 * Description: Individual product delivery status is shown in a card format
 * ========================================================================================== */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ExpandOrder = ({ lineProduct }) => {
  return (
    <div
      className="h-14 mt-px sm:mt-0 rounded-md flex flex-row items-center w-full px-2 py-2 mb-2"
      style={{ backgroundColor: "#F7F9FA" }}
    >
      <p className="font-bold text-sm flex flex-basis-30 justify-start items-center">
        {lineProduct.activeProduct.product.name}
      </p>
      <p className="text-sm flex flex-basis-30 justify-start items-center text-center">
        x{lineProduct.calculatedQuantity}
      </p>
      {/* Delivery Status */}
      <div
        className="h-12 w-14 rounded-md flex justify-center items-center"
        style={
          lineProduct.delivered
            ? { backgroundColor: "#A6EA8A" }
            : { backgroundColor: "#E0E0E0" }
        }
      >
        <FontAwesomeIcon
          fixedWidth
          icon={faCheck}
          style={lineProduct.delivered ? { color: "#036D19" } : { color: "black" }}
        />
      </div>
    </div>
  );
};

export default ExpandOrder;
