import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query SummaryQuery(
    $mode: String!
  ) {
    adminSummaryStats(
      mode: $mode
    ) {
      activeOrganizations
      completedShops
      activeShops
      transactions
      orderStats {
        grossRevenue
        organizationNetRevenue
        backgateNetRevenue
        stripeNetRevenue
        refundTotal
      }
    }
  }
`;

// Example data
// {
//   "startDate": "2023-01-01",
//   "endDate": "2023-03-15",
//   "organizationUid": "hdhp",
//   "shopUid": "hdhp_1_shop",
// }
