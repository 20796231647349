import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { AddProduct } from "../../../../apollo/mutation/admins/products/AddProduct";
import Select from "react-select";
import { DirectUpload } from "@rails/activestorage";
import { DIRECT_UPLOAD_URL } from "../../../../constants/consts";

const AddProductForm = ({ setShowForm, organizations, refetchProductList }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedItemType, setSelectedItemType] = useState(null);
  const [blob, setBlob] = useState({});

  const [addProduct] = useMutation(AddProduct);

  const isBlobPresent = Object.keys(blob).length > 0;

  //   Adds new product to the system
  function addNewProduct(e) {
    e.preventDefault();

    addProduct({
      variables: {
        name: name,
        organizationUid: selectedOrganization,
        description: description,
        shortDescription: shortDescription,
        enabled: enabled,
        image: isBlobPresent ? blob.signed_id : null,
        itemType: selectedItemType,
      },
    })
      .then(res => {
        setShowForm(false);
        refetchProductList();
      })
      .catch(e => console.error(e));
  }

  //   Upload image to cloud/storage
  function handleProductImageUpload(e) {
    const file = e.target.files[0];
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setBlob(blob);
      }
    });
  }

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <form className="flex flex-col w-full px-10" onSubmit={addNewProduct}>
            <label htmlFor="name" className="flex flex-col mt-4">
              Name
              <input type="text" id="name" onChange={e => setName(e.target.value)} />
            </label>
            <label htmlFor="description" className="flex flex-col mt-4">
              Description
              <input
                type="text"
                id="description"
                onChange={e => setDescription(e.target.value)}
              />
            </label>
            <label htmlFor="short_description" className="flex flex-col mt-4">
              Short Description
              <input
                type="text"
                id="short_description"
                onChange={e => setShortDescription(e.target.value)}
              />
            </label>
            <Select
              placeholder={"Select Item Type"}
              className="mt-4 h-10"
              options={[
                {
                  label: 'Stall',
                  value: 'Stall',
                },
                {
                  label: 'Grain',
                  value: 'Grain',
                },
                {
                  label: 'Hay',
                  value: 'Hay',
                },
                {
                  label: 'Bedding',
                  value: 'Bedding',
                },
              ]}
              isSearchable={false}
              closeMenuOnSelect={true}
              closeMenuOnScroll={() => true}
              hideSelectedOptions={false}
              isClearable={false}
              onChange={selected => setSelectedItemType(selected.value)}
            />
            <Select
              placeholder={"Select Organization"}
              className="mt-4 h-10"
              options={organizations}
              isSearchable={false}
              closeMenuOnSelect={true}
              closeMenuOnScroll={() => true}
              hideSelectedOptions={false}
              isClearable={false}
              onChange={selected => setSelectedOrganization(selected.value)}
            />
            <label htmlFor="enabled" className="flex mt-4 items-center">
              Enabled
              <input
                type="checkbox"
                id="enabled"
                className="ml-3"
                value={enabled}
                onClick={() => setEnabled(!enabled)}
              />
            </label>
            <label className="mt-4">
              <input type="file" accept="images/*" onChange={handleProductImageUpload} />
            </label>
            <button
              type="submit"
              className="mt-5 text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
              disabled={name.length < 1 && selectedOrganization.length < 1}
            >
              Add Product
            </button>
          </form>
          <button
            onClick={() => setShowForm(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddProductForm;
