import {gql} from '@apollo/client';

// userUid: String
// stallCount: Int
// stallTypeUid: String
// showUid: String
// showSegmentUids: [String]

export const BulkCreateStallReservations = gql`
  mutation BulkCreateStallReservations($stallReservations: [CreateStallReservation!]!){
    bulkCreateStallReservations(input: {
      stallReservations: $stallReservations
    }) {
      stallReservations {
        stallCount
        showSegments {
          uid
          name
          startDate
          endDate
          createdAt
          updatedAt
        }
        stallType {
          id
          uid
          name
          count
          weeklyPrice
          circuitPrice
          seasonPrice
          cancellationFee
          venueUid
          imageUrl
          venue {
            id
            uid
            name
            organization {
              id
              uid
              name
              url
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        show {
          uid
          name
          logoUrl
          showDetail
          theme
          createdAt
          updatedAt
          venueUid
          venue {
            uid
            name
            createdAt
            updatedAt
          }
          organization {
            uid
            name
            createdAt
            updatedAt
          }
        }
      }
      errors
    }
  }
`
