import React, { useMemo, useState } from "react";
import StatePill from "./StatePill";
import SingleStatusChangeModal from "./SingleStatusChangeModal";
import EllipsisDropdown from "../../shared/Table/EllipsisDropdown";
import Table from "../../shared/Table";
import MultiPill from "../../shared/Table/MultiPill";
import MultiSelectColumnFilter from "../../shared/Table/MultiSelectColumnFilter";
import { orgTableContext } from "../../../utils/orgTableContext";
import calculateReservationPrice from "../../../utils/calculateReservationPrice";
import { displayableName } from "../../../utils/user.utils";
import EditableCell from "../../shared/Table/EditableCell";
import StallTypeDropdown from "../../shared/Table/StallTypeDropdown";
import OrgConfirmationModal from "./OrgConfirmationModal";

const OrganizationReservationsTable = ({ reservations, refetchReservations }) => {
  const [showSingleModal, setShowSingleModal] = useState(false);
  const [singleSelection, setSingleSelection] = useState(null);
  const [targetState, setTargetState] = useState(null);

  const [editRowNumber, setEditRowNumber] = useState(null); //This is the index number of the row we need to edit when user presses edit
  const [stallCount, setStallCount] = useState(); //To update stall count for editing no. of stall
  const [stallType, setStallType] = useState();
  const [week, setWeek] = useState();
  const [reservationUid, setReservationUid] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [showData, setShowData] = useState();
  const [showSegmentNames, setShowSegmentNames] = useState();

  const preppedData = reservations.map(reservation => {
    const { user, show, stallCount, stallType, showSegments } = reservation;
    return {
      ...reservation,
      username: displayableName(user),
      showSegmentNames: showSegments.map(({ name }) => name),
      showName: show.name,
      stallCount: stallCount,
      stallType: stallType.name,
      stallPrice: {
        circuitPrice: stallType.circuitPrice,
        weeklyPrice: stallType.weeklyPrice,
      }, //Getting circuit and weekly price to be calculated later
      reservationPrice: `$${calculateReservationPrice(
        stallCount,
        stallType,
        showSegments.map(({ uid }) => uid),
        show,
      ).toFixed(2)}`,
    };
  });

  const handleStatusChange = ({
    originalState,
    newState,
    rowValues,
    preppedStallReservation,
  }) => {
    setTargetState(newState);
    setSingleSelection(preppedStallReservation);
    setShowSingleModal(true);
  };

  const columns = useMemo(() => [
    {
      accessor: "uid",
      disableFilters: true,
    },
    {
      Header: "TRAINER",
      accessor: "username",
      disableFilters: true,
    },
    {
      Header: "SHOW",
      accessor: "showName",
      disableFilters: true,
    },
    {
      Header: "WEEK",
      accessor: "showSegmentNames",
      Cell: MultiPill,
      disableFilters: true,
    },
    {
      Header: "STALLS",
      accessor: "stallType",
      Cell: StallTypeDropdown,
      disableFilters: true,
    },
    {
      Header: "QUANTITY",
      accessor: "stallCount",
      Cell: EditableCell,
      disableFilters: true,
    },
    {
      Header: "TOTAL",
      accessor: "reservationPrice",
      disableFilters: true,
    },
    {
      Header: "STATUS",
      accessor: "state",
      Cell: StatePill,
      Filter: MultiSelectColumnFilter,
      filter: "includesSome",
    },
    {
      accessor: "edit",
      Cell: cellObj => (
        <EllipsisDropdown
          cellObj={cellObj}
          type={"organization"}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ]);

  return (
    <>
      {showSingleModal && (
        <SingleStatusChangeModal
          setShowSingleModal={setShowSingleModal}
          singleSelection={singleSelection}
          setSingleSelection={setSingleSelection}
          targetState={targetState}
          setTargetState={setTargetState}
          refetchReservations={refetchReservations}
        />
      )}
      <orgTableContext.Provider
        value={{
          editRowNumber,
          setEditRowNumber,
          stallCount,
          setStallCount,
          setStallType,
          setWeek,
          setReservationUid,
          setShowData,
          setShowSegmentNames,
          week,
        }}
      >
        <Table
          className="text-light-blue overflow-auto"
          type="organization"
          columns={columns}
          data={preppedData}
          getRowProps={row => ({
            style: {
              boxShadow:
                row.index === editRowNumber
                  ? "0px 10px 30px rgba(0, 0, 0, 0.2)"
                  : "0px 0px 0px rgba(0, 0, 0, 0.0)",
              borderRadius: row.index === editRowNumber ? 8 : 0,
            },
          })}
        />
        {openConfirmModal ? (
          <OrgConfirmationModal
            showModal={openConfirmModal}
            setShowModal={setOpenConfirmModal}
            isBulk={false}
            week={week}
            stallType={stallType}
            stallCount={stallCount}
            reservationUid={reservationUid}
            showData={showData}
            showSegmentNames={showSegmentNames}
          />
        ) : null}
      </orgTableContext.Provider>
    </>
  );
};

export default OrganizationReservationsTable;
