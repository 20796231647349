import React from 'react'
import GrossRevenueGraph from './GrossRevenueGraph';
import BackgateNetRevenueGraph from './BackgateNetRevenueGraph';
import OrganizationNetRevenueGraph from './OrganizationNetRevenueGraph';
import RefundTotalGraph from './RefundTotalGraph';

const Graphs = ({stats}) => {

  // Switch to grid
  // Titles at top
  // Gross - Refund
  // Partner - Net
  // Weeks as unit of measure
  // Format $ better

  // Transactions
  // Gross
  // Refunds
  // Partner
  // Net
  return (
    <div className="grid grid-cols-2" style={{height: 600}}>
      <GrossRevenueGraph stats={stats} />
      <RefundTotalGraph stats={stats} />
      <OrganizationNetRevenueGraph stats={stats} />
      <BackgateNetRevenueGraph stats={stats} />
    </div>
  )
}

export default Graphs;
