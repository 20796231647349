import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import GetShows from "../../../apollo/query/GetShows";
import ShowsSection from "./ShowsSection";
import StallTypeSection from "./StallTypeSection";
import NotifyModal from "../../shared/NotifyModal";
import ReservationForm from "./ReservationForm";
import { MODAL_MESSAGES } from "../../../constants/consts";

const TrainerReservationsScreen = ({ refetchMyReservations }) => {
  const [selectedShow, setSelectedShow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { loading, error, data } = useQuery(GetShows, {
    variables: {
      filterPastShows: true,
    },
  });

  return (
    <div className="flex flex-col items-start h-full">
      <div className="text-3xl text-black py-2">Reserve Stalls</div>
      <div className="bg-white w-full rounded-lg shadow">
        <ShowsSection
          loading={loading}
          error={error}
          data={data}
          selectedShow={selectedShow}
          setSelectedShow={setSelectedShow}
        />
        {selectedShow && (
          <>
            <StallTypeSection selectedShow={selectedShow} />
            <ReservationForm
              selectedShow={selectedShow}
              setShowModal={setShowModal}
              refetchMyReservations={refetchMyReservations}
            />
          </>
        )}
        {showModal ? (
          <NotifyModal
            setShowModal={setShowModal}
            action="confirmreservation"
            text={MODAL_MESSAGES.REQUESTSUBMISSIONTEXT}
            heading={MODAL_MESSAGES.REQUESTSUBMISSIONHEADING}
          />
        ) : null}
      </div>
    </div>
  );
};

export default TrainerReservationsScreen;
