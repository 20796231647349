import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Moment from "react-moment";
import AddEmailModal from "../../shared/AddEmailModal";
import { PATHS, MODAL_MESSAGES } from "../../../constants/consts";
import { formatDateRange } from "../../../utils/date.utils";
import { centsToDollarStr } from "../../../utils/money.utils";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { validateEmail } from "../../../utils/form_validation.utils";

const TrainerCheckout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    cartItems,
    subTotal,
    shopUid,
    shopStartDate,
    shopEndDate,
    showName,
    deliveryDate,
    deliveryTime,
    orderUid,
    stablingWith,
  } = location.state;

  const [dateRange, setDateRange] = useState("");
  const [emails, setEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailsInvalid, setEmailsInvalid] = useState(false);

  const failureUrl = `${PATHS.STOREFRONT.replace(":shopUid", shopUid)}?error=true`;

  // Navigates user back to cart screen
  function handleGoBack() {
    navigate(-1);
  }

  useEffect(() => {
    if (shopStartDate && shopEndDate) {
      setDateRange(formatDateRange(shopStartDate, shopEndDate));
    }
  }, [shopStartDate, shopEndDate]);

  // Function removes array element at the specified index
  function removeEmail(index) {
    const updateEmails = [...emails];
    updateEmails.splice(index, 1);
    setEmails(updateEmails);
  }

  function handleSubmitEmails() {
    if (emails.some(email => validateEmail(email) === "Email is not valid")) {
      setEmailsInvalid(true);
    } else {
      setEmailsInvalid(false);
      setShowEmailModal(false);
    }
  }

  return (
    <div className="flex flex-row justify-center md:justify-start relative">
      <div className="h-full w-auto md:w-full md:max-w-15 md:flex-basis-10"></div>
      <div className="flex flex-col px-3 w-full sm:w-11/12 md:w-full sm:items-center md:max-w-150 md:flex-basis-80 xl:max-w-200">
        <div className="sm:w-11/12 flex flex-col items-start  mt-3">
          {/* Review Order Heading */}
          <p className="font-bold md:text-3xl hidden md:inline md:mt-5">Review Order</p>
          {/* Show Name and Time */}
          <p className="text-xl font-bold mt-3">{showName}</p>
          <p>{dateRange}</p>
        </div>
        {/* Cart */}
        <div className="flex flex-col justify-between min-h-80 shadow-md ring-1 ring-black ring-opacity-5 rounded-lg mt-8 px-3 sm:w-11/12 md:w-full md:bg-cart md:ml-8">
          <div className="flex flex-col w-full h-full xl:px-6">
            <p className="text-2xl font-bold my-6">Cart</p>
            {/* Item details */}
            <div className="flex justify-between" style={{ color: "#D0D0D2" }}>
              <p className="flex flex-basis-50 items-center justify-start">ITEM</p>
              <p className="flex flex-basis-15 items-center justify-center">QUANTITY</p>
              <p className="flex flex-basis-25 items-center justify-end">COST</p>
            </div>
            {/* Display selected cart Items */}
            <div className="h-full pb-6">
              {cartItems &&
                cartItems.length > 0 &&
                cartItems.map(item => (
                  <div key={item.uid}>
                    <ul key={item.uid} className="flex justify-between mt-3">
                      <li className="flex flex-basis-50 items-center font-semibold justify-start">
                        {item.name}
                      </li>
                      <li className="flex flex-basis-15 items-center font-semibold justify-center">
                        {item.qty}
                      </li>
                      <li className="flex flex-basis-25 items-center font-semibold justify-end">
                        {centsToDollarStr(item.price * item.qty)}
                      </li>
                    </ul>
                    <ul key={`sub-${item.uid}`} className="flex justify-between mt-1">
                      <li className="flex flex-basis-50 items-start font-semibold justify-start text-xs 2xl:text-md text-cart-gray-mobile">
                        {item.shortDescription}
                      </li>
                      <li className="flex flex-basis-15 items-center justify-center">
                        {""}
                      </li>
                      <li className="flex flex-basis-25 items-center justify-end text-xs 2xl:text-md text-cart-gray-mobile">
                        {item.qty > 1 ? `${item.displayPrice} each` : ""}
                      </li>
                    </ul>
                  </div>
                ))}
            </div>
          </div>
          {/* Display subtotal */}
          <div className="flex justify-between px-2 py-3 pt-2 w-full border-t border-gray-300 ">
            <p className="font-bold text-lg">Subtotal:</p>
            <p className="text-lg">${subTotal}</p>
          </div>
        </div>
        {/* Display delivery date */}
        <div className="mt-8 sm:w-11/12 sm:px-3 md:px-0 md:w-full md:ml-8">
          <p className="font-bold">Delivery Window</p>
          <div className="flex mt-3 justify-between">
            <p className="text-sm 2xl:text-base">
              <Moment format="MM/DD/YYYY">{deliveryDate}</Moment>
            </p>
            <p>{deliveryTime.label}</p>
          </div>
        </div>
        {/* Stabling With */}
        <div className="sm:w-11/12 sm:px-3 md:px-0 md:w-full md:ml-8">
          <p className="mt-3 font-semibold">Who are you stabling with?</p>
          <input
            type="text"
            disabled={true}
            value={stablingWith}
            style={{ border: "1px solid #9BA3AF" }}
            className="rounded bg-gray-200 pl-2 pr-3 h-9 mt-2"
          />
        </div>
        {emails && emails.length > 0 && (
          <div className="mt-8 sm:w-11/12 sm:px-3 md:hidden">
            <p className="font-bold">Additional Emails to Notify:</p>
            <div className="flex mt-3 justify-between">
              <p>{emails.join(", ")}</p>
            </div>
          </div>
        )}
        {/* Add Email Button (Mobile) */}
        <button
          className="md:hidden mt-4 h-12 sm:w-11/12 border bg-white font-bold text-button-green rounded mt-2"
          style={{ borderColor: "#37833B" }}
          onClick={() => setShowEmailModal(true)}
        >
          Add emails to notify
        </button>
        {/* Add Email Field */}
        <div className="hidden md:inline w-full ml-8">
          <p className="mt-3 font-semibold">
            Additional emails for delivery notification{" "}
            <span className="text-medium-gray">(optional)</span>:
          </p>
          <ReactMultiEmail
            placeholder="Enter emails"
            className="w-full mt-2 text-lg rounded border-box-gray"
            emails={emails}
            onChange={_emails => {
              setEmails(_emails);
            }}
            validateEmail={email => {
              return isEmail(email); // return boolean
            }}
            getLabel={(email, index) => {
              return (
                <div data-tag key={index} className="">
                  {email}
                  <span
                    className="text-black font-bold text-md"
                    data-tag-handle
                    onClick={() => removeEmail(index)}
                  >
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
        <div className="mt-4 2xl:mt-10 flex flex-col justify-center items-center sm:w-11/12 md:w-full md:ml-8">
          {/* Continue to payment Button*/}
          <form
            action={"/payments/create-checkout-session-from-order"}
            method="POST"
            className="w-full"
          >
            <input type="hidden" name="order_uid" value={orderUid} />
            <input type="hidden" name="failure_url" value={failureUrl} />
            <input type="hidden" name="emails" multiple={true} value={emails} />
            <button
              type="submit"
              className="h-14 bg-button-green text-white font-bold w-full shadow-sm rounded"
            >
              Continue to Payment Method
            </button>
          </form>
          {/* Go back to Store Screen Button */}
          <button
            className="h-12 w-full border bg-white font-bold rounded mt-4 md:mt-2 mb-3"
            style={{ borderColor: "#37833B", color: "#37833B" }}
            onClick={handleGoBack}
          >
            Back to Feed &amp; Bedding Store
          </button>
        </div>
        {/* Add Email Pop-up (Mobile) */}
        {showEmailModal && (
          <AddEmailModal
            setShowModal={setShowEmailModal}
            setEmails={setEmails}
            text={MODAL_MESSAGES.EMAIL_NOTIFICATIONS}
            emails={emails}
            removeEmail={removeEmail}
            handleSubmitEmails={handleSubmitEmails}
            emailsInvalid={emailsInvalid}
            setEmailsInvalid={setEmailsInvalid}
          />
        )}
      </div>
    </div>
  );
};

export default TrainerCheckout;
