import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const VenueSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { venue } = location.state;
  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Venue Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p className="mt-2"> {venue.name}</p>
        {/* Organization */}
        <p className="font-bold mt-3">Organization:</p>
        <p className="mt-2"> {venue.organization.name}</p>
        {/* Stall Types */}

        <p className="font-bold mt-3">StallTypes:</p>
        {venue.stallTypes.length > 0 ? (
          venue.stallTypes.map(stallType => (
            <div className="flex justifiy-between mt-2" key={stallType.uid}>
              <img src={stallType.imageUrl} className="w-10 h-10 rounded-full" />
              <p className="ml-5">{stallType.name}</p>
            </div>
          ))
        ) : (
          <p>None</p>
        )}
        {/* Created At/Update At */}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {venue.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{venue.updatedAt}</p>
      </div>
    </div>
  );
};

export default VenueSummary;
