import React from 'react';

const BreakpointSizer = () => {
  return (
    <>
      <div className="absolute fixed bottom-0 right-0 z-100 inline sm:hidden">
        Smallest (default)
      </div>
      <div className="absolute fixed bottom-0 right-0 z-100 hidden sm:inline md:hidden">
        Small (sm)
      </div>
      <div className="absolute fixed bottom-0 right-0 z-100 hidden md:inline lg:hidden">
        Medium (md)
      </div>
      <div className="absolute fixed bottom-0 right-0 z-100 hidden lg:inline xl:hidden">
        Large (lg)
      </div>
      <div className="absolute fixed bottom-0 right-0 z-100 hidden xl:inline 2xl:hidden">
        X-Large (xl)
      </div>
      <div className="absolute fixed bottom-0 right-0 z-100 hidden 2xl:inline">
        2X-Large (2xl)
      </div>
    </>
  );
};

export default BreakpointSizer;
