import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="relative">
      <input
        type="search"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm pr-7 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
      <span
        data-testid="search"
        className="absolute top-1/2 text-search-gray left-full transform -translate-x-full -translate-y-2/4 pr-2"
      >
        <FontAwesomeIcon icon={faSearch} fixedWidth size="sm" />
      </span>
    </div>
  );
};

export default GlobalFilter;
