import React from "react";
import { useQuery } from "@apollo/client";
import TaxRatesByActiveProducts from "../../../../apollo/query/admins/tax_rates/TaxRatesByActiveProducts";
import Loading from "../../../shared/Loading";
import TaxRateCard from "./TaxRateCard";

const AdminTaxActiveProduct = () => {
  const { loading, data, error } = useQuery(TaxRatesByActiveProducts);

  const [searchFilter, setSearchFilter] = React.useState("");

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allActiveProductsTaxRates
      : data?.allActiveProductsTaxRates.filter(taxRate =>
          taxRate.activeProduct.product.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      {data &&
        !loading &&
        filteredData &&
        filteredData.map(taxRate => (
          <div key={taxRate.uid}>
            <TaxRateCard name={taxRate.activeProduct.product.name} taxRate={taxRate} />
          </div>
        ))}
    </div>
  );
};

export default AdminTaxActiveProduct;
