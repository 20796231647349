import React, {useEffect, useState} from 'react';
import Select, { components } from "react-select";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';

const PageSummary = ({page, perPage, total}) => {
  const pageStart = (page)*perPage + 1
  const pageEnd = (page + 1)*perPage

  return (
    <p className="text-black text-xs">
      Showing{" "}
      <span className="font-bold">
        {pageStart}
      </span>{" "}
      to{" "}
      <span className="font-bold">
        {pageEnd > total ? total : pageEnd}
      </span>{" "}
      of{" "}
      <span className="font-bold">
        {total}
      </span>{" "}
      Results
    </p>
  )
};

const PerPageSelect = ({perPage, selectPerPage}) => {
  return (
    <div className="flex flex-row justify-start items-center pr-8">
      <p className="text-xs pr-1">Items per Page:</p>
      <Select
        className="w-32"
        options={[
          {
            label: '10',
            value: 10,
          },
          {
            label: '25',
            value: 25,
          },
          {
            label: '50',
            value: 50,
          },
          {
            label: '100',
            value: 100,
          },
        ]}
        defaultValue={{value: perPage, label: perPage}}
        getValue={() => perPage}
        isSearchable={false}
        isLoading={false}
        closeMenuOnSelect={true}
        closeMenuOnScroll={true}
        isClearable={false}
        onChange={selected => selectPerPage(selected)}
      />
    </div>
  )
}

const PageEntry = ({selectPage, page, selected, ellipses}) => (
  <div
    className={`border border-black px-2 ${selected
        ? "bg-pagination-selected disabled"
        : (!ellipses && "cursor-pointer")
      }`
    }
    onClick={() => {
      if (ellipses) { return; }
      selectPage(page)
    }}
  >
    {ellipses ? '...' : page}
  </div>
)

const PageSelect = ({selectPage, page, perPage, total}) => {
  const pageCount = Math.ceil(total/perPage)
  console.log(`PageSelect.pageCount: ${pageCount}`);

  return (
    <ReactPaginate
      className={"flex flex-row justify-center items-center grow"}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      forcePage={page}
      previousLabel={(
        <div className="rounded-l border border-medium-gray cursor-pointer"
          onClick={() => {
            if (page > 0) {
              selectPage(page - 1)
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} fixedWidth size="sm" />
        </div>
      )}
      nextLabel={(
        <div className="rounded-r border border-medium-gray cursor-pointer grow"
          onClick={() => {
            if (page < pageCount - 1) {
              selectPage(page + 1)
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} fixedWidth size="sm" />
        </div>
      )}
      breakLabel={(
        <PageEntry selected={true} ellipses={true}/>
      )}
      onPageChange={p => selectPage(p.selected)}
      pageClassName={"border border-black px-2 grow"}
    />
  )
}

const ActionGroup = (props) => (
  <div className="flex flex-row justify-end items-center">
    <PerPageSelect {...props} />
    <PageSelect {...props} />
  </div>
)

const Pagination = (props) => {
  console.log(`pagination props: ${JSON.stringify(props)}`)
  return (
    <div className="flex flex-row w-full px-6 mb-8 mt-6 justify-between items-center">
      <PageSummary {...props} />
      <ActionGroup {...props} />
    </div>
  )
};

export default Pagination;
