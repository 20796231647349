import { gql } from "@apollo/client";

export const AdminRemoveOrganization = gql`
  mutation AdminRemoveOrganization($organizationUid: String!) {
    adminRemoveOrganization(input: { organizationUid: $organizationUid }) {
      organization {
        uid
        name
      }
      errors
    }
  }
`;
