export const validateFirstName = firstName =>
  !firstName ? "First Name is Required." : null;
export const validateLastName = lastName => (!lastName ? "Last Name is Required." : null);
export const validatePassword = password => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

  if (password.length < 8) {
    return "Passwords must be at least 8 characters";
  } else if (!password.match(passwordRegex)) {
    return "Passwords must contain at least: one numeric digit, one uppercase and one lowercase letter";
  }
};

export const validatePasswordMatch = ({ password, confirmPassword }) => {
  const errors = {};

  if (password !== confirmPassword) {
    errors.password = "Password and Password Confirmation do not match";
  }

  return errors;
};

export const validateAgreement = agreed =>
  !agreed ? "Please agree to terms and conditions to continue" : null;

export const validateForm = values => {
  const errors = validatePasswordMatch(values);
  const agreementValidation = validateAgreement(values.agreed);
  if (agreementValidation) {
    errors.agreed = agreementValidation;
  }

  return errors;
};

export const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase())) {
    return "Email is not valid";
  }
};

export const validatePhone = phone => {
  const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!re.test(String(phone).toLowerCase())) {
    return "Phone number is not valid";
  }
};
