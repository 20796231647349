import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const OrganizationSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { organization, organizationShops } = location.state;

  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Organization Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p className="mt-2"> {organization.name}</p>
        <p className="font-bold mt-3">URL:</p>
        <p className="mt-2">{organization.url}</p>
        <p className="font-bold mt-3">Shops:</p>
        {organizationShops.map(shop => (
          <p className="mt-2" key={shop.uid}>
            {shop.name}
          </p>
        ))}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {organization.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{organization.updatedAt}</p>
      </div>
    </div>
  );
};

export default OrganizationSummary;
