/* ===================================== AUDIT LOG ========================================= *
 * Title: Orders To Deliver
 * Author: Faizan Omer
 * Created on: 03/25/22
 * Description: This subcomponent of the delivererhome page is used to render the orders view
 * ========================================================================================== */

import React from "react";
import RenderOrder from "./RenderOrder";

const OrdersToDeliver = ({ allOrders }) => {
  return (
    <div className="px-2">
      <ul className="flex flex-row w-full items-center mt-4">
        <li className="text-xs flex-basis-30 flex text-left items-center justify-start">
          CUSTOMER INFO
        </li>
        <li className=" text-xs flex-basis-30 flex text-center items-center justify-center">
          STABLING WITH
        </li>
        <li className="text-xs flex-basis-30 flex text-right items-center justify-end">
          DELIVERED
        </li>
      </ul>
      {/* Rendering orders in a list */}
      <div className="mt-2 h-full overflow-y-auto mb-5">
        {allOrders.map(order => (
          <RenderOrder key={order.uid} initialOrder={order} />
        ))}
      </div>
    </div>
  );
};

export default OrdersToDeliver;
