import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { PATHS } from "../../../constants/consts";

const AdminNavigation = () => {
  const navigation = useNavigate();
  // Navigation Options
  const sections = [
    {
      path: PATHS.ADMIN_CONSOLE,
      icon: faUser,
      name: "Users",
    },
    {
      path: PATHS.ADMIN_PRODUCTS,
      icon: faUser,
      name: "Products",
    },
    {
      path: PATHS.ADMIN_ORGS,
      icon: faUser,
      name: "Organizations",
    },
    {
      path: PATHS.ADMIN_ACTIVE_PRODUCTS,
      icon: faUser,
      name: "Active Products",
    },
    {
      path: PATHS.ADMIN_SHOWS,
      icon: faUser,
      name: "Shows",
    },
    {
      path: PATHS.ADMIN_VENUES,
      icon: faUser,
      name: "Venues",
    },
    {
      path: PATHS.ADMIN_SHOPS,
      icon: faUser,
      name: "Shops",
    },
    {
      path: PATHS.ADMIN_TAX_RATE,
      icon: faUser,
      name: "Tax Rate",
    },
    {
      path: PATHS.ADMIN_SHOP_SUBSCRIPTION,
      icon: faUser,
      name: "Shop Subscription",
    },
  ];

  return (
    <div className="w-screen h-screen">
      <div className="flex flex-row w-full h-full">
        {/* Left navigation Bar*/}
        <div className="flex flex-col h-full sm:w-1/4 w-1/5 xl:w-1/6 pt-12 px-6 shadow-md overflow-y-auto">
          <p className="font-bold text-2xl">Admin Console</p>
          {sections.map(({ path, icon, name }) => (
            <button
              key={path}
              onClick={() => navigation(path)}
              className="flex flex-row mt-8 justify-start items-center"
            >
              <FontAwesomeIcon fixedWidth icon={icon} />
              <p className="pl-1 sm:text-xs lg:text-base">{name}</p>
            </button>
          ))}
        </div>
        {/* Page Content */}
        <div className="flex flex-col overflow-auto sm:w-3/4 w-4/5 xl:w-5/6 bg-light-gray pb-12 min-h-screen h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
