import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useMutation } from "@apollo/client";
import { Navigate } from "react-router-dom";
import ConfigureStripeAccount from "../../apollo/mutation/orgAdmins/ConfigureStripeAccount";
import Loading from "../shared/Loading";
import { PATHS } from "../../constants/consts";

const ConfigureStripeAccountRedirectScreen = () => {
  const { currentUser } = useAuth();
  const { organizations, selectedOrganization } = currentUser;
  const fetchOrganizationUid = (selectedOrganization || organizations[0]).uid;
  const [configureStripeAccount, { loading, error, data }] = useMutation(ConfigureStripeAccount);

  configureStripeAccount({
    variables: {
      organizationUid: fetchOrganizationUid,
    },
  });

  if (data) {
    const { redirectLink, errors } = data.configureStripeAccount;

    if (errors.length > 0) {
      return errors[0]
    }

    window.location.href = redirectLink;
  }

  if (error) {
    <Navigate to={PATH.HOME} />
  }

  return <Loading size="lg" color="black" inline={true} />
};

export default ConfigureStripeAccountRedirectScreen;
