import {gql} from '@apollo/client'
// FIXME: This should be a .gql file

export default gql`
  query {
    shops {
      nodes {
        uid
        name
        imageUrl
        description
        organization {
          uid
          name
        }
        show {
          uid
          name
          showSegments {
            uid
            name
            startDate
            endDate
          }
        }
      	activeProducts {
          uid
          price
          product {
            uid
            name
            imageUrl
            itemType
          }
        }
        createdAt
        updatedAt
        endDate
        startDate
        deliveryTimes {
          uid
          name
        }
      }
    }
  }
`
