import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";

const TaxRateCard = ({ name, taxRate }) => {
  const navigate = useNavigate();

  let state = {
    state: {
      taxRate: taxRate,
    },
  };
  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center px-10 font-bold">
      <div className="flex justify-between">
        <p>{name}</p>
        <FontAwesomeIcon
          icon={faInfoCircle}
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => navigate(PATHS.TAX_SUMMARY, state)}
        />
      </div>
    </div>
  );
};

export default TaxRateCard;
