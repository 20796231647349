import React from "react";

const ReservationCounter = ({ count }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    // Check if being viewed on mobile or desktop/laptop screen
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Setting a maximum width for mobile devices
  let isMobile = width <= 640;

  return (
    <div className="flex flex-row w-15per rounded-full bg-divider h-8 ml-14 mt-2.5 py-1">
      <div
        className="sm:w-auto sm:min-w-16.5per text-center rounded-full w-full sm:ml-2"
        style={{ backgroundColor: "#4A4B57", color: "#F7F7FA" }}
      >
        {count}
      </div>
      {!isMobile ? <p className="ml-2">Items selected</p> : null}
    </div>
  );
};

export default ReservationCounter;
