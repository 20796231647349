import { gql } from "@apollo/client";

export const AddOrganization = gql`
  mutation AddOrganization($name: String!, $url: String, $deliveryTime: String!) {
    adminAddOrganization(input: { name: $name, url: $url, deliveryTime: $deliveryTime }) {
      organization {
        uid
        name
        url
      }
      errors
    }
  }
`;
