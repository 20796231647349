import React from 'react';
import { useQuery } from "@apollo/client";

import SummaryStat from './SummaryStat';
import Loading from "../../shared/Loading";
import ErrorModal from "../../shared/ErrorModal";
import { MODAL_MESSAGES } from "../../../constants/consts";

import { centsToDollarStrSummary } from "../../../utils/money.utils";

import SummaryQuery from "./apollo/SummaryQuery";

const SummaryStats = () => {
  const {loading: ytdLoading, data: ytdData, error: ytdError} = useQuery(SummaryQuery, {
    variables: {
      mode: 'ytd',
    },
  })

  const {loading: mtdLoading, data: mtdData, error: mtdError} = useQuery(SummaryQuery, {
    variables: {
      mode: 'mtd',
    },
  })

  const {loading: wtdLoading, data: wtdData, error: wtdError} = useQuery(SummaryQuery, {
    variables: {
      mode: 'wtd',
    },
  })

  const stats = () => {
    const ytdStats = ytdData.adminSummaryStats;

    return [
      {
        name: 'Active Organizations',
        value: ytdStats.activeOrganizations,
      },
      {
        name: 'Completed Shows',
        value: ytdStats.completedShops,
      },
      {
        name: 'Active Shows',
        value: ytdStats.activeShops,
      },
    ]
  }

  const statsRows = () => {
    const ytdStats = ytdData.adminSummaryStats;
    const mtdStats = mtdData.adminSummaryStats;
    const wtdStats = wtdData.adminSummaryStats;

    return [
      {
        name: 'Transactions',
        wtd: wtdStats.transactions,
        mtd: mtdStats.transactions,
        ytd: ytdStats.transactions,
      },
      {
        name: 'Gross Revenue',
        wtd: centsToDollarStrSummary(wtdStats.orderStats.grossRevenue),
        mtd: centsToDollarStrSummary(mtdStats.orderStats.grossRevenue),
        ytd: centsToDollarStrSummary(ytdStats.orderStats.grossRevenue),
      },
      {
        name: 'Refunds',
        wtd: centsToDollarStrSummary(wtdStats.orderStats.refundTotal),
        mtd: centsToDollarStrSummary(mtdStats.orderStats.refundTotal),
        ytd: centsToDollarStrSummary(ytdStats.orderStats.refundTotal),
      },
      {
        name: 'Partner Revenue',
        wtd: centsToDollarStrSummary(wtdStats.orderStats.organizationNetRevenue),
        mtd: centsToDollarStrSummary(mtdStats.orderStats.organizationNetRevenue),
        ytd: centsToDollarStrSummary(ytdStats.orderStats.organizationNetRevenue),
      },
      {
        name: 'Net Revenue',
        wtd: centsToDollarStrSummary(wtdStats.orderStats.backgateNetRevenue),
        mtd: centsToDollarStrSummary(mtdStats.orderStats.backgateNetRevenue),
        ytd: centsToDollarStrSummary(ytdStats.orderStats.backgateNetRevenue),
      },
    ]
  }

  return (
    <div className="flex flex-col justify-start items-center my-4 p-4 bg-white rounded-lg shadow w-full">
      {ytdLoading || mtdLoading || wtdLoading ? (
        <div className="w-full flex flex-col justify-center items-center">
          <Loading size="4x" color="gray" inline={true} />
        </div>
      ) : (
        <>
          <div className="flex flex-row flex-wrap w-full">
            {stats().map((stat, i) => (<SummaryStat key={i} {...stat} />))}
          </div>
          <div className="grid grid-cols-4 w-full py-4 pr-4">
            <div className="py-2 bg-gray-50 text-gray-500 border-b border-gray-200"></div>
            <div className="py-2 bg-gray-50 text-gray-500 border-b border-gray-200">Week to Date</div>
            <div className="py-2 bg-gray-50 text-gray-500 border-b border-gray-200">Month to Date</div>
            <div className="py-2 bg-gray-50 text-gray-500 border-b border-gray-200">Year to Date</div>
            {statsRows().map(({name, wtd, mtd, ytd}, i) => (
              <>
                <div className={`pl-4 py-2 text-black font-semibold ${i % 2 !== 0 ? 'bg-gray-50' : ''}`}>{name}</div>
                <div className={`py-2 text-dark-gray ${i % 2 !== 0 ? 'bg-gray-50' : ''}`}>{wtd}</div>
                <div className={`py-2 text-dark-gray ${i % 2 !== 0 ? 'bg-gray-50' : ''}`}>{mtd}</div>
                <div className={`py-2 text-dark-gray ${i % 2 !== 0 ? 'bg-gray-50' : ''}`}>{ytd}</div>
              </>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default SummaryStats;
