import React from "react";
import ListModal from "../../../shared/ListModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";

const ShowCard = ({ show, openAddShowSegment }) => {
  const [showSegmentsModal, setShowSegmentsModal] = React.useState(false);
  const navigate = useNavigate();

  let state = {
    state: {
      show: show,
    },
  };

  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{show.name}</p>
        </li>
        {/* Organization */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{show.organization.name}</p>
        </li>
        {/* Venue */}
        <li className="flex flex-basis-15 items-center justify-start">
          <p className="text-sm">{show.venue.name}</p>
        </li>
        {/* Segments*/}
        <li className="flex flex-basis-15 items-center justify-center">
          <button
            className="text-sm underline font-semibold"
            style={{ color: "#4286f4" }}
            disabled={show.showSegments.length < 1}
            onClick={() => {
              setShowSegmentsModal(true);
            }}
          >
            {show.showSegments.length}
          </button>
        </li>
        {/* Edit show */}
        <li className="flex flex-basis-5 items-center justify-center">
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="cursor-pointer"
            onClick={() => openAddShowSegment(show)}
          />
        </li>
        <li className="flex flex-basis-5 items-center justify-center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            fixedWidth
            className="cursor-pointer text-blue-600"
            onClick={() => navigate(PATHS.SHOW_SUMMARY, state)}
          />
        </li>
      </ul>
      {showSegmentsModal && (
        <ListModal
          list={show.showSegments}
          setShowModal={setShowSegmentsModal}
          heading={"Show Segments"}
        />
      )}
    </div>
  );
};

export default ShowCard;
