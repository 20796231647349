import { gql } from "@apollo/client";

export default gql`
  query {
    myStallReservations {
      uid
      stallCount
      state
      stallType {
        uid
        name
        weeklyPrice
        circuitPrice
        seasonPrice
        cancellationFee
        imageUrl
      }
      showSegments {
        uid
        name
        startDate
        endDate
      }
      show {
        uid
        name
        logoUrl
        theme
        showDetail
        organization {
          name
        }
        showSegments {
          uid
          name
        }
        venue {
          uid
          name
          stallTypes {
            uid
            name
            weeklyPrice
            circuitPrice
            seasonPrice
            cancellationFee
            imageUrl
          }
        }
      }
    }
  }
`;
