import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation TrainerCreateOrder(
    $shopUid: String!
    $deliveryDate: ISO8601Date!
    $deliveryTimeUid: String!
    $lineItems: [CreateLineItemInput!]!
    $stablingWith: String!
  ) {
    trainerCreateOrder(
      input: {
        shopUid: $shopUid
        deliveryDate: $deliveryDate
        deliveryTimeUid: $deliveryTimeUid
        lineItems: $lineItems
        stablingWith: $stablingWith
      }
    ) {
      order {
        uid
        shop {
          uid
        }
        user {
          uid
        }
        deliveryDate
        deliveryTime {
          uid
          name
        }
        stablingWith
        lineItems {
          uid
          quantity
          isRefund
          calculatedQuantity
          activeProduct {
            uid
            price
            product {
              uid
              name
            }
          }
        }
      }
      errors
    }
  }
`;

// Sample Input:
// {
//   "shopUid": "langer_1_shop",
//   "deliveryDate": "2022-07-01",
//   "deliveryTimeUid": "leg_morning",
//   "lineItems": [
//     {
//       "activeProductUid": "alfalfa_bale",
//       "quantity": 2
//     },
//     {
//       "activeProductUid": "alfalfa_pellets",
//       "quantity": 7
//     }
//   ]
// }
