import React from "react";
import NotifyModal from "../../shared/NotifyModal";
import OrganizationReservationsTable from "./OrganizationReservationsTable";
import Loading from "../../shared/Loading";
import { MODAL_MESSAGES } from "../../../constants/consts";
import { useQuery } from "@apollo/client";
import GetOrgStallReservations from "../../../apollo/query/GetOrgStallReservations";
import { useAuth } from "../../../contexts/AuthContext";

const OrganizationReservationsScreen = () => {
  const { currentUser } = useAuth();
  const { selectedOrganization, organizations } = currentUser;
  const organizationUid = selectedOrganization?.uid || organizations[0].uid;
  const { loading, error, data, refetch } = useQuery(GetOrgStallReservations, {
    variables: { organizationUid },
  });
  return (
    <div>
      <h1 className="text-3xl font-semibold">All Reservations</h1>
      {!error ? (
        loading ? (
          <Loading />
        ) : (
          <OrganizationReservationsTable
            reservations={data?.orgStallReservations}
            refetchReservations={refetch}
          />
        )
      ) : (
        <NotifyModal
          action="errormessage"
          text={MODAL_MESSAGES.ERRORTEXT}
          heading={MODAL_MESSAGES.ERRORHEADING}
        />
      )}
    </div>
  );
};

export default OrganizationReservationsScreen;
