import {gql} from '@apollo/client'
// FIXME: This should be a .gql file
export default gql`
  mutation BulkWaitlistStallReservation(
      $stallReservationUids: [String!]!,
      $note: String
  ) {
    bulkWaitlistStallReservation(input: {
      stallReservationUids: $stallReservationUids,
      note: $note
    }) {
      stallReservations {
        uid
        note
        state
      }
      errors
    }
  }
`
