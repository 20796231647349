import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import BulkApprove from "../../../apollo/mutation/stallReservations/BulkApprove";
import BulkWaitlist from "../../../apollo/mutation/stallReservations/BulkWaitlist";
import BulkCancel from "../../../apollo/mutation/stallReservations/BulkCancel";
import ConfirmationTable from "./ConfirmationTable";
import StatePill from "./StatePill";
import OrganizationEdit from "../../../apollo/mutation/stallReservations/OrganizationEdit";
import calculateReservationPrice from "../../../utils/calculateReservationPrice";

const OrgConfirmationModal = ({
  showModal,
  setShowModal,
  isBulk, //For Bulk actions
  selectedRows,
  selectedOption,
  week,
  stallType,
  stallCount,
  reservationUid,
  showData,
  showSegmentNames,
}) => {
  // const { week, stallType, stallCount, reservationUid, showData } =
  //   useContext(orgTableContext);
  const [note, setNote] = useState(""); //For storing state of customer note
  const [maxCountReached, setMaxCountReached] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [bulkApproveStallReservation] = useMutation(BulkApprove);
  const [bulkWaitlistStallReservation] = useMutation(BulkWaitlist);
  const [bulkCancelStallReservation] = useMutation(BulkCancel);
  const [editStallReservation] = useMutation(OrganizationEdit);

  React.useEffect(() => {}, [week, stallType, stallCount, reservationUid, showData]);

  const tableData =
    selectedRows && selectedRows.length > 0
      ? selectedRows.map(row => row.values)
      : [showData]; //Take values from the selected row(s) to pass as table data

  if (isBulk) {
    let state;
    if (selectedOption.value === "approved") {
      state = "approved";
    } else if (selectedOption.value === "waitlist") {
      state = "waitlisted";
    } else if (selectedOption.value === "cancel") {
      state = "cancelled";
    }
    tableData.forEach(element => (element.state = state));
  } else {
    let newPrice;
    if (stallCount && stallType && week && reservationUid && showData) {
      newPrice = calculateReservationPrice(
        stallCount,
        showData.stallPrice,
        showData.showSegments.map(({ uid }) => uid),
        showData.show,
      ).toFixed(2);
    }
    tableData[0].stallCount = stallCount;
    tableData[0].stallType = stallType.name;
    tableData[0].showSegmentNames = showSegmentNames;
    tableData[0].reservationPrice = newPrice;
  }

  //Generate columns for showing confirm changes table
  const columns = useMemo(() => [
    {
      accessor: "username",
      disableFilters: true,
    },
    {
      accessor: "showName",
      disableFilters: true,
    },
    {
      accessor: "showSegmentNames",
      Cell: ({ value }) => {
        return (
          <div className="flex flex-row flex-wrap">
            {value.map(item => (
              <div key={item} className="bg-gray-200 rounded-md px-2 my-1 mx-1">
                {item}
              </div>
            ))}
          </div>
        );
      },
      disableFilters: true,
    },
    {
      accessor: "stallType",
      disableFilters: true,
    },
    {
      accessor: "stallCount",
      disableFilters: true,
    },
    {
      accessor: "reservationPrice",
      disableFilters: true,
    },
    {
      Header: "STATUS",
      accessor: "state",
      Cell: StatePill,
    },
  ]);

  // Function to update description text count
  const updateTextCount = text => {
    if (text.length < 110) {
      setMaxCountReached(false);
      setTextCount(text.length);
    } else {
      setMaxCountReached(true);
      setTextCount(text.length);
    }
  };

  const handleSave = () => {
    // To save Bulk Action
    if (isBulk) {
      const uids = selectedRows.map(row => row.values.uid);
      //Case: Approve is selected
      if (selectedOption.value === "approved" && selectedRows.length !== 0) {
        bulkApproveStallReservation({
          variables: { stallReservationUids: uids },
        }).then(() => {
          window.location.reload();
        });
      } //Case: WaitList is selected
      else if (selectedOption.value === "waitlist" && selectedRows.length !== 0) {
        bulkWaitlistStallReservation({
          variables: { stallReservationUids: uids },
        }).then(() => {
          window.location.reload();
        });
      } //Case: Cancel is selected
      else if (selectedOption.value === "cancel" && selectedRows.length !== 0) {
        bulkCancelStallReservation({
          variables: { stallReservationUids: uids },
        }).then(() => {
          window.location.reload();
        });
      }
      //TODO: Implement error case
      else {
        // FIXME
      }
    }
    //To Save single row
    else {
      if (stallCount && stallType && week && reservationUid && showData) {
        const newPrice = calculateReservationPrice(
          stallCount,
          showData.stallPrice,
          showData.showSegments.map(({ uid }) => uid),
          showData.show,
        ).toFixed(2);
        if (newPrice) {
          editStallReservation({
            variables: {
              stallReservationUid: reservationUid,
              stallCount: stallCount,
              note: "",
              showSegmentUids: week,
              stallTypeUid: stallType.uid,
            },
          }).then(window.location.reload());
        }
      }
    }
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-9/12 my-6 mx-auto 2xl:w-6/12">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full max-h-98 bg-white outline-none focus:outline-none px-4">
                <div className="relative py-6 px-2 flex-auto flex flex-col justify-center">
                  {/* Heading title */}
                  <div className="flex flex-row">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      fixedWidth
                      className="bg-white opacity-50 pt-1"
                      size="lg"
                    />
                    <p className="font-bold pl-2">Change Status</p>
                    {/* <ReservationCounter count={selectedRows.length} /> */}
                  </div>
                  {/* Show Changes for Bulk Action */}
                  {isBulk ? <div></div> : null}
                  {/* Note to user */}
                  <div>
                    <p className="text-dark-gray text-xs mt-2">
                      The following changes will be submitted.{" "}
                    </p>
                  </div>
                  {/* Show the rows that are changing */}

                  <div>
                    <div className="font-bold pl-4 mt-4">Changes:</div>
                    <div className="w-full overflow-auto max-h-40 mt-3 border border-solid border-black rounded-xl">
                      <ConfirmationTable data={tableData} columns={columns} />
                    </div>
                  </div>

                  {/* Customer Note Text Box */}
                  {!isBulk ? (
                    <label className="text-medium-gray text-xs pb-1 mb-2 mt-12">
                      Customer Note
                      <div>
                        <textarea
                          rows={4}
                          cols={35}
                          type="text"
                          className="focus:ring-1 focus:ring-blue-500 border-2 focus:text-black  py-2 pl-2 pr-4 resize-none w-full h-24"
                          value={note}
                          maxLength="110"
                          onChange={e => {
                            setNote(e.target.value);
                            updateTextCount(e.target.value);
                          }}
                        />
                        {/* Clear all icon */}
                        <FontAwesomeIcon
                          icon={faTimes}
                          fixedWidth
                          className="absolute right-3 bottom-44 -mb-3 z-10"
                          size="lg"
                          onClick={() => setNote("")}
                        />
                        {/* Counter for descrption text */}
                        <div className="flex w-full justify-end">
                          <p
                            className={
                              !maxCountReached
                                ? "text-xs text-medium-gray"
                                : "text-xs text-warning-red"
                            }
                          >
                            {textCount}/110
                          </p>
                        </div>
                      </div>
                    </label>
                  ) : null}
                  {/* Save/Discard Changes */}
                  <div className="flex flex-row w-full justify-between md:justify-end mt-6">
                    {/* Save Button */}
                    <button
                      className="text-white text-xs font-bold rounded bg-button-blue shadow-md px-6 h-11 mr-3"
                      onClick={handleSave}
                    >
                      Save Changes
                    </button>
                    {/* Discard Button */}
                    <button
                      className="text-xs font-bold rounded bg-white px-6 h-11"
                      style={{ color: "#354662" }}
                      onClick={() => setShowModal(false)}
                    >
                      Discard Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default OrgConfirmationModal;
