import React, { useState, createRef } from "react";
import { createPopper } from "@popperjs/core";

import firebase from "firebase/app";
import "firebase/auth";

import { useNavigate, useLocation } from "react-router-dom";

import AvatarCircle from "../../shared/AvatarCircle";
import LoginModal from "../../shared/LoginModal";

import { VIEW_MODES, PATHS } from "../../../constants/consts";

import {
  displayableName,
  isOrgUser,
  isMultiOrgUser,
  setViewMode,
  viewingAsTrainer,
  viewingAsOrgUser,
  viewingAsMultiOrgUser,
  viewingAsDeliveryUser,
  canViewMultiOrg,
  canViewOrgDashboard,
  canViewDeliveries,
  setViewModeAndSelectedOrganization,
} from "../../../utils/user.utils";
import classNames from "../../../utils/classNames";
import { useAuth } from "../../../contexts/AuthContext";
import { clearLocalAuth } from "../../../utils/localstorage.utils";

const AccountDropdown = () => {
  const { currentUser, setCurrentUser, logout, setFirebaseUser } = useAuth();
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [loginPopUp, setLoginPopUp] = useState(false); //If true the login to continue pop shows
  // const { organizations, selectedOrganization } = currentUser;

  const navigate = useNavigate();
  const location = useLocation();
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const setUserViewMode = mode => {
    return setCurrentUser({
      ...currentUser,
      viewMode: mode,
    });
  };

  // when user chooses to logout
  const handleLogout = e => {
    e.preventDefault();
    logout()
      .then(() => setCurrentUser(null))
      .then(() => setFirebaseUser(null))
      .then(() => clearLocalAuth())
      .then(() => closeDropdownPopover())
      .then(() => window.localStorage.clear())
      .then(() => navigate(PATHS.HOME));
  };

  const handleLoginSuccess = () => {
    navigate(PATHS.HOME);
    setLoginPopUp(false);
  };

  return (
    <>
      {loginPopUp && (
        <LoginModal handleSuccess={handleLoginSuccess} setLoginPopUp={setLoginPopUp} />
      )}
      <div className="flex flex-wrap">
        <div className="flex flex-row items-center justify-center">
          <div className="relative inline-flex align-middle w-full  pr-1 pb-1">
            {currentUser ? (
              <button
                className="text-button-green text-sm py-3 rounded outline-none focus:outline-none ease-linear transition-all duration-150 flex flex-row items-center"
                type="button"
                ref={btnDropdownRef}
                onClick={() => {
                  dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                }}
                onBlur={e => {
                  if (!popoverDropdownRef.current.contains(e.relatedTarget)) {
                    dropdownPopoverShow && closeDropdownPopover();
                  }
                }}
              >
                <div className="text-button-green text-md font-medium pr-4 hover:underline hover:font-bold">
                  {displayableName(currentUser)}
                </div>
                <AvatarCircle
                  containerClassName={"h-fit w-fit ring ring-button-green"}
                  innerClassName="bg-light-gray text-black font-semibold text-lg text-center"
                  currentUser={currentUser}
                  size={8}
                  borderSize={3}
                />
              </button>
            ) : (
              <button
                className="font-bold text-sm"
                onClick={() => setLoginPopUp(true)}
                style={{ color: "#37833B" }}
              >
                Log in/Sign up
              </button>
            )}

            <div
              ref={popoverDropdownRef}
              className={classNames(
                dropdownPopoverShow ? "block " : "hidden ",
                "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg bg-white",
              )}
              style={{ minWidth: "12rem" }}
            >
              {currentUser && !viewingAsTrainer(currentUser) ? (
                <button
                  className={classNames(
                    "justify-start float-left text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent",
                    "text-blueGray-700",
                  )}
                  onClick={e => {
                    e.preventDefault();

                    setViewMode(currentUser, setCurrentUser, VIEW_MODES.TRAINER);
                    navigate(PATHS.HOME);
                  }}
                >
                  View As Trainer
                </button>
              ) : (
                ""
              )}
              {currentUser &&
              canViewMultiOrg(currentUser) &&
              !viewingAsMultiOrgUser(currentUser) ? (
                <button
                  className={classNames(
                    "justify-start float-left text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent",
                    "text-blueGray-700",
                  )}
                  onClick={e => {
                    e.preventDefault();
                    setViewModeAndSelectedOrganization(
                      currentUser,
                      setCurrentUser,
                      VIEW_MODES.MULTI_ORG,
                      null,
                    );
                    navigate(PATHS.HOME);
                  }}
                >
                  View As Internal Admin
                </button>
              ) : (
                ""
              )}
              {currentUser &&
              canViewOrgDashboard(currentUser) &&
              !viewingAsOrgUser(currentUser) ? (
                <button
                  className={classNames(
                    "justify-start float-left text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent",
                    "text-blueGray-700",
                  )}
                  onClick={e => {
                    e.preventDefault();
                    setViewMode(currentUser, setCurrentUser, VIEW_MODES.ORG_USER);
                    navigate(PATHS.HOME);
                  }}
                >
                  View As Dashboard Admin
                </button>
              ) : (
                ""
              )}
              {currentUser &&
              canViewDeliveries(currentUser) &&
              !viewingAsDeliveryUser(currentUser) ? (
                <button
                  className={classNames(
                    "justify-start float-left text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent",
                    "text-blueGray-700",
                  )}
                  onClick={e => {
                    e.preventDefault();
                    setViewMode(currentUser, setCurrentUser, VIEW_MODES.DELIVERY);
                    navigate(PATHS.HOME);
                  }}
                >
                  View As Delivery User
                </button>
              ) : (
                ""
              )}
              <button
                className={classNames(
                  "justify-start float-left text-left text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent",
                  "text-blueGray-700",
                )}
                onClick={e => {
                  e.preventDefault();
                  navigate(PATHS.ACCOUNT);
                }}
              >
                Account
              </button>
              <button
                className="text-light-blue px-4 pt-2 font-sm whitespace-nowrap w-full border-t border-blueGray-800"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDropdown;
