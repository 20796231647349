import firebase from "firebase/app";
import "firebase/auth";
import { setLocalAuth, getLocalAuth } from "./localstorage.utils";

const localAuth = getLocalAuth();

class Auth {
  constructor(currentUser) {
    this.token = localAuth.token || null;
    this.expiry = localAuth.expiry || null;
    this.currentUser = currentUser || null;
  }

  get isExpired() {
    if (!this.expiry) {
      return true;
    }

    // Check if the token is expired, _or_ will be expiring in the next 5 minutes
    return this.expiry.getTime() - new Date().getTime() < 5 * 1000;
  }

  async getAuthToken() {
    if (this.token && !this.isExpired) {
      return this.token;
    }

    return this.requestNewToken();
  }

  async requestNewToken() {
    const res = await firebase
      .auth()
      .currentUser?.getIdToken()
      .then(() => firebase.auth().currentUser?.getIdTokenResult());

    if (!res) {
      return null;
    }
    setLocalAuth(res.token, res.expirationTime);
    this.expiry = new Date(res.expirationTime);
    this.token = res.token;

    return this.token;
  }
}

export default Auth;
