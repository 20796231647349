import React from "react";
import { useNavigate } from "react-router-dom";
import { formatDateRange } from "../../../utils/date.utils";
import { PATHS } from "../../../constants/consts";

const ListShopCard = ({shop}) => {
  const navigate = useNavigate();

  const storeUrl = `${PATHS.STOREFRONT.replace(':shopUid', shop.uid)}`;

  function handleShowClick() {
    navigate(storeUrl);
  }

  return (
    <div className="flex flex-row justify-start p-3 rounded-lg shadow-md ring-1 ring-black ring-opacity-5 mx-3">
      <div className="flex flex-col flex-basis-35 items-center justify-center w-5/12 rounded-md">
        <img
          src={shop.imageUrl}
          alt={shop.name}
          className={`h-32 md:h-40 lg:h-48 w-full ${shop.uid === 'langer_1_shop' ? 'object-contain' : 'object-cover'} rounded-lg`}
        />
      </div>
      <div className={[
        "flex flex-col flex-basis-60 bg-white rounded-b-md items-start px-2 w-full",
        "sm:pl-3",
      ].join(' ')}>
        <div className="font-bold sm:text-lg md:text-xl">
          {shop.name}
        </div>
        <div className="sm:text-lg">
          {formatDateRange(shop?.show?.showSegments[0]?.startDate, shop?.show?.showSegments[shop?.show?.showSegments?.length - 1]?.endDate)}
        </div>
        <div className="flex flex-col h-full w-full justify-end">
          <button
            className={[
              "text-white h-10 px-6 py-1 font-bold my-2 self-end",
              "bg-button-green rounded shadow-md ring-1 ring-black ring-opacity-10",
              "sm:text-lg sm:h-10 sm:px-12",
              "md:text-lg md:h-10 md:px-12",
              "lg:text-xl lg:h-12 lg:px-16",
            ].join(' ')}
            onClick={handleShowClick}
          >
            Shop
          </button>
        </div>
        {/* <div className="text-xs h-full">{show.showDetail}</div> */}
      </div>
    </div>
  )
};

export default ListShopCard;
