import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query GetPaginatedOrganizationOrders(
    $organizationUid: String!
    $filterShopUid: String
    $q: String
    $sortKey: String
    $sortDirection: String
    $page: Int
    $perPage: Int
  ) {
    paginatedOrganizationOrders(
      organizationUid: $organizationUid
      filterShopUid: $filterShopUid
      q: $q
      sortKey: $sortKey
      sortDirection: $sortDirection
      page: $page
      perPage: $perPage
    ) {
      orders {
        uid
        deliveryStatus
        paymentStatus

        total
        salesTax
        applicationFee
        subtotal
        stripeFee
        stablingWith

        createdAt
        updatedAt
        completedAt
        deliveredBy {
          uid
          name
          displayName
        }
        shop {
          uid
          name
          show {
            uid
            name
          }
        }
        user {
          uid
          name
          phone
          email
        }
        deliveryDate
        deliveryTime {
          uid
          name
        }
        lineItems {
          uid
          quantity
          isRefund
          calculatedQuantity
          delivered
          deliveredAt
          subtotal
          salesTax
          applicationFee
          processingFee
          stripeFee
          total
          activeProduct {
            uid
            price
            product {
              uid
              name
            }
          }
        }
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`;

// Example data
// {
//   "organizationUid": "hdhp",
//   "filterShopUid": "hdhp_1_shop",
//   "q": "user 1",
//   "sortKey": "createdAt",
//   "sortDirection": "asc",
// }
