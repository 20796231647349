import { gql } from "@apollo/client";

export const RemoveUserRole = gql`
  mutation RemoveUserRole($roleUid: String!) {
    adminRemoveRole(input: { roleUid: $roleUid }) {
      organizationRole {
        role
      }
      errors
    }
  }
`;
