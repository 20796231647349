/* ===================================== AUDIT LOG ========================================= *
 * Title: StoreFront
 * Author: Faizan Omer
 * Created on: 03/02/22
 * Description: The main screen for the feed and bedding store where you can purchase items and add to cart
 * ========================================================================================== */

import React, { useState, useEffect } from "react";
import ItemSelectCard from "../../shared/ItemSelectCard";
import CartBasket from "../../shared/CartBasket";
import LoginModal from "../../shared/LoginModal";
import GetShop from "../../../apollo/query/GetShop";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import moment from "moment-timezone";
import parseISO from "date-fns/parseISO";
import { MODAL_MESSAGES, PATHS } from "../../../constants/consts";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { calculateSubtotal } from "../../../utils/cart.utils";
import { useMutation } from "@apollo/client";
import Create from "../../../apollo/mutation/trainers/orders/Create";
import ErrorModal from "../../shared/ErrorModal";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import StoreSearch from "../../shared/StoreSearch";
import Loading from "../../shared/Loading";
import { TopNavLayout } from "../../layouts";
import LoadingText from "../../../assets/loading-paragraph.png";
import { formatDateRange } from "../../../utils/date.utils";
import {
  returnMinDateBasedOnDate,
  returnMinDateBasedOnToday,
} from "../../../utils/date.utils";
import { default as _ } from "lodash";

const StoreFront = () => {
  const { shopUid } = useParams();
  const { firebaseUser } = useAuth();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery(GetShop, {
    variables: { shopUid },
  });

  const [
    trainerCreateOrder,
    { loading: loadingMutation, error: mutationError, data: mutationData },
  ] = useMutation(Create);

  const [cartItems, setCartItems] = useState([]); //All the user selected items (products) to be passed to cart bucket
  const [loginPopUp, setLoginPopUp] = useState(false); //If true the login to continue pop shows
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryTime, setDeliveryTime] = useState("");
  const [showName, setShowName] = useState("");
  const [minDate, setMinDate] = useState(new Date());
  const [shopStartDate, setShopStartDate] = useState(new Date());
  const [shopEndDate, setShopEndDate] = useState(new Date());
  const [subTotal, setSubtotal] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [stablingWith, setStablingWith] = useState("");
  const [sortedEnabledFnbProducts, setSortedEnabledFnbProducts] = useState([]);
  const [sortedEnabledStallProducts, setSortedEnabledStallProducts] = useState([]);

  // sets option for selecting delivery times
  const options = data?.shop?.deliveryTimes.map(time => {
    return {
      value: time.uid,
      label: time.name.charAt(0).toUpperCase() + time.name.slice(1), //Capitalizing the first letter
    };
  });

  useEffect(() => {
    setSortedEnabledFnbProducts(
      _.orderBy(
        (data?.shop?.enabledActiveProducts || []).filter(ap => ['Hay', 'Grain', 'Bedding'].includes(ap.product.itemType)),
        [
          activeProduct => activeProduct.sortOrder,
          activeProduct => activeProduct.product.name.toLowerCase(),
        ],
        ["asc", "asc"],
      ),
    );
    setSortedEnabledStallProducts(
      _.orderBy(
        (console.log(`enabledActiveProducts: ${JSON.stringify(data?.shop?.enabledActiveProducts.map(({product}) => ({itemType: product.itemType, name: product.name})))}`) || data?.shop?.enabledActiveProducts || []).filter(ap => 'Stall' === ap.product.itemType),
        [
          activeProduct => activeProduct.sortOrder,
          activeProduct => activeProduct.product.name.toLowerCase(),
        ],
        ["asc", "asc"],
      ),
    );
  }, [data]);

  // Check if cart items are being changed
  useEffect(() => {
    setSubtotal(calculateSubtotal(cartItems));
  }, [cartItems]);

  // Set state based on data received from query
  useEffect(() => {
    if (data && data.shop?.uid) {
      const date = returnMinDateBasedOnToday(
        moment().tz("America/Los_Angeles"),
        moment(data.shop.startDate).tz("America/Los_Angeles"),
        moment(data.shop.endDate).tz("America/Los_Angeles"),
        data.shop.allowSameDayDeliveries,
      )
        .tz("America/Los_Angeles")
        .format();
      setShowName(data.shop.show.name);
      setShopStartDate(data.shop.startDate);
      setShopEndDate(data.shop.endDate);
      setMinDate(parseISO(date));
      setDeliveryDate(parseISO(date));

      if (window.localStorage.getItem(data.shop.uid)) {
        const shoppingCart = JSON.parse(window.localStorage.getItem(data.shop.uid));
        const deliveryDate = returnMinDateBasedOnDate(
          moment(shoppingCart.deliveryDate).tz("America/Los_Angeles"),
          moment(date).tz("America/Los_Angeles"),
          moment(data.shop.endDate).tz("America/Los_Angeles"),
          data.shop.allowSameDayDeliveries,
        )
          .tz("America/Los_Angeles")
          .format();
        setCartItems(shoppingCart.cartItems);
        setDeliveryDate(parseISO(deliveryDate));
        setDeliveryTime(shoppingCart.deliveryTime);
        setStablingWith(shoppingCart.stablingWith);
      }
    }
  }, [data]);

  // Set cartItems to local storage
  useEffect(() => {
    if (data) {
      window.localStorage.setItem(
        data.shop.uid,
        JSON.stringify({
          cartItems,
          deliveryDate,
          deliveryTime,
          stablingWith,
        }),
      );
    }
  }, [cartItems, deliveryDate, deliveryTime, stablingWith]);

  // returns quantity of an item it was already present in cart
  function returnQuantity(productId) {
    const item = cartItems.filter(item => item.uid === productId);
    if (item.length < 1 || !item[0].qty) return false;
    return item[0].qty;
  }

  // When user selects an item
  function onAddItem(product) {
    // if quantity is 0 remove from cart else update quantity
    if (product.qty > 0) {
      //   Check if the item already exists in the cart
      const existsInCart = cartItems.find(item => item.uid === product.uid);
      // if item exits then iterate through the cart state and update the item quantity else add the item to cart
      if (existsInCart) {
        setCartItems(
          cartItems.map(item =>
            item.uid === product.uid ? { ...existsInCart, qty: product.qty } : item,
          ),
        );
      } else {
        setCartItems([...cartItems, { ...product }]);
      }
    } else {
      setCartItems(cartItems.filter(item => item.uid !== product.uid));
    }
  }

  // Takes user to stores screen if logged in else opens login in pop up
  function handleSearchStores() {
    firebaseUser ? navigate(PATHS.HOME) : setLoginPopUp(true);
  }

  // Function handles guest login checkout when user tries to login
  const handleLoginSuccess = () => {
    const lineItems = cartItems.map(item => {
      return { activeProductUid: item.uid, quantity: item.qty };
    });

    setTimeout(() => {
      trainerCreateOrder({
        variables: {
          shopUid: shopUid,
          deliveryDate: moment(deliveryDate).format("YYYY-MM-DD"),
          deliveryTimeUid: deliveryTime.value,
          lineItems: lineItems,
          stablingWith: stablingWith,
        },
      }).then(res => {
        if (res.data.trainerCreateOrder.errors.length > 0) {
          setShowErrorModal(true);
          return;
        }
        let state = {
          state: {
            showName: data.shop.show.name,
            deliveryTime,
            shopStartDate,
            shopEndDate,
            deliveryDate,
            cartItems,
            subTotal,
            shopUid,
            orderUid: res.data.trainerCreateOrder.order.uid,
            stablingWith: stablingWith,
          },
        };

        setLoginPopUp(false);
        navigate(PATHS.CHECKOUT, state);
      });
    }, 400);
  };

  // When a user clicks on checkout button navigate them to checkout screen if they're logged in
  function handleCheckout() {
    !firebaseUser ? setLoginPopUp(true) : handleLoginSuccess();
  }

  //custom components for dropdown
  const customComponents = {
    IndicatorSeparator: () => null,
    DropdownIndicator: props => {
      return (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon icon={faCaretDown} fixedWidth color="#036D19" size="lg" />
        </components.DropdownIndicator>
      );
    },
  };

  const customDropdownStyles = {
    option: base => ({
      ...base,
      borderRadius: 5,
      backgroundColor: "#FFFF",
      color: "black",
      "&:hover": {
        backgroundColor: "#036D19",
        color: "#FFFF",
      },
    }),
    menuList: base => ({
      ...base,
      paddingLeft: 5,
      paddingRight: 5,
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: 0,
      border: state.isFocused ? "1px solid #036D19" : "1px solid #cccccc",
      "&:hover": {
        border: state.isFocused ? "1px solid #036D19" : "1px solid #cccccc",
      },
    }),
  };

  const DatePickerCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className="rounded bg-white w-full flex flex-row justify-between items-center px-2 h-10"
      style={{ border: `1px solid #cccccc` }}
      onClick={onClick}
      ref={ref}
    >
      <p className="text-sm">{value}</p>
      <FontAwesomeIcon icon={faCalendarDay} fixedWidth color="#036D19" />
    </button>
  ));

  if (error) {
    return (
      <div className="w-screen h-screen">
        <ErrorModal
          buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
          onClick={() => window.location.reload()}
          heading={MODAL_MESSAGES.ERRORHEADING}
          text={MODAL_MESSAGES.ERRORTEXT}
        />
      </div>
    );
  }

  if (!loading && !data?.shop?.uid) {
    return (
      <div className="text-lg font-bold text-center w-full pt-8">Shop Not Found</div>
    );
  }

  return (
    <div className="w-screen h-screen">
      {/* If user not logged in show sign/up login else show account dropdown */}
      <div
        className={[
          "flex flex-col h-full pt-4",
          "md:flex-row  md:px-4",
          "lg:px-10",
          "xl:px-28",
          "2xl:px-40",
        ].join(" ")}
      >
        {/* Show title and date section */}
        <div className="flex flex-col flex-2 px-2 h-full md:flex-grow">
          {!loading ? (
            <>
              <p className="text-2xl xl:text-3xl 2xl:text-4xl font-bold">
                {data.shop.show.name}
              </p>
              <p className="font-bold 2xl:text-xl">
                {formatDateRange(
                  data.shop?.show?.showSegments[0]?.startDate,
                  data.shop?.show?.showSegments[data.shop?.show?.showSegments?.length - 1]
                    ?.endDate,
                )}
              </p>
              <p className=" text-sm 2xl:text-base font-light mt-1 md:mt-2">
                Not the right show?{" "}
                <span
                  className="hover:underline hover:cursor-pointer"
                  style={{ color: "#37833B" }}
                  onClick={handleSearchStores}
                >
                  Find it here.
                </span>
              </p>
              {data.shop?.description && (
                <p className="italic my-2 font-semibold text-button-green 2xl:text-lg">
                  {data.shop.description}
                </p>
              )}
            </>
          ) : (
            <img src={LoadingText} />
          )}
          {/* Stabling with section */}
          <p className="mt-3 md:text-lg font-bold">
            Who are you stabling with?{" "}
            <span className="text-warning-red text-bold">*</span>
          </p>
          <input
            type="text"
            className="focus:ring-2 mt-1 focus:ring-blue-500 focus:text-black rounded pl-2 pr-3 h-9 w-5/6 mb-8"
            value={stablingWith}
            onChange={e => setStablingWith(e.target.value)}
          />
          {!loading ? (sortedEnabledStallProducts.length > 0 && (
            <>
              <p className="mt-3 font-bold text-xl">Select Stalls</p>
              <div className="mt-1 h-full lg:w-full mb-8 xl:pr-3 md:space-y-1">
                {sortedEnabledStallProducts?.map(item => (
                  <ItemSelectCard
                    key={item.uid}
                    itemUid={item.uid}
                    itemName={item.product.name}
                    itemImage={item.product.imageUrl}
                    itemDescription={item.product.shortDescription}
                    itemDisplayPrice={item.displayPrice}
                    itemPrice={item.price}
                    itemType={item.product.itemType}
                    onAddItem={onAddItem}
                    storedQty={returnQuantity(item.uid)}
                  />
                ))}
              </div>
            </>
          )) : (
            <div className="mt-16 flex items-center justify-center">
              <Loading inline={true} color="gray" size="4x" />
            </div>
          )}


          {/* List of items */}
          <p className="mt-3 font-bold text-xl">Select Supplies</p>
          <p className="mt-3 md:text-lg font-bold">
            Set your preferred delivery window{" "}
            <span className="text-warning-red text-bold">*</span>
          </p>
          <div className="flex justify-between md:justify-start mt-2 w-full">
            <div className="w-4/12 mr-2 sm:w-5/12 md:w-4/12 lg:w-5/12">
              {/* Date Picker */}
              <DatePicker
                className=""
                selected={deliveryDate}
                minDate={minDate}
                maxDate={parseISO(shopEndDate)}
                onChange={date => setDeliveryDate(date)}
                customInput={<DatePickerCustomInput />}
              />
            </div>
            {/* Time Picker */}
            <Select
              className="w-7/12 sm:w-6/12 md:w-8/12 lg:w-6/12 xl:w-5/12"
              placeholder={"Time of day"}
              options={options}
              components={customComponents}
              isSearchable={false}
              closeMenuOnSelect={true}
              closeMenuOnScroll={() => true}
              hideSelectedOptions={false}
              value={deliveryTime}
              isClearable={false}
              onChange={selected => setDeliveryTime(selected)}
              styles={customDropdownStyles}
            />
          </div>
          {!loading ? (
            <div className="mt-1 h-full lg:w-full mb-8 xl:pr-3 md:space-y-1">
              {sortedEnabledFnbProducts?.map(item => (
                <ItemSelectCard
                  key={item.uid}
                  itemUid={item.uid}
                  itemName={item.product.name}
                  itemImage={item.product.imageUrl}
                  itemDescription={item.product.shortDescription}
                  itemDisplayPrice={item.displayPrice}
                  itemPrice={item.price}
                  itemType={item.product.itemType}
                  onAddItem={onAddItem}
                  storedQty={returnQuantity(item.uid)}
                />
              ))}
            </div>
          ) : (
            <div className="mt-16 flex items-center justify-center">
              <Loading inline={true} color="gray" size="4x" />
            </div>
          )}
        </div>
        <div className="flex flex-col  md:w-5/12 xl:max-w-100">
          {/* Store Search */}
          <div className="hidden md:inline flex flex-col">
            <p className=" font-bold 2xl:text-lg">
              Not seeing the right show? Let's find it.
            </p>
            <StoreSearch />
          </div>

          {/* Cart basket */}
          <CartBasket
            deliveryTime={deliveryTime}
            handleCheckout={handleCheckout}
            cartItems={cartItems}
            subTotal={subTotal}
            stablingWith={stablingWith}
          />
        </div>
      </div>
      {/* Shows login Modal if user not signed in */}
      {loginPopUp && (
        <LoginModal setLoginPopUp={setLoginPopUp} handleSuccess={handleLoginSuccess} />
      )}
      {showErrorModal && (
        <ErrorModal
          buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
          onClick={() => window.location.reload()}
          heading={MODAL_MESSAGES.ERRORHEADING}
          text={MODAL_MESSAGES.ERRORTEXT}
        />
      )}
    </div>
  );
};

export default StoreFront;
