import React from "react";

import { useLocation, Link } from "react-router-dom";

import { PATHS, BREAD_CRUMBS } from "../../../constants/consts";

import ChevronRight from "../../../assets/chevron-right.png";

const BreadCrumbRow = () => {
  const location = useLocation();
  return (
    <div className="flex flex-row justify-start text-dark-gray font-bold text-s items-center">
      {location.pathname === "/" && (
        <div key={"home"} className="flex flex-row items-center my-4">
          <Link to={PATHS.HOME} className="">
            {"Home"}
          </Link>
        </div>
      )}
      {location.pathname !== "/" &&
        location.pathname.split("/").map((path, index) => {
          const { link, text } = BREAD_CRUMBS[path];
          const lastEl = location.pathname.split("/").length === index + 1;
          if (!link || !text) {
            return null;
          }

          return (
            <div key={path} className="flex flex-row items-center my-4">
              <Link to={link}>{text}</Link>
              {lastEl || (
                <img src={ChevronRight} alt=">" className="mx-4 h-2 object-scale-down" />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default BreadCrumbRow;
