import React, { useState, useEffect } from 'react'
import './SidePanel.scss';

const SidePanel = ({close, children, ...props}) => {
  const testClose = (e) => {
    e.stopPropagation();
    console.log('SidePanel: testClose')
    close()
  }
  return (
    <>
      <div className="fixed top-0 right-0 flex flex-row justify-end z-50 h-screen w-1/2 md:w-1/2 2xl:w-2/5">
        <div id="sidePanelSlider" className="bg-white p-12 overflow-x-none overflow-y-auto w-full">
          {children}
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black" onClick={testClose}></div>
    </>
  )
}

export default SidePanel;
