import React from "react";
import TrainerCard from "./TrainerCard";
import ShowsRow from "./ShowsRow";
import ReservationsTable from "./ReservationsTable";
import Loading from "../../shared/Loading";
import { MODAL_MESSAGES, PATHS } from "../../../constants/consts";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import NotifyModal from "../../shared/NotifyModal";
import { useAuth } from "../../../contexts/AuthContext";
import { useQuery } from "@apollo/client";
import MyStallReservations from "../../../apollo/query/myStallReservations";

const TrainerHomeScreen = () => {
  const { currentUser } = useAuth();
  const { loading, error, data } = useQuery(MyStallReservations);
  const [width, setWidth] = React.useState(window.innerWidth);
  const navigate = useNavigate();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    // Check if being viewed on mobile or desktop/laptop screen
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Setting a maximum width for mobile devices
  let isMobile = width <= 768;
  return (
    <div className="h-full">
      <TrainerCard />
      <div className="flex flex-col flex-start">
        <div className="md:text-3xl sm:text-2xl text-xl font-semibold mt-12 mb-6">
          My Shows
        </div>
        {!error ? (
          loading ? (
            <Loading />
          ) : (
            <>
              <ShowsRow
                currentUser={currentUser}
                shows={data?.myStallReservations?.map(res => res.show)}
              />
              {/* Reservation heading */}
              <div className="flex flex-row justify-between">
                <div className="md:text-3xl sm:text-2xl text-xl font-semibold mt-12">
                  My Reservations
                </div>
                {/* Reservation Button */}
                {
                  // If previous reservations exist show make reservation button else don't show it above the table
                  data?.myStallReservations !== undefined &&
                  Array.isArray(data.myStallReservations) &&
                  data.myStallReservations.length !== 0 ? (
                    !isMobile ? (
                      <div className="mt-12">
                        <button
                          className="rounded-full text-white bg-button-blue md:h-10 md:w-52"
                          onClick={() => navigate(PATHS.RESERVATIONS)}
                        >
                          <FontAwesomeIcon icon={faPlus} fixedWidth className="pr-2" />
                          Make a reservation
                        </button>
                      </div>
                    ) : (
                      <div className="mt-12">
                        <button
                          className="rounded-full text-white bg-button-blue flex justify-center items-center w-20 h-8"
                          onClick={() => navigate(PATHS.RESERVATIONS)}
                        >
                          <FontAwesomeIcon icon={faPlus} fixedWidth />
                        </button>
                      </div>
                    )
                  ) : null
                }
              </div>
              {/* Reservation table with all reserved shows info */}
              <ReservationsTable reservations={data?.myStallReservations} />
            </>
          )
        ) : (
          <NotifyModal
            action="errormessage"
            text={MODAL_MESSAGES.ERRORTEXT}
            heading={MODAL_MESSAGES.ERRORHEADING}
          />
        )}
      </div>
    </div>
  );
};

export default TrainerHomeScreen;
