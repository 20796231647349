import { gql } from "@apollo/client";

export default gql`
  query TaxRatesByActiveProducts {
    allActiveProductsTaxRates {
      uid
      displayName
      stripeTaxType
      internalTaxType
      percentage
      enabled
      createdAt
      updatedAt
      country
      description
      shop {
        uid
        name
      }
      activeProduct {
        product {
          uid
          name
          organization {
            uid
            name
          }
        }
      }
    }
  }
`;
