import {gql} from '@apollo/client';

export const OrgAdminsOrderPartialRefund = gql`
  mutation OrgAdminsOrderPartialRefund(
    $orderUid: String!,
    $refundLineItems: [RefundLineItemInput!]!,
    $reason: String,
  ) {
    orgAdminOrderPartialRefund(
      input: {
        orderUid: $orderUid,
        refundLineItems: $refundLineItems,
        reason: $reason,
      }
    ) {
      order {
        uid
        deliveryStatus
        paymentStatus
        payment {
          uid
          status
        }
        refunds {
          uid
          status
          createdBy {
            uid
            name
          }
        }
        transfers {
          uid
          status
        }
      }
      errors
    }
  }
`;

// Sample Input
// {
//   "orderUid": "6005da",
//   "reason": "The user can no longer attend"
// }

// NOTE: The above was generated via a test, not seeds so you'll need ot replace values
