import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = firebase.initializeApp({
  apiKey: "AIzaSyDSy9E_9-YOJYySslaoi6FXwTFkFVKQFoI",
  authDomain: "backgate-app.firebaseapp.com",
  projectId: "backgate-app",
  storageBucket: "backgate-app.appspot.com",
  messagingSenderId: "483243588961",
  appId: "1:483243588961:web:29059d28ec4ba41c795920",
  measurementId: "G-QVPX7C1CL5",
});

export const auth = firebaseConfig.auth();
export default firebaseConfig;
