import React, { useEffect, useState, useMemo } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  useGlobalFilter,
  preGlobalFilteredRows,
  setGlobalFilter,
  state,
} from "react-table";
import DetailsCell from "./DetailsCell";
import ItemsCell from "./ItemsCell";
import GlobalFilter from "./GlobalFilter";
import SelectColumnFilter from "./SelectColumnFilter";
import EllipsesCell from "./EllipsesCell";
import DeliveryStatusPill from "./DeliveryStatusPill";
import { centsToDollarStr } from "../../../utils/money.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const OrdersTable = ({ data, refetch }) => {
  const columns = useMemo(() => [
    {
      Header: "UID",
      accessor: "uid",
      Cell: ({ row }) => (
        <div className="my-1 text-xs 2xl:text-md font-semibold">{row.values.uid}</div>
      ),
    },
    {
      Header: "Show",
      accessor: "showName",
      Cell: ({ row }) => (
        <div className="my-1 text-xs 2xl:text-md font-semibold">{row.values.showName}</div>
      ),
    },
    {
      Header: "User",
      accessor: "userName",
      Cell: ({ row }) => (
        <div className="my-1 text-xs 2xl:text-md font-semibold">{row.values.userName}</div>
      ),
    },
    {
      Header: "Order Details",
      accessor: "totalQuantity",
      Cell: ItemsCell,
    },
    {
      Header: "Payment Status",
      accessor: "paymentStatus",
      Cell: ({ row }) => (
        <div className="my-1 uppercase text-xs 2xl:text-md font-light">
          {row.original.paymentStatus}
        </div>
      ),
    },
    {
      Header: "Delivery Status",
      accessor: "deliveryStatus",
      Cell: DeliveryStatusPill,
    },
    {
      Header: "Delivery Details",
      Cell: DetailsCell,
    },
    {
      Header: '',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => (
        <EllipsesCell row={row} refetch={refetch} />
      ),
    },
    // {
    //   Header: "Created At",
    //   accessor: "createdAt",
    // },
    // {
    //   Header: "Updated At",
    //   accessor: "updatedAt",
    // }
  ], []);

  const preppedData = useMemo(() => {
    if (!data?.paginatedOrganizationOrders) {
      return [];
    }
    return data.paginatedOrganizationOrders.orders.map(order => {
      return {
        ...order,
        totalStr: centsToDollarStr(order.total),
        userName: order.user.name,
        showName: order.shop.show.name,
        deliveryTimeName: order.deliveryTime.name,
        totalQuantity: `${order.lineItems.filter(li => !li.isRefund).reduce((sum, i) => {
          return sum + i.calculatedQuantity;
        }, 0)} Items`,
      };
    });
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data: preppedData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  // return (
  //   <div> Hmm</div>
  // )

  // const shouldRenderOrders = () => {
  //   return data?.paginatedOrganizationOrders?.length > 0;
  // }

  return preppedData.length > 0 ? (
    <div className="h-auto overflow-auto">
      <table {...getTableProps()} className="min-w-full table-fixed w-auto lg:w-full border-b border-gray-200">
        <thead className="z-20 sticky">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs bg-white border-b border-gray-200 sticky top-0 z-20 font-medium text-gray-700 uppercase tracking-wider max-w-xs xl:max-w-xl"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-2 max-w-xs xl:max-w-xl"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="flex h-full items-center justify-center mt-20">
      No orders available in selection.
    </div>
  )
};

export default OrdersTable;
