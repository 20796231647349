/* ===================================== AUDIT LOG ========================================= *
 * title: Login Modal
 * Author: Faizan Omer
 * Created on: 03/08/22
 * Description: Login Modal for logging in during auth activities like checkout
 * ========================================================================================== */

import React, { useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Formik, Form, Field } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { PATHS } from "../../constants/consts";
import FindOrCreateUser from "../../apollo/mutation/FindOrCreateUser";
import SsoLinks from "../screens/AuthScreen/SsoLinks";
import ForgotPasswordForm from "../screens/AuthScreen/ForgotPasswordForm";
import { AUTH_SCREEN_MODES } from "../../constants/consts";
import useOnClickOutside from "../../hook/useOnClickOutside";
import {
  validateFirstName,
  validateLastName,
  validatePassword,
  validateAgreement,
  validateEmail,
  validateForm,
  validatePhone,
} from "../../utils/form_validation.utils";

const LoginModal = ({ setLoginPopUp, handleSuccess }) => {
  const { login, signUp, setCurrentUser } = useAuth();
  const [mode, setMode] = useState(AUTH_SCREEN_MODES.LOGIN);
  const [findOrCreateUser, { loading, error, data }] = useMutation(FindOrCreateUser);
  const [authError, setAuthError] = useState(null);

  const navigate = useNavigate();
  const ref = useRef();

  const validateLoginForm = values => {
    const errors = {};

    return errors;
  };

  React.useEffect(() => {
    isLogin();
  }, [mode]);

  // Function for signing up a user and redirecting them to checkout page
  const handleSignUp = values => {
    signUp(values.email, values.password)
      .then(({ user, additionalUserInfo }) => {
        setAuthError(true);
        return findOrCreateUser({
          variables: {
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            providerId: additionalUserInfo.providerId,
            firebaseId: user.uid,
          },
        });
      })
      .then(({ data }) => setCurrentUser(data.findOrCreateUser.user))
      .then(args => {
        handleSuccess();
        return args;
      })
      .catch(e => {
        setAuthError(e);
      });
  };

  // Function logs users in and redirects them to checkout
  function handleLogin(values) {
    {
      return login(values.email, values.password)
        .then(({ user, additionalUserInfo }) => {
          setAuthError(null);
          return findOrCreateUser({
            variables: {
              ...values,
              providerId: additionalUserInfo.providerId,
              firebaseId: user.uid,
            },
          });
        })

        .then(args => {
          handleSuccess();
          return args;
        })
        .then(({ data }) => {
          setCurrentUser(data.findOrCreateUser.user);
        })

        .catch(e => setAuthError(e));
    }
  }

  // Function to check whether user wants to login or register
  const isLogin = () => mode === AUTH_SCREEN_MODES.LOGIN;

  // Function to check whether user wants to register
  const isRegister = () => mode === AUTH_SCREEN_MODES.REGISTER;

  // Function to check whether user wants to reset password
  const isPasswordReset = () => mode === AUTH_SCREEN_MODES.PASSWORD_RESET;

  // Sets mode to register account
  function registerMode() {
    setMode(AUTH_SCREEN_MODES.REGISTER);
  }

  // Sets mode to login account
  function loginMode() {
    setMode(AUTH_SCREEN_MODES.LOGIN);
  }

  // Sets mode to login account
  function passwordResetMode() {
    setMode(AUTH_SCREEN_MODES.PASSWORD_RESET);
  }

  // Closes modal if clicked outside the modal
  function handleClickOutside() {
    setLoginPopUp(false);
  }

  // Hook thats listens for outside clicks
  useOnClickOutside(ref, handleClickOutside);

  const handleFormErrors = (errors, touched) => {
    if (Object.keys(errors).length === 0) {
      return null;
    }

    return (
      <>
        {Object.keys(errors).map(key => {
          if (touched.hasOwnProperty(key)) {
            return (
              <div key={key} className="text-red-400 font-semibold text-sm">
                {errors[key]}
              </div>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      {" "}
      <div
        ref={ref}
        className={`w-11/12 sm:w-8/12 md:w-7/12 lg:w-6/12 xl:w-4/12 ${isPasswordReset() ? "" : "h-97 xl:h-98 sm:h-5/6"} px-4 bg-white shadow rounded-lg absolute bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none`}
      >
        {isLogin() ? (
          <>
            <div className="flex flex-col mt-5">
              <p className="font-bold text-2xl 2xl:text-4xl">Sign in</p>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validate={validateLoginForm}
                onSubmit={values => handleLogin(values)}
              >
                {props => (
                  <Form onSubmit={props.handleSubmit} className="w-full mt-8">
                    <div className="flex flex-col items-start">
                      {handleFormErrors(props.errors, props.touched)}
                      {authError && (
                        <div
                          key={authError.code}
                          className="text-red-400 font-semibold text-sm"
                        >
                          {authError.message}
                        </div>
                      )}
                      <div className="flex flex-col w-full">
                        <div className="flex flex-col mr-2 my-2 w-full">
                          <Field
                            type="email"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            name="email"
                            placeholder="Email"
                            className="rounded border-gray-400 shadow-sm text-xs md:text-base 2xl:text-lg bg-white h-10 md:h-14"
                          />
                        </div>
                        <div className="flex flex-col mr-2 my-2 w-full">
                          <Field
                            type="password"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            name="password"
                            placeholder="Password"
                            className="rounded border-gray-400 shadow-sm text-xs md:text-base 2xl:text-lg bg-white h-10 md:h-14"
                          />
                        </div>
                        {/* Sign in button */}
                        <button
                          type="submit"
                          className="text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold my-2 w-full bg-button-green"
                          disabled={loading || Object.keys(props.errors) > 0}
                        >
                          Log in
                        </button>
                        {/* Remember me checkbox */}
                        <div className="flex flex-row items-center w-full my-2">
                          <Field
                            className={`rounded-sm ${
                              props.values.remember ? "bg-light-blue" : "bg-light-gray"
                            }`}
                            type="checkbox"
                            name="remember"
                          />
                          <div className="text-xs md:text-lg 2xl:text-xl ml-2">
                            Remember me
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* Social sign up buttons */}
            <div className="flex flex-col items-center">
              <SsoLinks
                mode={mode}
                findOrCreateUser={findOrCreateUser}
                loading={loading}
                setAuthError={setAuthError}
                handleSuccess={handleSuccess}
              />
              <p className="text-xs md:text-base  pt-6">
                Don't have an account?{" "}
                <span
                  style={{ color: "#036D19" }}
                  className="hover:underline cursor-pointer"
                  onClick={registerMode}
                >
                  Register Now!
                </span>
              </p>
              <p className="text-xs md:text-base mb-24">
                <span
                  style={{ color: "#036D19" }}
                  className="hover:underline hover:cursor-pointer"
                  onClick={() => setMode(AUTH_SCREEN_MODES.PASSWORD_RESET)}
                >
                  Forgot Password?
                </span>
              </p>
            </div>
          </>
        ) : (isRegister() ? (
          // REGISTRATION FORM
          <>
            <div className="flex flex-col mt-5">
              <p className="font-bold text-2xl lg:text-4xl">Register</p>
              <div className="flex flex-col items-center mt-10 px-3">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    phone: "",
                    agreed: false,
                  }}
                  validate={validateForm}
                  onSubmit={(values, actions) => {
                    handleSignUp(values, actions);
                  }}
                >
                  {props => (
                    <Form onSubmit={props.handleSubmit}>
                      <div className="flex flex-col items-center">
                        {handleFormErrors(props.errors, props.touched)}
                        {authError && (
                          <div
                            key={authError.code}
                            className="text-red-400 font-semibold text-sm"
                          >
                            {authError.message}
                          </div>
                        )}
                        <div className="flex flex-row flex-wrap justify-center">
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            {/* Field for first name */}
                            <Field
                              type="text"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="firstName"
                              placeholder="First Name"
                              className=" rounded border-gray-400 shadow-sm text-xs h-10"
                              validate={validateFirstName}
                            />
                          </div>
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            <Field
                              type="text"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="lastName"
                              placeholder="Last Name"
                              className="rounded border-gray-400 shadow-sm text-xs h-10"
                              validate={validateLastName}
                            />
                          </div>
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            <Field
                              type="email"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="email"
                              placeholder="Email"
                              className="rounded border-gray-400 shadow-sm text-xs h-10"
                              validate={validateEmail}
                            />
                          </div>
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            <Field
                              type="text"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="phone"
                              placeholder="Phone Number"
                              className="rounded border-gray-400 shadow-sm text-xs h-10"
                              validate={validatePhone}
                            />
                          </div>
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            <Field
                              type="password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="password"
                              placeholder="Password"
                              className="rounded border-gray-400 shadow-sm text-xs h-10"
                              validate={validatePassword}
                            />
                          </div>
                          <div className="flex flex-col w-5/12 mr-2 my-0.5">
                            <Field
                              type="password"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              className="rounded border-gray-400 shadow-sm text-xs h-10"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-4">
                          <div className="flex flex-row items-center">
                            <Field
                              className={`rounded-sm ${
                                props.values.agreed ? "bg-light-blue" : "bg-light-gray"
                              }`}
                              type="checkbox"
                              name="agreed"
                              validate={validateAgreement}
                            />
                            <div className="text-xs ml-3">
                              I agree to all the{" "}
                              <Link
                                to={PATHS.TERMS}
                                className="font-normal "
                                style={{ color: "#036D19" }}
                              >
                                Terms
                              </Link>{" "}
                              and{" "}
                              <Link
                                to={PATHS.PRIVACY}
                                className="font-normal "
                                style={{ color: "#036D19" }}
                              >
                                Privacy Policy
                              </Link>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="text-white rounded shadow-sm px-6 py-1 w-full h-8 md:h-14 my-4 bg-button-green"
                          onClick={args => setAuthError(null)}
                          disabled={loading || Object.keys(props.errors) > 0}
                        >
                          <p className="text-xs md:text-lg lg:text-xl font-bold">
                            Create Account
                          </p>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {/* Social sign up buttons */}
            <div className="flex flex-col items-center">
              <SsoLinks
                mode={mode}
                findOrCreateUser={findOrCreateUser}
                loading={loading}
                setAuthError={setAuthError}
                handleSuccess={handleSuccess}
              />
              <p className="text-xs md:text-base  pt-6">
                Already have an account?{" "}
                <span
                  style={{ color: "#036D19" }}
                  className="hover:underline cursor-pointer"
                  onClick={loginMode}
                >
                  Sign In!
                </span>
              </p>
            </div>
          </>
        ) : (
          <ForgotPasswordForm />
        ))}

      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default LoginModal;
