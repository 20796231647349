import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../shared/Loading";
import SubscriptionCard from "./SubscriptionCard";
import AddSubscriptionForm from "./AddSubscriptionForm";
import { useQuery, useMutation } from "@apollo/client";
import GetNotificationSubscriptions from "../../../../apollo/query/admins/shop__notification_subscriptions/GetNotificationSubscriptions";
import { AdminRemoveSubscription } from "../../../../apollo/mutation/admins/shop_notification_subscriptions/RemoveSubscription";

const AdminManageShopSubscriptions = () => {
  const [addSubscription, setAddSubscription] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");

  const { data, loading, error, refetch } = useQuery(GetNotificationSubscriptions);

  const [adminRemoveSubscription] = useMutation(AdminRemoveSubscription);

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allShopNotifications
      : data?.allShopNotifications.filter(
          subscription =>
            subscription.user.name.toLowerCase().includes(lowercasedFilter) ||
            subscription.shop.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  const removeSubscritpion = subscriptionUid => {
    adminRemoveSubscription({
      variables: {
        subscriptionUid: subscriptionUid,
      },
    })
      .then(res => {
        refetch();
      })
      .catch(e => console.error(e));
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Subscriptions</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setAddSubscription(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-25 items-center justify-start">User</p>
        <p className="flex flex-basis-25 items-center justify-start">Shop</p>
        <p className="flex flex-basis-25 items-center justify-start">Notification Type</p>
        <p className="flex flex-basis-5 items-center justify-start"></p>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Display all Subscriptions */}
      {data &&
        !loading &&
        filteredData &&
        filteredData.length > 0 &&
        filteredData.map(subscription => (
          <div key={subscription.uid}>
            <SubscriptionCard
              subscription={subscription}
              refetchSubscriptionList={refetch}
              removeSubscritpion={removeSubscritpion}
            />
          </div>
        ))}
      {/* Add Subscription */}
      {addSubscription ? (
        <AddSubscriptionForm
          setAddSubscription={setAddSubscription}
          refetchSubscriptionList={refetch}
        />
      ) : null}
    </div>
  );
};

export default AdminManageShopSubscriptions;
