import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import Auth from "../utils/auth.utils";

const buildClient = (currentUser, config) => {
  const auth = new Auth(currentUser);
  const abortController = new AbortController();

  // FIXME: -> https://github.com/apollographql/apollo-client/issues/6769
  const httpLink = createHttpLink({
    //FIXME: This shouldn't be hard-coded
    uri: config.API_URI,
    fetchOptions: {
      mode: "cors",
      signal: abortController.signal,
    },
  });

  const authLink = setContext(async (_req, { headers }) => {
    const token = await auth.getAuthToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  // Create the client as outlined in the setup guide
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return client;
};

export default buildClient;
