/* ===================================== AUDIT LOG ========================================= *
 * Title: Admin Manage Products
 * Author: Faizan Omer
 * Created on: 05/09/22
 * Description: Grants admin panel the ability to edit product details
 * ========================================================================================== */

import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import GetAllProducts from "../../../../apollo/query/admins/products/GetAllProducts";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddProductForm from "./AddProductForm";
import { AdminRemoveProduct } from "../../../../apollo/mutation/admins/products/RemoveProduct";
import ProductCard from "./ProductCard";

const AdminManageProducts = () => {
  const { loading, error, data, refetch } = useQuery(GetAllProducts);

  const [adminRemoveProduct] = useMutation(AdminRemoveProduct);

  const [searchFilter, setSearchFilter] = React.useState("");

  const {
    loading: orgLoading,
    data: orgData,
    error: orgError,
  } = useQuery(GetAllOrganizations);

  const options = orgData?.allOrganizations.map(organization => {
    return { value: organization.uid, label: organization.name };
  });

  const [showForm, setShowForm] = React.useState(false);

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allProducts
      : data?.allProducts.filter(product =>
          product.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Products</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setShowForm(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-30 items-center justify-start">Name</p>
        <p className="flex flex-basis-25 items-center justify-center">Organization</p>
        <p className="flex flex-basis-25 items-center justify-center">Description</p>
        <p className="flex flex-basis-10 items-center justify-center">Status</p>
        <p className="flex flex-basis-5 items-center justify-center"></p>
      </div>
      {/* Display all Products */}
      {data &&
        filteredData &&
        filteredData.length > 0 && (
          <>
            {filteredData.map(product => <ProductCard key={product.uid} product={product} />)}
          </>
      )}
      {/* Add Product */}
      {showForm ? (
        <AddProductForm
          setShowForm={setShowForm}
          organizations={options}
          refetchProductList={refetch}
        />
      ) : null}
    </div>
  );
};

export default AdminManageProducts;
