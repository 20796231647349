import { gql } from "@apollo/client";

export const AddSubscription = gql`
  mutation AddSubscription(
    $userUid: String!
    $shopUid: String!
    $notificationType: String!
  ) {
    adminAddSubscription(
      input: { userUid: $userUid, shopUid: $shopUid, notificationType: $notificationType }
    ) {
      subscription {
        uid
        user {
          uid
        }
        shop {
          uid
        }
        notificationType
      }
      errors
    }
  }
`;
