import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation EditStallReservation(
    $stallReservationUid: String!
    $stallCount: Int!
    $note: String!
  ) {
    editStallReservation(
      input: {
        stallReservationUid: $stallReservationUid
        stallCount: $stallCount
        note: $note
      }
    ) {
      stallReservation {
        uid
        state
        stallCount
        showSegments {
          uid
          name
          startDate
          endDate
        }
        note
      }
      errors
    }
  }
`;
