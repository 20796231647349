import React, { useMemo, useState } from "react";
import Table from "../../shared/Table";
import StatePill from "./StatePill";
import MultiPill from "./MultiPill";
import MultiSelectColumnFilter from "../../shared/Table/MultiSelectColumnFilter";
import calculateReservationPrice from "../../../utils/calculateReservationPrice";
import EllipsisDropdown from "../../shared/Table/EllipsisDropdown";
import EditableCell from "../../shared/Table/EditableCell";
import { trainerTableContext } from "../../../utils/trainerTableContext";

const ReservationsTable = ({ reservations }) => {
  // Data fed to the table
  const preppedData = reservations.map(reservation => {
    const { uid, stallCount, stallType, show, showSegments } = reservation;
    return {
      ...reservation,
      showSegmentNames: reservation.showSegments.map(({ name }) => name),
      reservationPrice: `$${calculateReservationPrice(
        stallCount,
        stallType,
        showSegments.map(({ uid }) => uid),
        show,
      ).toFixed(2)}`,
      isEditing: false,
    };
  });

  const [editRowNumber, setEditRowNumber] = useState(null); //This is the index number of the row we need to edit when user presses edit
  const [stallCount, setStallCount] = useState(); //To update stall count for editing no. of stall

  // Columns fed to the table
  const columns = useMemo(() => [
    {
      accessor: "uid",
      disableFilters: true,
    },
    {
      Header: "Show",
      accessor: "show.name",
      disableFilters: true,
    },
    {
      Header: "Type",
      accessor: "stallType.name",
      disableFilters: true,
    },
    {
      Header: "Weeks",
      accessor: "showSegmentNames",
      Cell: MultiPill,
      disableFilters: true,
    },
    {
      Header: "# OF STALLS",
      accessor: "stallCount",
      Cell: EditableCell,
      disableFilters: true,
    },
    {
      Header: "Total",
      accessor: "reservationPrice",
      disableFilters: true,
    },
    {
      Header: "Status",
      accessor: "state",
      Cell: StatePill,
      Filter: MultiSelectColumnFilter,
      filter: "includesSome",
    },
    {
      accessor: "edit",
      Cell: cellObj => <EllipsisDropdown cellObj={cellObj} type={"trainer"} />,
      disableSortBy: true,
      disableFilters: true,
    },
  ]);

  return (
    <trainerTableContext.Provider
      value={{ editRowNumber, setEditRowNumber, stallCount, setStallCount }}
    >
      {/* Get row props enable us to add custom styles to rows based on certain row conditions */}
      <Table
        columns={columns}
        data={preppedData}
        getRowProps={row => ({
          style: {
            boxShadow:
              row.index === editRowNumber
                ? "0px 10px 30px rgba(0, 0, 0, 0.2)"
                : "0px 0px 0px rgba(0, 0, 0, 0.0)",
            borderRadius: row.index === editRowNumber ? 8 : 0,
          },
        })}
      />
    </trainerTableContext.Provider>
  );
};

export default ReservationsTable;
