import React from "react";
import { useQuery } from "@apollo/client";
import UserCard from "./UserCard";
import MyShowCard from "../../shared/MyShowCard";
import Loading from "../../shared/Loading";
import GetOrders from "../../../apollo/query/trainers/GetOrders";
import RenderOrder from "./RenderOrder";
import ErrorModal from "../../shared/ErrorModal";
import { MODAL_MESSAGES } from "../../../constants/consts";
import { formatDateRange } from "../../../utils/date.utils";
import { default as _ } from "lodash";
import { useSearchParams } from "react-router-dom";

const AccountScreen = () => {
  const [myShows, setMyShows] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { loading: loadingOrders, data: orderData, error } = useQuery(GetOrders);

  // Takes shop uid from the url on checkout redirect
  React.useEffect(() => {
    const shopuid = searchParams.get("shopUid");
    if (shopuid) {
      clearShopCart(shopuid);
    }
  }, []);

  // Clears the shop cart on successful redirect
  async function clearShopCart(shopUid) {
    await window.localStorage.removeItem(shopUid);
  }

  // Get unique list of shows
  React.useEffect(() => {
    if (orderData) {
      const shows = _.unionBy(orderData.trainerOrders, "shop");
      setMyShows(shows);
    }
  }, [orderData]);

  return (
    <div className="px-4 md:px-6 lg:px-8 xl:px-24 2xl:px-28 relative">
      {/* Heading */}
      <p className="font-bold text-xl mt-3">Home</p>
      {/* User Card */}
      <div className="mt-3">
        <UserCard />
      </div>
      {/* User Shows */}
      <div className="mt-6">
        <p className="font-bold text-xl">Shows</p>
        <div className="max-h-80 w-full overflow-y-auto md:h-52 md:overflow-y-hidden">
          {loadingOrders ? (
            <div className="flex justify-center items-center h-full w-full">
              <Loading inline={true} color="gray" size="xl" />
            </div>
          ) : (
            <div className="md:flex md:flex-row md:w-full md:overflow-x-auto">
              {(!error && !orderData.trainerOrders) ||
              (!error && orderData.trainerOrders.length < 1) ? (
                <div className="flex mt-2 justify-center items-center w-full">
                  <p>No shows found!</p>
                </div>
              ) : (
                myShows.map(order => (
                  <div className="mt-2 md:mr-6" key={order.uid}>
                    <MyShowCard
                      show={order.shop.show}
                      image={order.shop.imageUrl}
                      shopUid={order.shop.uid}
                      date={formatDateRange(order.shop.startDate, order.shop.endDate)}
                    />
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
      {/* User Orders */}
      <div className="mt-6 mb-6">
        <p className="font-bold text-xl">Orders</p>
        {loadingOrders ? (
          <div className="flex justify-center items-center w-full">
            <Loading inline={true} color="gray" size="xl" />
          </div>
        ) : (
          <div className="mt-2 mb-6">
            <div className="bg-profile-gray px-2 md:px-4 xl:px-6 py-4 w-full rounded rounded-md h-80">
              <ul className="flex flex-row w-full items-center mt-4">
                <li className="text-xs flex-basis-30 flex text-left items-center justify-start">
                  SHOP NAME
                </li>
                <li className=" text-xs flex-basis-30 flex text-center items-center justify-center">
                  TOTAL
                </li>
                <li className="text-xs flex-basis-30 flex text-right items-center justify-end">
                  DELIVERED
                </li>
              </ul>
              {/* Rendering orders in a list */}
              <div className="mt-3 h-5/6 overflow-y-auto">
                {!error &&
                  orderData.trainerOrders.map(order => (
                    <RenderOrder key={order.uid} order={order} />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      {error && (
        <ErrorModal
          buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
          onClick={() => window.location.reload()}
          heading={MODAL_MESSAGES.ERRORHEADING}
          text={MODAL_MESSAGES.ERRORTEXT}
        />
      )}
    </div>
  );
};

export default AccountScreen;
