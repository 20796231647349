import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query GetTrainerOrders {
    trainerOrders {
      uid
      total
      shop {
        uid
        name
        imageUrl
        startDate
        endDate
        show {
          uid
          name
          venue {
            name
          }
        }
      }
      user {
        uid
      }
      deliveryDate
      deliveryTime {
        uid
        name
      }
      lineItems {
        uid
        quantity
        isRefund
        calculatedQuantity
        activeProduct {
          uid
          price
          product {
            uid
            name
          }
        }
      }
    }
  }
`;

// Example Data:
// {
//   "orderUid": "order-graphql_trainer"
// }
