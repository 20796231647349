import React from "react";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

export const Control = props => {
  return (
    <components.Control {...props}>
      <div className="w-full flex justify-center ">
        <FontAwesomeIcon style={{ color: "#BDBDBD" }} icon={faEllipsisH} />
        {props.children}
      </div>
    </components.Control>
  );
};
