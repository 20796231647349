import { gql } from "@apollo/client";

export default gql`
  query GetAllShops {
    allShops {
      uid
      name
      allowSameDayDeliveries
      enabled
      currency
      startDate
      endDate
      createdAt
      updatedAt
      deliveryTimes {
        uid
        name
      }
      enabledActiveProducts {
        uid
        displayPrice
        product {
          imageUrl
          name
        }
      }
      imageUrl
      organization {
        uid
        name
      }
      show {
        uid
        name
      }
    }
  }
`;
