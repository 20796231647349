import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLocalStorage from "../../hook/useLocalStorage";
import { Transition } from "@headlessui/react";

const LeftNav = ({ children, sections }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [navExpanded, setNavExpanded] = useLocalStorage("leftNavExpanded", true);
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    // Check if being viewed on mobile or desktop/laptop screen
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // Setting a maximum width for mobile devices
  let isMobile = width <= 640;

  const activePath = path => {
    return path === location.pathname;
  };

  if (navExpanded) {
    return (
      <div className="flex flex-row w-full sm:h-screen min-h-screen">
        {/* Normal view rendered on a large display */}
        {!isMobile ? (
          <>
            <div className="flex flex-col h-full sm:w-1/4 w-1/5 xl:w-1/6 pt-12 px-6 bg-dark-gray">
              {/* Sections: different nav menu items */}
              {sections.map(({ path, icon, name }) => (
                <button
                  key={path}
                  className={`w-full flex flex-row rounded items-center justify-start py-1 my-2 pr-3 ${
                    activePath(path)
                      ? "bg-medium-gray text-white"
                      : "bg-dark-gray text-another-gray opacity-50"
                  }`}
                  onClick={() => navigate(path)}
                >
                  <img className="h-4 pl-3" src={icon} alt={name} />
                  <div className="pl-1 sm:text-xs lg:text-base">{name}</div>
                </button>
              ))}
            </div>
            <div className="flex flex-col overflow-auto sm:w-3/4 w-4/5 xl:w-5/6 bg-light-gray pb-12 min-h-screen h-full">
              {children}
            </div>
          </>
        ) : (
          // This view is rendered on a mobile device
          <>
            <div className="flex flex-col w-screen bg-light-gray pb-12 min-h-screen h-full">
              <div className="flex flex-col ">
                {/* Button for the nav bar starts here */}
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-dark-gra inline-flex items-center justify-center p-2 text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
                {/* Transition component responsible for displaying the nav menu items when nav button is pressed */}
                <Transition
                  show={isOpen}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {ref => (
                    <div className="flex flex-col sm:w-full bg-dark-gray">
                      <div className="md:hidden" id="mobile-menu">
                        {/* Sections: different nav menu items */}
                        {sections.map(({ path, icon, name }) => (
                          <button
                            key={path}
                            className={`w-full flex flex-row rounded items-center justify-start py-1 my-2 pr-3 ${
                              activePath(path)
                                ? "bg-medium-gray text-white"
                                : "bg-dark-gray text-another-gray opacity-50"
                            }`}
                            onClick={() => navigate(path)}
                          >
                            <img className="h-4 pl-3" src={icon} alt={name} />
                            <div className="pl-2 pr-4">{name}</div>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </Transition>
              </div>
              {children}
            </div>
          </>
        )}
      </div>
    );
  }
  return <div>Collapsed navbar placeholder</div>;
};

export default LeftNav;
