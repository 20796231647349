import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { AdminAddShowSegment } from "../../../../apollo/mutation/admins/show_segments/AdminAddShowSegment";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import parseISO from "date-fns/parseISO";

const AddShowSegmentForm = ({ showUid, setAddShowSegment, refetchShowList }) => {
  const [showSegmentName, setShowSegmentName] = useState("");
  const [showSegmentStartDate, setShowSegmentStartDate] = useState(new Date());
  const [showSegmentEndDate, setShowSegmentEndDate] = useState(new Date());

  const [adminAddShowSegment] = useMutation(AdminAddShowSegment);

  const handleAddShowSegment = () => {
    adminAddShowSegment({
      variables: {
        name: showSegmentName,
        showUid: showUid,
        showSegmentStart: moment(showSegmentStartDate)
          .tz("America/Los_Angeles")
          .format("YYYY-MM-DD"),
        showSegmentEnd: moment(showSegmentEndDate)
          .tz("America/Los_Angeles")
          .format("YYYY-MM-DD"),
      },
    })
      .then(res => {
        setAddShowSegment(false);
        refetchShowList();
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            <div className="flex flex-col">
              <p className="text-3xl font-bold ">Add Show Segment</p>
              <label htmlFor="show-segment" className="flex flex-col mt-3">
                Show Segment Name
                <input
                  type="text"
                  id="show-segment"
                  value={showSegmentName}
                  onChange={e => setShowSegmentName(e.target.value)}
                />
              </label>
              <div className="flex justify-between w-6/12">
                <div className="flex flex-col mt-3">
                  Show Segment Start Date
                  <DatePicker
                    className="w-full"
                    selected={showSegmentStartDate}
                    maxDate={parseISO(showSegmentEndDate)}
                    onChange={date => setShowSegmentStartDate(date)}
                  />
                </div>
                <div className="flex flex-col mt-3">
                  Show Segment End Date
                  <DatePicker
                    className="w-full"
                    minDate={showSegmentStartDate}
                    selected={showSegmentEndDate}
                    onChange={date => setShowSegmentEndDate(date)}
                  />
                </div>
              </div>
              <button
                className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                disabled={
                  showSegmentEndDate.length < 1 &&
                  showSegmentName.length < 1 &&
                  showSegmentStartDate.length < 1
                }
                onClick={handleAddShowSegment}
              >
                Add Show Segment
              </button>
            </div>
          </div>
          <button
            onClick={() => setAddShowSegment(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddShowSegmentForm;
