import React, { useState, useEffect } from 'react';
import { formatDate } from "../../../utils/date.utils";
import { centsToDollarStr } from '../../../utils/money.utils';

import RefundQuantityInput from './RefundQuantityInput';
import RefundConfirmationModal from './RefundConfirmationModal';

const PartialRefundForm = ({order, refetch, setSidePanelOpen}) => {
  const [refundQuantities, setRefundQuantities] = useState({});
  const [refundReason, setRefundReason] = useState('');
  const [refundConfirmationOpen, setRefundConfirmationOpen] = useState(false);
  const [validRefund, setValidRefund] = useState(false);
  const [isFullRefund, setIsFullRefund] = useState(false);
  const [calculatedRefundQuantities, setCalculatedRefundQuantities] = useState({});

  // console.log(`PartialRefundForm - order: ${JSON.stringify(order)}`);

  useEffect(() => {
    //TODO: Validate to ensure quantities don't exceed original order quantities
    const refundQuantityTotal = Object.keys(refundQuantities).reduce((partialSum, k) => partialSum + (refundQuantities[k] || 0), 0);
    setValidRefund(isFullRefund || refundQuantityTotal > 0);
    setCalculatedRefundQuantities(calcRefundQuantities());
  }, [refundQuantities, isFullRefund]);

  const calcRefundQuantities = () => {
    if (isFullRefund) {
      const ret = order.lineItems.filter(li => !li.isRefund).map(li => {
        console.log('calcRefundQuantities: ', li.uid, li.calculatedQuantity);
        return [li.uid, li.calculatedQuantity];
      }).reduce((acc, vals) => {
        acc[vals[0]] = vals[1];
        return acc;
      }, {});
      console.log(ret);
      return ret;
    }
    return refundQuantities;
  }

  const handleRefundReasonChange = (e) => {
    setRefundReason(e.target.value);
  }

  return (
    <div className="flex flex-col jusify-start items-start overflow-y-auto w-full">
      <div className="text-3xl">Refund Order</div>
      <div className="text-lg font-semibold">#{order.uid}</div>
      <div className="flex flex-row justify-between mt-3 w-full">
        {/* user details */}
        <div className="flex flex-basis-50 flex-col">
          <p className="text-xs font-semibold text-subdued-gray">USER DETAILS</p>
          <p className="capitalize text-xs">{order.user.name}</p>
          <p className="text-xs">{order.user.email}</p>
          <p className="text-xs">{order.user.phone}</p>
        </div>
        {/* Show details */}
        <div className="flex flex-basis-50 flex-col items-end">
          <p className="text-xs font-semibold text-subdued-gray">SHOW DETAILS</p>
          <p className="text-xs">{order.shop.show.name}</p>
        </div>
      </div>
      {/* DELIVERY DETAILS */}
      <div className="flex flex-row mt-4 justify-between w-full border-b pb-2">
        <div className="flex flex-basis-50 flex-col">
          <p className="text-xs font-semibold text-subdued-gray">DELIVERY DETAILS</p>
          <p className="text-xs">
            {order.deliveryDate && formatDate(order.deliveryDate)}
          </p>
          <p className="text-xs text-subdued-gray">
            {order.deliveryTime?.name}
          </p>
          <br/>
          <p className="text-xs">Delivered By: {order.deliveredBy?.name}</p>

        </div>
        {/* Stabling With */}
        <div className="flex flex-basis-50 flex-col items-end">
          <p className="text-xs font-semibold text-subdued-gray">STABLING WITH</p>
          <p className="text-xs capitalize">{order.stablingWith}</p>
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="flex justify-between z-20 sticky mt-3 text-subdued-gray">
          <p className="flex flex-basis-50 items-center text-xs justify-start">ITEM</p>
          <p className="flex flex-basis-15 items-center text-xs justify-center">QUANTITY</p>
        </div>
        {order.lineItems.filter(li => !li.isRefund).map(item => (
          <div key={item.uid} className="flex flex-row justify-between mt-2 w-full">
            <div className="flex flex-basis-10 xl:flex-basis-40 items-center text-sm justify-start">{item.activeProduct.product.name}</div>
            <RefundQuantityInput
              classNames="flex-basis-45 xl:flex-basis-20 2xl:flex-basis-45 items-center text-sm"
              lineItem={item}
              order={order}
              enabled={!isFullRefund}
              refundQuantities={refundQuantities}
              setRefundQuantities={setRefundQuantities}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-row justify-end pt-3 w-full items-center">
        <p className="text-md text-subdued-gray mr-3">Refund entire order?</p>
        <input
          className="rounded mr-3"
          type="checkbox"
          value={isFullRefund}
          onChange={() => setIsFullRefund(!isFullRefund)}
        />
      </div>
      <div className="flex flex-col items-start w-full px-2">
        <input
          className="w-full rounded mt-6"
          type="text"
          placeholder="Reason for refund (optional)"
          value={refundReason}
          onChange={handleRefundReasonChange}
        />
      </div>
      <div className="flex flex-row justify-end w-full mt-6">
        <button
          className={[
            "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md z-13",
            (
              validRefund && !refundConfirmationOpen ?
                "bg-button-green text-white hover:bg-gray-50 hover:text-gray-700"
              :
                "bg-gray-400 text-white cursor-not-allowed focus:outline-none"
            ),
          ].join(' ')}
          onClick={() => setRefundConfirmationOpen(true)}
          disabled={!validRefund}
        >
          Refund
        </button>
        <button
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => setSidePanelOpen(false)}
        >
          Cancel
        </button>
      </div>
      <RefundConfirmationModal
        isOpen={refundConfirmationOpen}
        setIsOpen={setRefundConfirmationOpen}
        order={order}
        refundQuantities={calculatedRefundQuantities}
        refundReason={refundReason}
        isFullRefund={isFullRefund}
        setSidePanelOpen={setSidePanelOpen}
        refetch={refetch}
      />
    </div>
  )
}

export default PartialRefundForm;
