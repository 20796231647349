import React from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { PATHS } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";

const PrivateRoute = () => {
  const { firebaseUser } = useAuth();
  const location = useLocation();
  return firebaseUser ? (
    <Outlet />
  ) : (
    <Navigate to={PATHS.LOGIN} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
