import React from "react";
import { useTable } from "react-table";

const ConfirmationTable = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  return (
    <div className="h-12">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-200 overflow-y-auto"
      >
        <tbody {...getTableBodyProps()} className="bg-white">
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className="px-6 py-2 whitespace-nowrap">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ConfirmationTable;
