/* ===================================== AUDIT LOG ========================================= *
 * title: ItemSelectCard
 * Author: Faizan Omer
 * Created on: 03/02/22
 * Description: A card component for selecting the item and adding it to cart
 * ========================================================================================== */

import React, { useEffect, useState } from "react";
import QuantityCounter from "./QuantityCounter";

const ItemSelectCard = ({
  itemName,
  itemImage,
  itemPrice,
  itemType,
  itemDescription,
  itemUid,
  onAddItem,
  itemDisplayPrice,
  storedQty,
}) => {
  const [quantityCount, setQuantityCount] = useState(0);

  // updateCart on quantity change
  useEffect(() => {
    const product = {
      uid: itemUid,
      name: itemName,
      price: itemPrice,
      itemType: itemType,
      displayPrice: itemDisplayPrice,
      shortDescription: itemDescription,
      qty: quantityCount,
    };
    onAddItem(product);
  }, [quantityCount]);

  // Set quantity counters values
  useEffect(() => {
    if (storedQty) {
      setQuantityCount(storedQty);
    }
  }, [storedQty]);

  // increments quantity by 1
  function onIncrementQuantity() {
    setQuantityCount(prev => prev + 1);
  }

  // decrements quantity by 1 if quantity is 0 returns 0
  function onDecrementQuantity() {
    setQuantityCount(prev => {
      if (prev == 0) {
        return 0;
      } else {
        return prev - 1;
      }
    });
  }

  // updates quantity as per user input
  function onChangeQuantity(e) {
    setQuantityCount(Number(e.target.value));
  }

  return (
    <div className={[
      "flex flex-row items-center p-2 w-full",
      "md:w-auto md:bg-shop-gray md:rounded-lg",
    ].join(" ")}>
      {/* Image section */}
      <div className="flex flex-grow-0 justify-center items-center rounded-xl w-14 h-14 xl:w-20 xl:h-20">
        <img
          src={itemImage}
          className="w-full h-full object-cover rounded-xl  bg-white shadow"
        />
      </div>
      {/* Item name and description section */}
      <div className="flex flex-grow flex-col pl-3 md:pl-0 w-36 sm:w-fit lg:w-44 ml-3 sm:ml-10 items-start">
        <p className="font-bold text-sm md:text-base 2xl:text-lg">{itemName}</p>
        {itemDescription ? (
          <p className="text-xs md:text-base 2xl:text-lg whitespace-nowrap">
            {itemDescription}
          </p>
        ) : null}
      </div>
      {/* Item price section */}
      <p className="text-sm md:text-base 2xl:text-lg lg:ml-5 lg:w-24 px-3">
        {itemDisplayPrice}
      </p>
      <div className="lg:ml-5">
        <QuantityCounter
          quantityCount={quantityCount}
          onDecrementQuantity={onDecrementQuantity}
          onIncrementQuantity={onIncrementQuantity}
          onChangeQuantity={onChangeQuantity}
        />
      </div>
    </div>
  );
};

export default ItemSelectCard;
