import React, { useState } from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { OrgAdminsOrderFullRefund } from "../../../apollo/mutation/orgAdmins/order/FullRefund";

import SidePanel from '../../shared/SidePanel';
import Modal from '../../shared/Modal';
import PartialRefundForm from './PartialRefundForm';

const ACTION_OPTIONS = {
  partialRefund: 'partialRefund',
  fullRefund: 'fullRefund',
  markDelivered: 'markDelivered',
};

const EllipsesCell = ({ row, refetch }) => {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [fullRefundModalOpen, setFullRefundModalOpen] = useState(false);
  const [fullRefundModalButtonDisabled, setFullRefundModalButtonDisabled] = useState(false);

  const [orgAdminOrderFullRefund, {
    loading: fullRefundLoading,
    error: fullRefundError,
    data: fullRefundData
  }] = useMutation(OrgAdminsOrderFullRefund);

  const options = [
    {
      label: 'Issue Refund',
      value: ACTION_OPTIONS.partialRefund,
      isDisabled: () => {
        console.log('row.values.payment_status', row.values)
        return row.values.paymentStatus === 'refunded'
      },
    },
    // {
    //   label: 'Full Refund',
    //   value: ACTION_OPTIONS.fullRefund,
    // },
    // {
    //   label: 'Mark as Delivered',
    //   value: ACTION_OPTIONS.markDelivered,
    // },
  ];

  const handleAction = ({label, value}) => {
    if (value === ACTION_OPTIONS.partialRefund) {
      setSidePanelOpen(true);
    }
    if (value === ACTION_OPTIONS.fullRefund) {
      setFullRefundModalOpen(true);
    }
  }

  const requestFullRefund = (reason) => {
    setFullRefundModalButtonDisabled(true)
    orgAdminOrderFullRefund({
      variables: {
        orderUid: row.original.uid,
        reason: reason,
      },
    })
  };

  const closeSidePanel = e => {
    e?.preventDefault && e.preventDefault();
    setSidePanelOpen(false);
  }

  const CustomControl = props => (
    <components.Control {...props}>
      <FontAwesomeIcon icon={faEllipsisH} fixedWidth size="sm" />
      {props.children}
    </components.Control>
  )

  const customComponents = {
    Control: CustomControl,
    SingleValue: () => null,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null,
    DropdownIndicator: () => null,
  }

  const customStyle = {
    container: (base, state) => ({
      ...base,
      zIndex: state.selectProps.menuIsOpen ? 30 : 0,
      overflow: 'visible',
    }),
    control: base => ({
      ...base,
      border: 0,
      backgroundColor: "inherit",
      boxShadow: 0,
      zIndex: 9999,
    }),
    valueContainer: base => ({
      ...base,
      border: 0,
      borderColor: "#FFFF",
    }),
    menu: base => ({
      ...base,
      width: "auto",
      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2);",
      margin: 0,
    }),
    menuList: base => ({
      ...base,
      textAlign: "center",
      zIndex: 10000,
      lineHeight: '1rem',
      fontSize: '0.75rem',
    }),
    option: (provided, state) => {
      let res;
      if (state.data.isDisabled()) {
        res = {
          ...provided,
          backgroundColor: "#FFFF",
        };
      }
      else {
        res = {
          ...provided,
          backgroundColor: "#FFFF",
          "&:hover": {
            backgroundColor: "#FFFF",
            cursor: "pointer",
          },
          // borderBottom: state.data.value === "refund" ? 0 : "1px solid rgb(237, 237, 240)",
          color: state.data.value === "refund" ? "#FB3131" : "inherit",
        };
      }
      return res;
    },
  }

  return (
    <>
      <Select
        components={customComponents}
        options={options}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        placeholder={null}
        isClearable={false}
        isSearchable={false}
        onChange={handleAction}
        controlShouldRenderValue={false}
        styles={customStyle}
        menuPosition={'fixed'}
        isOptionDisabled={(option) => option.isDisabled()}
      />
      {sidePanelOpen && (
        <SidePanel close={closeSidePanel}>
          <PartialRefundForm order={row.original} refetch={refetch} setSidePanelOpen={setSidePanelOpen} />
        </SidePanel>
      )}
      {fullRefundModalOpen && (
        <Modal
          heading="Fully Refund Order"
          text="sample text"
          buttonMessage="Refund"
          onClick={() => requestFullRefund()}
        >
          <div>Add order details here</div>
        </Modal>
      )}
    </>
  );
};

export default EllipsesCell;
