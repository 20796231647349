import { gql } from "@apollo/client";

export default gql`
  query getShowsByOrganization($organizationUid: String!) {
    organizationShows(organizationUid: $organizationUid) {
      uid
      name
    }
  }
`;
