/* ===================================== AUDIT LOG ========================================= *
 * Title: Store Selection Screen
 * Author: Faizan Omer
 * Created on: 03/14/22
 * Description: User's can use this screen to reset their password if they forget it
 * ========================================================================================== */

import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/consts";

const PasswordReset = () => {
  const { confirmPasswordReset } = useAuth();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  //   Checks if password's  match whenever user changes password inputs
  useEffect(() => {
    if (newPassword === confirmPassword) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  }, [newPassword, confirmPassword]);

  // Sets new password state based on user input
  function inputPassword(e) {
    const password = e.target.value;
    setNewPassword(password);
  }

  // Sets confirm password state based on user input
  function inputConfirmPassword(e) {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  }

  //   If password's match set new password and validate verification code else set passwordsMatch to false
  function changePassword(e) {
    //  Preventing page from reloading
    e.preventDefault();
    const resetCode = searchParams.get("oobCode"); //Gets reset code after searching from firebase url
    if (newPassword && resetCode && confirmPassword && newPassword === confirmPassword) {
      try {
        confirmPasswordReset(resetCode, newPassword).then(() => {
          navigate(PATHS.LOGIN);
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      console.log("Something Went Wrong");
    }
  }

  return (
    <div className="flex flex-col h-screen w-screen items-center justify-center">
      <p>Enter the password reset code you received in your Email.</p>
      <form action="" className="flex flex-col mt-5" onSubmit={e => changePassword(e)}>
        {/* New Password */}
        <label htmlFor="new-passowrd" className="mt-3 flex flex-col">
          New Password
          <input type="password" onChange={e => inputPassword(e)} />
        </label>
        {/* Confirm New Password */}
        <label htmlFor="confirm-passowrd" className="mt-3 flex flex-col">
          Confirm New Password
          <input type="password" onChange={e => inputConfirmPassword(e)} />
        </label>
        {!passwordsMatch ? (
          <p className="text-warning-red">The passwords don't match</p>
        ) : null}
        {/* Submit Button */}
        <button type="submit" className="w-44 h-8 bg-blue-500 text-white mt-5">
          Confirm
        </button>
      </form>
    </div>
  );
};

export default PasswordReset;
