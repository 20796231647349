import React from "react";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import { AdminAddVenue } from "../../../../apollo/mutation/admins/venues/AdminAddVenue";

const AddVenueForm = ({ setVenueForm, refetchVenueList }) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState("");
  const [venueName, setVenueName] = React.useState("");
  const { loading, data, error } = useQuery(GetAllOrganizations);

  const [adminAddVenue] = useMutation(AdminAddVenue);

  const orgOptions =
    !loading && data
      ? data.allOrganizations.map(org => {
          return { value: org.uid, label: org.name };
        })
      : null;

  const addNewVenue = () => {
    adminAddVenue({
      variables: {
        name: venueName,
        organizationUid: selectedOrganization,
      },
    })
      .then(res => {
        setVenueForm(false);
        refetchVenueList();
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6">
            <p className="font-bold text-xl mt-4">Select Organization</p>
            <Select
              placeholder={"Select Organization"}
              className="mt-3"
              options={orgOptions && orgOptions}
              isSearchable={false}
              closeMenuOnSelect={true}
              closeMenuOnScroll={() => true}
              hideSelectedOptions={false}
              isClearable={false}
              onChange={selected => setSelectedOrganization(selected.value)}
            />
            {selectedOrganization.length > 0 && (
              <div className="mt-4">
                <p className="font-bold text-xl mt-2">Venue Name</p>
                <input
                  type="text"
                  className="w-full mt-2"
                  value={venueName}
                  onChange={e => setVenueName(e.target.value)}
                />
                <button
                  className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                  onClick={addNewVenue}
                  disabled={venueName.length < 1}
                >
                  Add Venue
                </button>
              </div>
            )}
          </div>

          <button
            onClick={() => setVenueForm(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full mt"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddVenueForm;
