import React, { cloneElement } from "react";

import { Outlet } from "react-router-dom";

import { TopNav } from "../navigation";

const TopNavLayout = ({ onOrgSelect, hideBreadCrumbs, layoutClassName, hideOrgSelect, topNavClassName, children, ...rest }) => {
  return (
    <div className={layoutClassName}>
      <TopNav className={topNavClassName} onOrgSelect={onOrgSelect} hideBreadCrumbs={hideBreadCrumbs} hideOrgSelect={hideOrgSelect} />
      {
        children ? (
          <>{cloneElement(children, { ...rest })}</>
        ) : (
          <Outlet />
        )
      }
    </div>
  );
};

export default TopNavLayout;
