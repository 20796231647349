import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ProductSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { product } = location.state;

  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Product Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p className="mt-2"> {product.name}</p>

        {/* Logo */}
        <p className="font-bold mt-3">Image:</p>
        <img src={product.imageUrl} className="mt-2 w-36 h-28" />

        {/* Organization */}
        <p className="font-bold mt-3">Organization:</p>
        <p className="mt-2"> {product.organization.name}</p>

        {/* Description */}
        <p className="font-bold mt-3">Description:</p>
        <p className="mt-2"> {product.description}</p>

        {/* Short Description */}
        <p className="font-bold mt-3">Short Description:</p>
        <p className="mt-2"> {product.shortDescription}</p>

        {/* Status */}
        <p className="font-bold mt-3">Enabled Status:</p>
        <p className="mt-2"> {product.enabled.toString()}</p>

        {/* Created At/Update At */}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {product.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{product.updatedAt}</p>
      </div>
    </div>
  );
};

export default ProductSummary;
