import React from "react";

import { ORDER_DELIVERY_STATUSES } from "../../../constants/consts";

const DeliveryStatusPill = ({ row }) => {
  let displayStatus;

  const pillFormat = status => {
    if (status === ORDER_DELIVERY_STATUSES.COMPLETED) {
      return "bg-green-300 text-green-700";
    }

    return "bg-gray-100 text-gray-700";
  };

  return (
    <span
      className={[
        "px-12 py-1 my-1 uppercase leading-wide font-bold text-xs 2xl:text-md rounded-full shadow-sm",
        pillFormat(row.original.deliveryStatus),
      ].join(" ")}
    >
      {row.original.deliveryStatus}
    </span>
  );
};

export default DeliveryStatusPill;
