import {gql} from '@apollo/client'
// FIXME: This should be a .gql file
export default gql`
  mutation CancelStallReservation(
      $stallReservationUid: String!,
  		$note: String
  ) {
    cancelStallReservation(input: {
      stallReservationUid: $stallReservationUid,
      note: $note
    }) {
      stallReservation {
        uid
        note
        state
      }
      errors
    }
  }
`
