import React from "react";
import { useQuery } from "@apollo/client";
import GetAllActiveProducts from "../../../../apollo/query/admins/active_products/GetAllActiveProducts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ActiveProductCard from "./ActiveProductCard";
import Loading from "../../../shared/Loading";
import AddActiveProductForm from "./AddActiveProductForm";

const AdminManageActiveProducts = () => {
  const { loading, error, data, refetch } = useQuery(GetAllActiveProducts);
  const [addActiveProduct, setAddActiveProduct] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allActiveProducts
      : data?.allActiveProducts.filter(
          activeProduct =>
            activeProduct.product.name.toLowerCase().includes(lowercasedFilter) ||
            activeProduct.shop.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Active Products</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setAddActiveProduct(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-5 items-center justify-start"></p>
        <p className="flex flex-basis-25 items-center justify-start">Name</p>
        <p className="flex flex-basis-25 items-center justify-start">Shop</p>
        <p className="flex flex-basis-15 items-center justify-start">Price</p>
        <p className="flex flex-basis-5 items-center justify-center">Status</p>
        <p className="flex flex-basis-5 items-center justify-center"></p>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Display all Products */}
      {data &&
        !loading &&
        filteredData &&
        filteredData.length > 0 &&
        filteredData.map(product => (
          <div key={product.uid}>
            <ActiveProductCard
              activeProduct={product}
              refetchActiveProductList={refetch}
            />
          </div>
        ))}
      {addActiveProduct && (
        <AddActiveProductForm
          setAddActiveProduct={setAddActiveProduct}
          refetchActiveProductList={refetch}
        />
      )}
    </div>
  );
};

export default AdminManageActiveProducts;
