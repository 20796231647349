export const centsToDollarStr = (cents) => {
  return `$${(cents/100).toLocaleString("en-US", {style: "decimal", minimumFractionDigits: 2})}`;
};

export const centsToDollarStrSummary = (cents) => {
  if (cents > 100_000) {
    const thousands = cents/100_000
    const decimals = thousands.toFixed(0).length > 3 ? 0 : (3 - thousands.toFixed(0).length)
    return `$${(thousands).toFixed(decimals)}K`;
  }
  else {
    const dollars = cents/100
    return `$${dollars.toFixed(dollars.toFixed(0).length > 3 ? 0 : 2)}`;
  }
};
