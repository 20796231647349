/* ===================================== AUDIT LOG ========================================= *
 * Title: Error Modal
 * Author: Faizan Omer
 * Created on: 03/29/22
 * Description: A Simple Generic Modal used for notifying users an error occured
 * ========================================================================================== */

import React from "react";

const Modal = ({ heading, text, buttonMessage, onClick, children, disableButton = false, close}) => {
  const onClose = () => {
    console.log('Shared/Modal:close fn');
    close && close();
  };

  return (
    <>
      <div className="flex flex-col justify-end sm:justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mb-20 sm:mb-0 md:w-6/12 lg:w-4/12 my-6 mx-auto max-w-3xl h-52 sm:w-full h-1/2">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pl-3 pr-6">
            <div className="relative p-6 flex-auto flex flex-col">
              <div className="flex flex-row mt-2 items-center justify-center">
                <p className="font-bold text-xl">{heading}</p>
              </div>
              {children ? children : <p className="mt-3">{text}</p>}
              <div className="flex flex-row justify-end mt-12 self.end">
                <button
                  className="text-white text-s font-bold rounded bg-button-green p-2 h-10 w-full"
                  onClick={onClick}
                  disabled={disableButton}
                >
                  {buttonMessage}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black" onClick={onClose}></div>
    </>
  );
};

export default Modal;
