import React from "react";

import { Outlet } from "react-router-dom";

import { PATHS } from "../../../constants/consts";

import { DefaultLayout } from "../../layouts";

import { useQuery } from "@apollo/client";
import GetOrgStallReservations from "../../../apollo/query/GetOrgStallReservations";

import dashboardIcon from "../../../assets/dashboard-icon.png";
import bookmarkIcon from "../../../assets/bookmark-icon.png";
import trashIcon from "../../../assets/trash-icon.png";

import { useAuth } from "../../../contexts/AuthContext";
import { viewingAsOrgUser, getViewMode } from "../../../utils/user.utils";

const OrganizationRouting = () => {
  const { currentUser } = useAuth();
  const { selectedOrganization, organizations } = currentUser;
  const organizationUid = selectedOrganization?.uid || organizations[0].uid;
  const { loading, error, data, refetch } = useQuery(GetOrgStallReservations, {
    variables: { organizationUid },
  });

  const leftNavSections = [
    {
      path: PATHS.HOME,
      icon: dashboardIcon,
      name: "My Dashboard",
    },
    {
      path: PATHS.RESERVATIONS,
      icon: bookmarkIcon,
      name: "Reservations",
    },
  ];

  return (
    <>
      {viewingAsOrgUser(currentUser) ? (
        <DefaultLayout leftNavSections={leftNavSections} onOrgSelect={() => refetch()}>
          <Outlet />
        </DefaultLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default OrganizationRouting;
