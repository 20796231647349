import { gql } from "@apollo/client";

export const AdminRemoveProduct = gql`
  mutation AdminRemoveProduct($productUid: String!) {
    adminRemoveProduct(input: { productUid: $productUid }) {
      product {
        uid
        name
      }
      errors
    }
  }
`;
