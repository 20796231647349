/* ===================================== AUDIT LOG ========================================= *
 * Title: Store Selection Screen
 * Author: Faizan Omer
 * Created on: 03/9/22
 * Description: Trainers can select a show with its corresponding shop using this screen
 * ========================================================================================== */

import React, { useEffect } from "react";
import ShowCard from "../../Shows/ShowCard";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import GetShops from "../../../apollo/query/GetShops";
import AccountDropdown from "../../navigation/TopNav/AccountDropdown";
import { useAuth } from "../../../contexts/AuthContext";
import OrgSelect from "../../navigation/TopNav/OrgSelect";
import { viewingAsTrainer } from "../../../utils/user.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../shared/Loading";
import ListShopCard from "./ListShopCard";

const StoreSelectionScreen = () => {
  const location = useLocation();
  const { loading, data, error } = useQuery(GetShops);
  const { currentUser } = useAuth();
  const [allShops, setAllShops] = React.useState([]);

  //   Set All shops once data from query is returned
  useEffect(() => {
    if (!loading && data) {
      setAllShops(data.shops.nodes);
    }
  }, [data, setAllShops]);

  // if search query sent from another page
  useEffect(() => {
    if (location.state && location.state.searchShop && !loading && data) {
      const result = data.shops.nodes.filter(
        shop => shop.name.toLowerCase().search(location.state.searchShop) != -1,
      );
      setAllShops(result);
    }
  }, [location]);

  // The following function is responsible for displaying the matched search results
  function handleSearchShop(e) {
    const inputValue = e.target.value;
    const result = data.shops.nodes.filter(
      shop => shop.name.toLowerCase().search(inputValue.toLowerCase()) != -1,
    );
    setAllShops(result);
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="flex flex-row justify-center">
        <div className="w-0 lg:flex-basis-10 lg:w-full lg:h-full max-w-20"></div>
        <div className="flex flex-col items-center max-w-lg w-full">
          {/* Section for Page heading and search bar starts here */}
          <div className="flex justify-between my-5 px-3 xl:mx-56 w-full">
            <p className="font-bold text-xl sm:text-2xl lg:text-3xl xl:text-4xl">
              Feed &amp; Bedding
            </p>
            {/* Search bar for searching shops */}
            <div className="flex relative ring-1 ring-black ring-opacity-5 focus:outline-dark-gray h-full items-center">
              <span
                data-testid="search"
                className="flex absolute inset-y-0 left-0 text-search-gray items-center h-full pl-2 py-1"
              >
                <FontAwesomeIcon icon={faSearch} fixedWidth size="sm" />
              </span>
              <input
                className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none pl-8 py-1"
                name="search"
                placeholder="Search shops"
                onChange={handleSearchShop}
              />
            </div>
          </div>
          {!loading ? (
            <div className="flex flex-col items-center w-full">
              {allShops.length > 0 ? (
                <>
                  <div className="flex flex-col space-y-4 w-full mb-4">
                    {allShops.map(item => (
                      <ListShopCard shop={item} key={item.uid} />
                    ))}
                  </div>
                </>
              ) : (
                <div className="flex flex-row justify-center">
                  <p className="text-lg font-semibold">No Stores available</p>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center mt-16">
              <Loading color="gray" size="4x" inline={true} />
            </div>
          )}
        </div>
        <div className="w-0 lg:w-full lg:h-full lg:flex-basis-10"></div>
      </div>
    );
  }
};

export default StoreSelectionScreen;
