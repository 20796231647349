import { gql } from "@apollo/client";

export default gql`
  query GetAllProducts {
    allProducts {
      uid
      name
      imageUrl
      organization {
        uid
        name
      }
      description
      createdAt
      updatedAt
      shortDescription
      enabled
    }
  }
`;
