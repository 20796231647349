import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation BulkCancelStallReservation($stallReservationUids: [String!]!, $note: String) {
    bulkCancelStallReservation(
      input: { stallReservationUids: $stallReservationUids, note: $note }
    ) {
      stallReservations {
        uid
        note
        state
      }
      errors
    }
  }
`;
