import React, { cloneElement } from "react";
import { Outlet } from "react-router-dom";

import { LeftNav, TopNav } from "../navigation";

const DefaultLayout = ({ leftNavSections, onOrgSelect, hideBreadCrumbs, hideLogo, children, ...rest }) => {
  return (
    <LeftNav sections={leftNavSections}>
      <TopNav onOrgSelect={onOrgSelect} hideLogo={hideLogo} hideBreadCrumbs={hideBreadCrumbs} />
      <div className="mx-12">{cloneElement(children, { ...rest })}</div>
    </LeftNav>
  );
};

export default DefaultLayout;
