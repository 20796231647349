import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation FindOrCreateUser(
    $displayName: String
    $firstName: String
    $lastName: String
    $phone: String
    $phoneVerified: Boolean
    $email: String!
    $emailVerified: Boolean
    $photoUrl: String
    $firebaseId: String!
    $providerId: String!
  ) {
    findOrCreateUser(
      input: {
        displayName: $displayName
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        phoneVerified: $phoneVerified
        email: $email
        emailVerified: $emailVerified
        photoUrl: $photoUrl
        firebaseId: $firebaseId
        providerId: $providerId
      }
    ) {
      user {
        uid
        name
        displayName
        firstName
        lastName
        bio
        barnName
        phone
        phoneVerified
        email
        emailVerified
        photoUrl
        firebaseId
        providerId
        avatarUrl
        isAdmin
        organizations {
          uid
          name
          theme
          createdAt
          updatedAt
        }
        organizationRoles {
          uid
          organizationUid
          role
        }
      }
      errors
    }
  }
`;
