import React from "react";
import { useMutation } from "@apollo/client";
import { AdminUpdateUser } from "../../../../apollo/mutation/admins/users/AdminUpdateUser";

const UserEditProfile = ({ user, setShowModal, refetchUsersData, setSearchFilter }) => {
  const [displayName, setDisplayName] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);

  const [adminUpdateUser] = useMutation(AdminUpdateUser);

  const updateUser = () => {
    adminUpdateUser({
      variables: {
        userUid: user.uid,
        displayName: displayName,
        isAdmin: isAdmin,
      },
    })
      .then(res => {
        setShowModal(false);
        setSearchFilter("");
        refetchUsersData();
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6">
            {/* Heading */}
            <p className="text-3xl font-bold ">Edit User</p>
            <label htmlFor="display-name" className="flex flex-col mt-4">
              Display Name
              <input
                type="text"
                id="display-name"
                onChange={e => setDisplayName(e.target.value)}
              />
            </label>
            <label htmlFor="delivery-time" className="flex flex-col mt-4">
              Is Admin
              <input
                type="checkbox"
                id="delivery-time"
                onChange={e => setIsAdmin(!isAdmin)}
              />
            </label>
            <button
              onClick={updateUser}
              className="mt-5 text-white rounded h-10 md:h-14 shadow px-6 py-1 w-full md:text-base 2xl:text-lg font-bold  bg-button-green"
              disabled={displayName.length < 1}
            >
              Update User
            </button>
          </div>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UserEditProfile;
