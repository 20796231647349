import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";

import CustomCheckoutForm from "./CustomCheckoutForm";
// import "./StripeCustom.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(
  "pk_test_51KS0U4L5UEYlArDVsNiv9owlYscMYrgr6L8cMrYRU5Pnf7DH2iwfmBbZ9JgficwQm10LliX60rdyolbZVGlCaBQr00p7v6kQt4",
);

export default function StripeCustom() {
  const [clientSecret, setClientSecret] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const urlClientSecret = searchParams.get("client_secret");

  const handleSubmit = () => {
    // Create PaymentIntent as soon as the page loads
    fetch("/payments/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then(res => res.json())
      .then(data => {
        return data;
      })
      .then(data => setClientSecret(data["client_secret"]))
      .then(() => console.log("Got here"));
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret: urlClientSecret || clientSecret,
    appearance,
  };

  return (
    <div className="App">
      <div>This is Stripe Checkout</div>
      <div className="product">
        <img
          src="https://i.imgur.com/EHyR2nP.png"
          alt="The cover of Stubborn Attachments"
        />
        <div className="description">
          <h3>Stubborn Attachments</h3>
          <h5>$20.00</h5>
        </div>
      </div>
      {options.clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CustomCheckoutForm clientSecret={options.clientSecret} />
        </Elements>
      ) : (
        <button
          className="w-full my-4 py-2 px-12 rounded-lg shadow-lg bg-button-blue text-lg text-white font-bold"
          type="submit"
          onClick={handleSubmit}
        >
          Checkout
        </button>
      )}
    </div>
  );
}
