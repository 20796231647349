import React, { useState } from "react";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import { AddTaxRate } from "../../../../apollo/mutation/admins/tax_rates/AddTaxRate";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import GetAllActiveProducts from "../../../../apollo/query/admins/active_products/GetAllActiveProducts";
import GetShops from "../../../../apollo/query/admins/shops/GetShops";
import GetAllProducts from "../../../../apollo/query/admins/products/GetAllProducts";

const AddTaxRateForm = ({ setOpenAddTaxRate }) => {
  const [selectedTaxCategory, setSelectedTaxCategory] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedActiveProduct, setSelectedActiveProduct] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedInternalTaxType, setSelectedInternalTaxType] = useState("");
  const [selectedStripeTaxType, setSelectedStripeTaxType] = useState("");
  const [inclusive, setInclusive] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [description, setDescription] = useState("");
  const [displayName, setDisplayName] = useState("");

  const [adminAddTaxRate] = useMutation(AddTaxRate);

  const {
    data: orgData,
    error: orgError,
    loading: orgLoading,
  } = useQuery(GetAllOrganizations);

  const { data: shopData, error: shopError, loading: shopLoading } = useQuery(GetShops);

  const {
    data: productData,
    error: productError,
    loading: productLoading,
  } = useQuery(GetAllProducts);

  const {
    data: activeProductData,
    error: activeProductError,
    loading: activeProductLoading,
  } = useQuery(GetAllActiveProducts);

  const orgOptions =
    !orgLoading &&
    orgData.allOrganizations.map(organization => {
      return { value: organization.uid, label: organization.name };
    });

  const shopOptions =
    !shopLoading &&
    shopData.allShops.map(shop => {
      return {
        value: shop.uid,
        label: (
          <div className="flex">
            <img src={shop.imageUrl} className="w-10 h-10 rounded-full" />
            <div className="flex flex-col ml-5">
              <p className="font-bold">{shop.name}</p>
              <p className="italic">Org: {shop.organization.name}</p>
              <p className="italic">Show: {shop.show.name}</p>
            </div>
          </div>
        ),
      };
    });

  const activeProductsOptions =
    !activeProductLoading &&
    activeProductData.allActiveProducts.map(activeProduct => {
      console.log("active", activeProduct);
      return {
        value: activeProduct.uid,
        label: (
          <div className="flex">
            <img
              src={activeProduct.product.imageUrl}
              className="w-10 h-10 rounded-full"
            />
            <div className="flex flex-col ml-5">
              <p className="font-bold">{activeProduct.product.name}</p>
              <p className="italic">Org: {activeProduct.product.organization.name}</p>
              <p className="italic">Shop: {activeProduct.shop.name}</p>
            </div>
          </div>
        ),
      };
    });

  const productOptions =
    !productLoading &&
    productData.allProducts.map(product => {
      return {
        value: product.uid,
        label: (
          <div className="flex">
            <img src={product.imageUrl} className="w-10 h-10 rounded-full" />
            <div className="flex flex-col ml-5">
              <p className="font-bold">{product.name}</p>
              <p className="italic">{product.organization.name}</p>
            </div>
          </div>
        ),
      };
    });

  const taxFor = [
    { value: "organization", label: "Organization" },
    {
      value: "shop",
      label: "Shop",
    },
    {
      value: "product",
      label: "Product",
    },
    {
      value: "activeProduct",
      label: "Active Product",
    },
  ];

  const internalTaxTypes = [
    { value: "sales_tax", label: "Sales tax" },
    {
      value: "application_fee",
      label: "Application Fee",
    },
    {
      value: "processing_fee",
      label: "Processing Fee",
    },
  ];

  const stripeTaxTypes = [
    { value: "sales_tax", label: "Sales tax" },
    {
      value: "vat",
      label: "VAT",
    },
    {
      value: "gst",
      label: "GST",
    },
    {
      value: "hst",
      label: "HST",
    },
    {
      value: "pst",
      label: "PST",
    },
    {
      value: "rst",
      label: "RST",
    },
    {
      value: "qst",
      label: "QST",
    },
    {
      value: "jct",
      label: "JCT",
    },
  ];

  const handleAddTaxRate = () => {
    adminAddTaxRate({
      variables: {
        organizationUid: selectedOrganization.length > 0 ? selectedOrganization : null,
        shopUid: selectedShop.length > 0 ? selectedShop : null,
        productUid: selectedProduct.length > 0 ? selectedProduct : null,
        activeProductUid: selectedActiveProduct.length > 0 ? selectedActiveProduct : null,
        percentage: parseFloat(percentage),
        inclusive: inclusive,
        description: description,
        displayName: displayName,
        internalTaxType: selectedInternalTaxType,
        stripeTaxType: selectedStripeTaxType,
        enabled: enabled,
      },
    })
      .then(res => {
        setOpenAddTaxRate(false);
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            {/* Heading */}
            <p className="text-3xl font-bold ">Add Tax Rate</p>
            <div className="flex flex-col">
              <p className="font-bold text-xl mt-4">Tax For</p>
              <Select
                placeholder={"Select Tax For"}
                className="mt-3"
                options={taxFor}
                isSearchable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => {
                  setSelectedTaxCategory(selected.value);
                }}
              />
              {/* Org Select */}
              {selectedTaxCategory == "organization" && (
                <>
                  <p className="font-bold text-xl mt-4">Select Organization</p>
                  <Select
                    placeholder={"Select Organization"}
                    className="mt-3"
                    options={orgOptions}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedOrganization(selected.value);
                    }}
                  />
                </>
              )}
              {/* Product Select */}
              {selectedTaxCategory == "product" && (
                <>
                  <p className="font-bold text-xl mt-4">Select Product</p>
                  <Select
                    placeholder={"Select Product"}
                    className="mt-3"
                    options={productOptions}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedProduct(selected.value);
                    }}
                  />
                </>
              )}
              {/* Shop Select */}
              {selectedTaxCategory == "shop" && (
                <>
                  <p className="font-bold text-xl mt-4">Select Shop</p>
                  <Select
                    placeholder={"Select Shop"}
                    className="mt-3"
                    options={shopOptions}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedShop(selected.value);
                    }}
                  />
                </>
              )}
              {/* Active Product Select */}
              {selectedTaxCategory == "activeProduct" && (
                <>
                  <p className="font-bold text-xl mt-4">Select Active Product</p>
                  <Select
                    placeholder={"Select Active Product"}
                    className="mt-3"
                    options={activeProductsOptions}
                    isSearchable={true}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedActiveProduct(selected.value);
                    }}
                  />
                </>
              )}
              {/* Internal tax type */}
              <p className="font-bold text-xl mt-4">Select Internal Tax Type</p>
              <Select
                placeholder={"Select Internal Tax Type"}
                className="mt-3"
                options={internalTaxTypes}
                isSearchable={false}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => {
                  setSelectedInternalTaxType(selected.value);
                }}
              />
              {/* Stripe tax type */}
              {selectedInternalTaxType === "sales_tax" && (
                <>
                  {" "}
                  <p className="font-bold text-xl mt-4">Select Stripe Tax Type</p>
                  <Select
                    placeholder={"Select Stripe Tax Type"}
                    className="mt-3"
                    options={stripeTaxTypes}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedStripeTaxType(selected.value);
                    }}
                  />
                </>
              )}
              {/* Percentage */}
              <label
                htmlFor="percentage"
                className="flex mt-4 items-center justify-between w-5/12"
              >
                Percentage
                <input
                  type="number"
                  id="percentage"
                  className="ml-3"
                  value={percentage}
                  onChange={e => setPercentage(e.target.value)}
                />
              </label>
              {/* Display Nmae */}
              <label
                htmlFor="name"
                className="flex mt-4 items-center justify-between w-5/12"
              >
                Display Name
                <input
                  type="text"
                  id="name"
                  className="ml-3"
                  value={displayName}
                  onChange={e => setDisplayName(e.target.value)}
                />
              </label>
              {/* Description */}
              <label
                htmlFor="description"
                className="flex mt-4 items-center justify-between w-5/12"
              >
                Description
                <input
                  type="text"
                  id="description"
                  className="ml-3"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </label>
              {/* Enabled */}
              <label htmlFor="enabled" className="flex mt-4 items-center">
                Enabled
                <input
                  type="checkbox"
                  id="enabled"
                  className="ml-3"
                  value={enabled}
                  onClick={() => setEnabled(!enabled)}
                />
              </label>
              {/* Inclusive */}
              <label htmlFor="inclusive" className="flex mt-4 items-center">
                Inclusive
                <input
                  type="checkbox"
                  id="inclusive"
                  className="ml-3"
                  value={inclusive}
                  onClick={() => setInclusive(!inclusive)}
                />
              </label>
              <button
                className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                onClick={handleAddTaxRate}
              >
                Add Tax Rate
              </button>
            </div>
          </div>
          <button
            onClick={() => setOpenAddTaxRate(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddTaxRateForm;
