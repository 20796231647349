import React from "react";

const ListModal = ({ list, heading, setShowModal }) => {
  return (
    <>
      <div className="w-11/12 sm:w-8/12 md:w-7/12 lg:w-6/12 xl:w-4/12 h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full">
          {/* Heading */}
          <p className="text-3xl font-bold mt-3">{heading}</p>
          {/* List */}
          <div className="py-8 px-6 h-4/6 overflow-y-auto mt-4">
            {list.map((data, index) => (
              <p key={index}>{data.name}</p>
            ))}
          </div>
          {/* Close Button */}
          <button
            onClick={() => setShowModal(false)}
            className="bg-black h-12 text-white mt-auto mb-3"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ListModal;
