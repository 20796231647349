import React from "react";

import { RESERVATION_STATES } from "../../../constants/consts";

import Loading from "../../shared/Loading";

import InfoIcon from "../../../assets/info-icon.png";

import {
  ApproveStallReservation,
  CancelStallReservation,
  WaitlistStallReservation,
} from "../../../apollo/mutation/stallReservations";

import { useMutation } from "@apollo/client";

const SingleStatusChangeModal = ({
  setShowSingleModal,
  singleSelection,
  setSingleSelection,
  targetState,
  setTargetState,
  refetchReservations,
}) => {
  const [
    approveStallReservation,
    { loading: approveLoading, data: approveData, error: approveError },
  ] = useMutation(ApproveStallReservation);
  const [
    cancelStallReservation,
    { loading: cancelLoading, data: cancelData, error: cancelError },
  ] = useMutation(CancelStallReservation);
  const [
    waitlistStallReservation,
    { loading: waitlistLoading, data: waitlistData, error: waitlistError },
  ] = useMutation(WaitlistStallReservation);

  const handleCancel = () => {
    setShowSingleModal(false);
    setTargetState(null);
    setSingleSelection(null);
  };
  const handleSubmit = () => {
    if (targetState === RESERVATION_STATES.APPROVED.value) {
      approveStallReservation({
        variables: { stallReservationUid: singleSelection.uid },
      }).then(() => {
        handleCancel();
        refetchReservations();
      });
      return;
    }
    if (targetState === RESERVATION_STATES.CANCELLED.value) {
      cancelStallReservation({
        variables: { stallReservationUid: singleSelection.uid },
      }).then(() => {
        handleCancel();
        refetchReservations();
      });
      return;
    }
    if (targetState === RESERVATION_STATES.WAITLISTED.value) {
      waitlistStallReservation({
        variables: { stallReservationUid: singleSelection.uid },
      }).then(() => {
        handleCancel();
        refetchReservations();
      });
      return;
    }
    handleCancel();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6">
            <div className="relative flex-auto flex flex-col justify-center items-center">
              <div className="flex flex-row justify-start pb-6 w-full items-center">
                <img
                  className="h-6 w-6 object-scale-down"
                  src={InfoIcon}
                  alt="info icon"
                />
                <div className="text-lg font-bold pl-6">Accept changes?</div>
              </div>
              {approveLoading || cancelLoading || waitlistLoading ? (
                <Loading />
              ) : (
                <div className="text-sm pb-6">
                  Are you sure you want to change the reservation to{" "}
                  <b className="uppercase font-semibold">{targetState}</b>?
                </div>
              )}
              <div className="flex flex-row justify-between w-full">
                <button
                  className="bg-white rounded text-gray-800 min-w-50 px-3 py-1"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="bg-light-blue rounded text-white min-w-50 px-3 py-1"
                  onClick={handleSubmit}
                >
                  Sure, continue!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default SingleStatusChangeModal;
