import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const ShowsSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { show } = location.state;

  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Show Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p className="mt-2"> {show.name}</p>
        {/* Logo */}
        <p className="font-bold mt-3">Logo:</p>
        <img src={show.logoUrl} className="mt-2 w-36 h-28" />
        {/* Organization */}
        <p className="font-bold mt-3">Organization:</p>
        <p className="mt-2"> {show.organization.name}</p>
        {/* Venue */}
        <p className="font-bold mt-3">Venue:</p>
        <p className="mt-2"> {show.venue.name}</p>
        {/* Show Segments */}
        <div className="mt-3">
          <p className="font-bold">Show Segments:</p>
          <hr className="mt-1" />
          <div className="px-5">
            {show.showSegments.map(segment => (
              <div key={segment.uid}>
                <p className="text-sm font-bold mt-2">Segment Name:</p>
                <p className="mt-2 text-sm">{segment.name}</p>
                <p className="text-sm font-bold mt-2">Start Date:</p>
                <p className="mt-2 text-sm">{segment.startDate}</p>
                <p className="text-sm font-bold mt-2">End Date:</p>
                <p className="mt-2 text-sm">{segment.startDate}</p>
                <p className="text-sm font-bold mt-2">Created At</p>
                <p className="mt-2 text-sm">{segment.createdAt}</p>
                <p className="text-sm font-bold mt-2">Updated At:</p>
                <p className="mt-2 text-sm">{segment.updatedAt}</p>
                <hr className="mt-1" />
              </div>
            ))}
          </div>
        </div>
        {/* Created At/Update At */}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {show.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{show.updatedAt}</p>
      </div>
    </div>
  );
};

export default ShowsSummary;
