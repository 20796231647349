import { gql } from "@apollo/client";

export default gql`
  query getShopsByOrganization($organizationUid: String!) {
    organizationShops(organizationUid: $organizationUid) {
      uid
      name
      enabled
    }
  }
`;
