import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query GetAllShops {
    allShops {
      uid
      name
      allowSameDayDeliveries
      enabled
      startDate
      endDate
      imageUrl
      organization {
        uid
        name
      }
      show {
        uid
        name
        logoUrl
        showSegments {
          startDate
          endDate
        }
      }
    }
  }
`;
