import { gql } from "@apollo/client";

export const AddTaxRate = gql`
  mutation AdminAddTaxRate(
    $organizationUid: String
    $shopUid: String
    $activeProductUid: String
    $productUid: String
    $description: String!
    $percentage: Float!
    $displayName: String!
    $stripeTaxType: String!
    $internalTaxType: String!
    $enabled: Boolean!
    $inclusive: Boolean!
  ) {
    adminAddTaxRate(
      input: {
        organizationUid: $organizationUid
        shopUid: $shopUid
        activeProductUid: $activeProductUid
        productUid: $productUid
        description: $description
        percentage: $percentage
        displayName: $displayName
        stripeTaxType: $stripeTaxType
        internalTaxType: $internalTaxType
        enabled: $enabled
        inclusive: $inclusive
      }
    ) {
      taxRate {
        uid
      }
      errors
    }
  }
`;
