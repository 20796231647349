import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation ConfigureStripeAccount(
      $organizationUid: String!,
  ) {
    configureStripeAccount(input: {
      organizationUid: $organizationUid
    }) {
      redirectLink
      errors
    }
  }
`;

// Sample Input:
// {
//   "organizationUid": "backgate"
// }

// Sample Output:
// {
//   "data": {
//     "configureStripeAccount": {
//       "redirectLink": "https://connect.stripe.com/setup/e/acct_1KcFHnQ54CfhKhii/1juN4xxNDdVQ",
//       "errors": []
//     }
//   }
// }
