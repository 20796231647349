export const setLocalAuth = (token, expiry) => {
  localStorage.setItem("cachedExpiry", expiry);
  localStorage.setItem("cachedToken", token);
};

export const getLocalAuth = () => {
  return {
    token: localStorage.getItem("cachedToken"),
    expiry: new Date(localStorage.getItem("cachedExpiry")),
  };
};

export const clearLocalAuth = () => {
  localStorage.removeItem("cachedExpiry");
  localStorage.removeItem("cachedToken");
};
