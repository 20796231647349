export const PATHS = {
  LOGIN: "/login",
  REGISTER: "/register",
  TERMS: "/terms-and-conditions",
  PRIVACY: "/privacy",
  HOME: "/",
  DASHBOARD: "/dashboard",
  SHOWS: "/shows",
  RESERVATIONS: "/reservations",
  RESERVE: "/reserve",
  EXHIBITORS: "/exhibitors",
  AVATAR_TEST: "/avatar_test",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  TEST: "/app_test",
  TEST_STRIPE_CHECKOUT: "/app_test/stripe_checkout",
  TEST_STRIPE_CHECKOUT_FROM_ORDER: "/app_test/stripe_checkout_from_order",
  TEST_STRIPE_CUSTOM: "/app_test/stripe_custom",
  CHECKOUT: "/checkout",
  STOREFRONT: "/storefront/:shopUid",
  ACCOUNT: "/account",
  CONFIGURE_STRIPE_ACCOUNT: "/configure_stripe_account",
  ADMIN_CONSOLE: "/admin",
  ADMIN_USERS: "/admin/users",
  ADMIN_SHOPS: "/admin/shops",
  ADMIN_PRODUCTS: "/admin/products",
  ADMIN_ORGS: "/admin/organizations",
  ADMIN_ACTIVE_PRODUCTS: "/admin/activeproducts",
  ADMIN_SHOWS: "/admin/shows",
  ADMIN_VENUES: "/admin/venues",
  SUMMARY: "/summary",
  PRODUCT_SUMMARY: "/summary/product",
  SHOW_SUMMARY: "/summary/show",
  SHOP_SUMMARY: "/summary/shop",
  VENUE_SUMMARY: "/summary/venue",
  USER_SUMMARY: "/summary/user",
  TAX_SUMMARY: "/summary/tax",
  ADMIN_TAX_RATE: "/admin/tax",
  ADMIN_TAX_ORG: "/admin/tax/organization",
  ADMIN_TAX_PRODUCT: "admin/tax/product",
  ADMIN_TAX_SHOP: "admin/tax/shop",
  ADMIN_TAX_ACTIVE_PRODUCT: "admin/tax/active-product",
  ADMIN_SHOP_SUBSCRIPTION: "/admin/shop-subscription",
};

export const MODAL_MESSAGES = {
  REQUESTSUBMISSIONHEADING: "Request submitted!",
  REQUESTSUBMISSIONTEXT: "The reservation has been successfully submitted.",
  DELETERESERVATIONHEADING: "Request for cancellation?",
  DELETERESERVATIONTEXT: "This decision cannot be reversed.",
  CONFIRMCHANGESHEADING: "Changes submitted!",
  CONFIRMCHANGESTEXT: "The changes have been successfully submitted.",
  ERRORHEADING: "Something Went Wrong!",
  ERRORTEXT: "An error has occured. Please try again!",
  BUTTONRELOAD: "Re-load the page",
  EMAIL_NOTIFICATIONS: "Notifications will be sent to all emails below",
};

export const AUTH_SCREEN_MODES = {
  LOGIN: "login",
  REGISTER: "register",
  PASSWORD_RESET: "password_reset",
};

export const BREAD_CRUMBS = {
  "": {
    link: PATHS.HOME,
    text: "Home",
  },
  reservations: {
    link: PATHS.RESERVATIONS,
    text: "All Reservations",
  },
  reserve: {
    link: PATHS.RESERVE,
    text: "Reserve a Stall",
  },
  app_test: {
    link: PATHS.TEST,
    text: "Testing",
  },
  stripe_checkout: {
    link: PATHS.TEST_STRIPE_CHECKOUT,
    text: "Stripe Checkout",
  },
  stripe_checkout_from_order: {
    link: PATHS.TEST_STRIPE_CHECKOUT_FROM_ORDER,
    text: "Stripe Checkout from Order",
  },
  stripe_custom: {
    link: PATHS.TEST_STRIPE_CUSTOM,
    text: "Stripe Custom",
  },
};

export const VIEW_MODES = {
  UNAUTHORIZED: "unauthorized",
  TRAINER: "trainer",
  ORG_USER: "org_user",
  SUPER_ADMIN: "super_admin",
  MULTI_ORG: "multi_org_user",
  DELIVERY: "delivery",
  GUEST: "guest",
  ADMIN: "admin",
};

export const ORG_ROLES = {
  DASHBOARD: "dashboard",
  DELIVERY: "delivery",
  TEST_PAGES: "test_pages",
};

export const ORDER_DELIVERY_STATUSES = {
  NEW: "new",
  COMPLETED: "completed",
};

export const RESERVATION_STATES = {
  REQUESTED: {
    value: "requested",
    label: "Requested",
  },
  CHANGES_REQUESTED: {
    value: "changes_requested",
    label: "Changes Requested",
  },
  APPROVED: {
    value: "approved",
    label: "Approved",
  },
  CANCELLATION_REQUESTED: {
    value: "cancellation_requested",
    label: "Cancellation Requested",
  },
  CANCELLED: {
    value: "cancelled",
    label: "Cancelled",
  },
  WAITLISTED: {
    value: "waitlisted",
    label: "Waitlisted",
  },
};

// Properties for making Carousel responsive depending on various screen sizes
export const RESPONSIVE_CAROUSEL = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

export const DIRECT_UPLOAD_URL = "/rails/active_storage/direct_uploads";
