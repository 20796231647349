import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../shared/Loading";
import OrganizationCard from "./OrganizationCard";
import AddOrganizationForm from "./AddOrganizationForm";
import { AdminRemoveOrganization } from "../../../../apollo/mutation/admins/organizations/RemoveOrganization";

const AdminManageOrgs = () => {
  const [showForm, setShowForm] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const { loading, data, error, refetch } = useQuery(GetAllOrganizations);
  const [adminRemoveOrganization] = useMutation(AdminRemoveOrganization);

  function removeOrganization(uid) {
    adminRemoveOrganization({
      variables: {
        organizationUid: uid,
      },
    })
      .then(res => refetch())
      .catch(e => console.error(e));
  }

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allOrganizations
      : data?.allOrganizations.filter(organization =>
          organization.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Organizations</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setShowForm(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none pl-8 py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-25 items-center justify-start">Name</p>
        <p className="flex flex-basis-5 items-center justify-start">Shops</p>
        <p className="flex flex-basis-25 items-center justify-center">URL</p>
        <p className="flex flex-basis-5 items-center justify-start"></p>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Display all Organization */}
      {data &&
        !loading &&
        filteredData &&
        filteredData.length > 0 &&
        filteredData.map(organization => (
          <div key={organization.uid}>
            <OrganizationCard
              organization={organization}
              removeOrganization={removeOrganization}
              refetchOrganizationList={refetch}
            />
          </div>
        ))}
      {/* Add Organization */}
      {showForm ? (
        <AddOrganizationForm
          setShowForm={setShowForm}
          refetchOrganizationList={refetch}
        />
      ) : null}
    </div>
  );
};

export default AdminManageOrgs;
