/* ===================================== AUDIT LOG ========================================= *
 * Title: Confirm Delivery Modal
 * Author: Faizan Omer
 * Created on: 03/27/22
 * Description: Confirmation modal pops up if last line item of the order is delivered marking
 * the order as completed
 * ========================================================================================== */

import React from "react";

const ConfirmDeliveryModal = ({ handleConfirmDelivery, closeModal, userName }) => (
  <>
    <div className="w-10/12 sm:w-9/12 md:w-7/12 lg:w-5/12 h-60 md:h-64 lg:h-64 xl:h-64 px-4 bg-white shadow rounded-lg absolute bottom-8 top-2/4 left-2/4 transform translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <p className="font-bold text-lg">Confirm Delivery</p>
        {/* Order For */}
        <p className="mt-2">
          Order for <span className="font-bold">{userName}</span>
        </p>
        {/* Confirm Order Button */}
        <div className="mt-4">
          <button
            className="text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-lg lg:text-xl font-bold my-2 w-full bg-button-green"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleConfirmDelivery();
            }}
          >
            CONFIRM
          </button>
          {/* Close Modal */}
          <button
            className="rounded  h-10 md:h-14  px-6 py-1  md:text-lg lg:text-xl font-bold my-2 w-full "
            style={{ color: "#036D19", border: "1px solid #036D19" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              closeModal();
            }}
          >
            GO BACK
          </button>
        </div>
      </div>
    </div>

    <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
  </>
);

export default ConfirmDeliveryModal;
