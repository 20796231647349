import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();

  let state = {
    state: {
      product: product,
    },
  };

  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Image */}
        <li className="flex flex-basis-5 items-center justify-start">
          <img
            src={product.imageUrl}
            className="w-10 h-10 object-cover rounded-xl  bg-white shadow"
          />
        </li>
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{product.name}</p>
        </li>
        {/* Product Organization */}
        <li className="flex flex-basis-25 items-center justify-center">
          <p className="text-sm"> {product.organization.name}</p>
        </li>
        {/* Product Description */}
        <li className="flex flex-basis-25 items-center justify-center">
          <p className="text-sm italic text-gray-400">
            {product.shortDescription ? product.shortDescription : "no description"}
          </p>
        </li>
        <li className="flex flex-basis-10 items-center justify-center">
          <p className="text-sm italic font-semibold">
            Enabled:{" "}
            <span
              className={`text-sm not-italic font-semibold ${
                product.enabled ? "text-button-green" : "text-warning-red"
              }`}
            >
              {product.enabled.toString()}
            </span>
          </p>
        </li>
        <li className="flex flex-basis-5 items-center justify-center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            fixedWidth
            className="cursor-pointer text-blue-600"
            onClick={() => navigate(PATHS.PRODUCT_SUMMARY, state)}
          />
        </li>
      </ul>
    </div>
  );
};

export default ProductCard;
