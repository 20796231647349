import React from "react";

import firebase from "firebase/app";
import "firebase/auth";

import BreadCrumbRow from "./BreadCrumbRow";
import AccountDropdown from "./AccountDropdown";
import OrgSelect from "./OrgSelect";
import Logo from "../../../assets/backgate-logo-no-text-no-background.png";

import { viewingAsMultiOrgUser } from "../../../utils/user.utils";
import { useAuth } from "../../../contexts/AuthContext";
import { PATHS } from "../../../constants/consts";
import { useNavigate } from "react-router-dom";

const TopNav = ({
  onOrgSelect,
  hideBreadCrumbs,
  hideOrgSelect,
  hideLogo,
  className,
}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const logoOnClick = e => {
    e.preventDefault();
    navigate(PATHS.HOME);
  };

  return (
    <div className={`flex flex-col w-full justify-start px-12 pt-6 ${className || ''}`}>
      {viewingAsMultiOrgUser(currentUser) ? (
        <div className={[
          "flex flex-row items-center w-full",
          hideOrgSelect && hideLogo ? 'justify-end' : 'justify-between',
        ].join(' ')}>
          {!hideLogo && (
            <img
              className="h-12"
              src={Logo}
              alt={'backgate'}
              onClick={logoOnClick}
            />
          )}
          {!hideOrgSelect && <OrgSelect onOrgSelect={onOrgSelect} />}
          <AccountDropdown color="white" />
        </div>
      ) : (
        <div className="flex flex-row justify-between items-center w-full">
          <img
            className="h-12"
            src={Logo}
            alt={'backgate'}
            onClick={logoOnClick}
          />
          <AccountDropdown color="white" />
        </div>
      )}
      {!hideBreadCrumbs && <BreadCrumbRow />}
    </div>
  );
};

export default TopNav;
