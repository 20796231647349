import React from "react";

const removeSelectedStallType = (stallType, selectedStallTypes) => {
  const newSelectedStallTypes = (selectedStallTypes || []).filter(
    st => st.uid !== stallType.uid,
  );
  return newSelectedStallTypes;
};

// Prevent duplicates by removing any pre-existing entries
const addSelectedStallType = (stallType, selectedStallTypes) => [
  ...removeSelectedStallType(stallType, selectedStallTypes),
  stallType,
];

const isSelectedStallType = (stallType, selectedStallTypes) =>
  !((selectedStallTypes || []).findIndex(st => st.uid === stallType.uid) === -1);
const shouldBeActive = (stallType, selectedStallTypes) => {
  if (!selectedStallTypes || selectedStallTypes.length === 0) {
    return true;
  }
  return isSelectedStallType(stallType, selectedStallTypes);
};

const StallTypeSection = ({
  selectedShow,
  selectedStallTypes,
  setSelectedStallTypes,
}) => {
  const { venueUid } = selectedShow;

  return (
    <div className="bg-white rounded-lg px-6 pb-6 flex flex-col w-full mt-2">
      <div className="text-xl pb-3">Available Stall Types</div>
      <div className="flex flex-col md:flex-row w-full h-full space-y-3 md:space-y-0 md:space-x-3 overflow-auto items-center">
        {selectedShow.venue.stallTypes.map(stallType => {
          return (
            <div
              key={stallType.uid}
              className="w-3/4 md:w-auto flex flex-col items-center justify-center"
            >
              <div className="relative">
                <img
                  src={stallType.imageUrl}
                  alt={stallType.name}
                  className="h-auto sm:h-16 md:h-32 w-full object-scale-down"
                />
                <div className="absolute w-full h-10 bg-gradient-to-b from-black to-transparent opacity-75 self-start top-0 left-0" />
                <div className="absolute text-md font-light self-start px-2 top-1 left-1 text-white font-semibold text-lg z-10">
                  {stallType.name}
                </div>
              </div>
              <div
                className="w-full flex flex-row font-semibold justify-between px-2 bg-gray-200 py-2"
                style={{ fontSize: "0.5rem" }}
              >
                <div className="flex flex-col">
                  <div className="text-xs self-center">Weekly</div>
                  <div className="text-xs sm:text-sm md:text-base lg:text-base text-black">
                    ${stallType.weeklyPrice && stallType.weeklyPrice.toFixed(2)}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="text-xs self-center">Circuit</div>
                  <div className="text-xs sm:text-sm md:text-base lg:text-base text-black">
                    ${stallType.circuitPrice && stallType.circuitPrice.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StallTypeSection;
