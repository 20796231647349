import { gql } from "@apollo/client";

export default gql`
  query GetOrgStallReservations($organizationUid: String!) {
    orgStallReservations(organizationUid: $organizationUid) {
      uid
      stallCount
      state
      user {
        uid
        displayName
        firstName
        lastName
        email
        avatarUrl
      }
      stallType {
        uid
        name
        weeklyPrice
        circuitPrice
        seasonPrice
        cancellationFee
        imageUrl
      }
      showSegments {
        uid
        name
        startDate
        endDate
      }
      show {
        uid
        name
        logoUrl
        theme
        venueUid
        venue {
          stallTypes {
            uid
            name
          }
        }
        showDetail
        organization {
          name
        }
        showSegments {
          uid
          name
        }
      }
    }
  }
`;
