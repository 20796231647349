import { gql } from "@apollo/client";

export default gql`
  query TaxRatesByOrganizations {
    allOrganizationsTaxRates {
      uid
      displayName
      stripeTaxType
      internalTaxType
      percentage
      enabled
      createdAt
      updatedAt
      country
      description
      organization {
        uid
        name
      }
    }
  }
`;
