import { gql } from "@apollo/client";

export const ChangeShopStatus = gql`
  mutation ChangeShopStatus($shopUid: String!, $enabledStatus: Boolean!) {
    changeShopStatus(input: { shopUid: $shopUid, enabledStatus: $enabledStatus }) {
      shop {
        uid
        name
        enabled
      }
      errors
    }
  }
`;
