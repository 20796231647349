import { gql } from "@apollo/client";

export const AdminAddVenue = gql`
  mutation AdminAddVenue($organizationUid: String!, $name: String!) {
    adminAddVenue(input: { organizationUid: $organizationUid, name: $name }) {
      venue {
        uid
        name
      }
      errors
    }
  }
`;
