import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { AdminAddShow } from "../../../../apollo/mutation/admins/shows/AdminAddShow";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import GetAllVenues from "../../../../apollo/query/admins/venues/GetAllVenues";
import Select from "react-select";
import { DirectUpload } from "@rails/activestorage";
import { DIRECT_UPLOAD_URL } from "../../../../constants/consts";

const AddShowForm = ({ setShowForm, refetchShowList }) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedVenue, setSelectedVenue] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [showName, setShowName] = useState("");
  const [showDetail, setShowDetail] = useState("");
  const [blob, setBlob] = useState({});

  const isBlobPresent = Object.keys(blob).length > 0;

  const { loading, data, error } = useQuery(GetAllOrganizations);
  const [adminAddShow] = useMutation(AdminAddShow);

  const {
    loading: venueLoading,
    data: venueData,
    error: venueError,
  } = useQuery(GetAllVenues);

  const orgOptions =
    !loading && data
      ? data.allOrganizations.map(org => {
          return { value: org.uid, label: org.name };
        })
      : null;

  const venueOptions =
    !venueLoading && venueData
      ? venueData.allVenues.map(venue => {
          return { value: venue.uid, label: venue.name };
        })
      : null;

  function handleAddShow() {
    adminAddShow({
      variables: {
        name: showName,
        showDetail: showDetail,
        organizationUid: selectedOrganization,
        venueUid: selectedVenue,
        logo: isBlobPresent ? blob.signed_id : null,
        contactUid: selectedContact,
      },
    })
      .then(res => {
        setShowForm(false);
        refetchShowList();
      })
      .catch(e => console.error(e));
  }

  //   Upload image to cloud/storage
  function handleShowLogoUpload(file) {
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setBlob(blob);
      }
    });
  }

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            {/* Heading */}
            <p className="text-3xl font-bold ">Add Show</p>
            {/* Org select dropdown */}
            <div className="flex flex-col">
              <p className="font-bold text-xl mt-4">Select Organization</p>
              {!loading && data && (
                <Select
                  placeholder={"Select Organization"}
                  className="mt-3"
                  options={orgOptions && orgOptions}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  closeMenuOnScroll={() => true}
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={selected => setSelectedOrganization(selected.value)}
                />
              )}
              {selectedOrganization.length > 0 && !venueLoading && venueData && (
                <>
                  <p className="font-bold text-xl mt-4">Select Venue</p>
                  <Select
                    placeholder={"Select Venue"}
                    className="mt-3"
                    options={venueOptions && venueOptions}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    closeMenuOnScroll={() => true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => setSelectedVenue(selected.value)}
                  />
                </>
              )}
            </div>
            {/* Add name */}
            {selectedOrganization && selectedVenue && (
              <div className="flex flex-col mt-3">
                {" "}
                <label htmlFor="name" className="flex flex-col">
                  Name
                  <input
                    type="text"
                    id="name"
                    value={showName}
                    onChange={e => setShowName(e.target.value)}
                  />
                </label>
                <label htmlFor="details" className="flex flex-col mt-3">
                  Show Details
                  <input
                    type="text"
                    id="details"
                    value={showDetail}
                    onChange={e => setShowDetail(e.target.value)}
                  />
                </label>
                <input
                  type="file"
                  accept="images/*"
                  onChange={e => handleShowLogoUpload(e.target.files[0])}
                />
                {!loading && data && selectedOrganization && (
                  <>
                    <p className="font-bold text-xl mt-4">Select Contact</p>
                    <Select
                      placeholder={"Select Contact"}
                      className="mt-3"
                      options={data.allOrganizations.filter(o => o.uid === selectedOrganization)[0].contacts.map(contact => ({value: contact.uid, label: contact.name}))}
                      isSearchable={false}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      isClearable={false}
                      onChange={selected => {
                        setSelectedContact(selected.value);
                      }}
                    />
                  </>
                )}
                <button
                  className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                  disabled={showName.length < 1 && isBlobPresent}
                  onClick={handleAddShow}
                >
                  Add Show
                </button>
              </div>
            )}
          </div>
          <button
            onClick={() => setShowForm(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddShowForm;
