import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";

const ShopCard = ({ shop }) => {
  const navigate = useNavigate();

  let state = {
    state: {
      shop: shop,
    },
  };
  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{shop.name}</p>
        </li>
        <li className="flex flex-basis-25 items-center justify-center">
          <p className="text-sm">{shop.organization.name}</p>
        </li>
        <li className="flex flex-basis-5 items-center justify-center">
          <p
            className={`text-sm font-semibold ${
              shop.enabled ? "text-button-green" : "text-warning-red"
            }`}
          >
            {shop.enabled ? "true" : "false"}
          </p>
        </li>
        <li className="flex flex-basis-20 items-center justify-center">
          <p
            className={`text-sm font-semibold ${
              shop.allowSameDayDeliveries ? "text-button-green" : "text-warning-red"
            }`}
          >
            {shop.allowSameDayDeliveries ? "true" : "false"}
          </p>
        </li>
        <li className="flex flex-basis-5 items-center justify-center">
          <FontAwesomeIcon
            icon={faInfoCircle}
            fixedWidth
            className="cursor-pointer text-blue-600"
            onClick={() => navigate(PATHS.SHOP_SUMMARY, state)}
          />
        </li>
      </ul>
    </div>
  );
};

export default ShopCard;
