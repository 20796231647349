import { VIEW_MODES, ORG_ROLES } from "../constants/consts";

export const getInitials = ({ firstName, lastName, displayName }) => {
  if (firstName && lastName) {
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  }
  return displayName
    .split(" ")
    .map(s => s.charAt(0))
    .join("")
    .toUpperCase();
};

export const displayableName = ({ firstName, lastName, displayName }) => {
  if (displayName) {
    return displayName;
  }
  return `${firstName} ${lastName}`;
};

export const isOrgUser = ({ organizations }) => {
  return organizations && organizations.length > 0;
};

export const isMultiOrgUser = currentUser => {
  if (!currentUser) {
    return false;
  }

  const { organizationRoles } = currentUser;
  const uniqueOrganizationUids = Object.keys(
    organizationRoles
      .map(({ organizationUid }) => organizationUid)
      .reduce((agg, next) => Object.assign(agg, { [next]: true }), {}),
  );

  return uniqueOrganizationUids.length > 1;
};

export const isOrgDashboardUser = currentUser => {
  if (!currentUser) {
    return false;
  }

  const { organizationRoles } = currentUser;

  return organizationRoles.map(({ role }) => role).indexOf(ORG_ROLES.DASHBOARD) != -1;
};

export const isDeliveryUser = currentUser => {
  if (!currentUser) {
    return false;
  }

  const { organizationRoles } = currentUser;

  return organizationRoles.map(({ role }) => role).indexOf(ORG_ROLES.DELIVERY) != -1;
};

export const defaultViewMode = currentUser => {
  // Viewing mode has not been set and user is a member of multiple orgs (with no selections)
  if (isMultiOrgUser(currentUser) && !selectedOrganization) {
    return VIEW_MODES.MULTI_ORG;
  }

  if (isOrgDashboardUser(currentUser)) {
    return VIEW_MODES.ORG_USER;
  }

  if (isDeliveryUser(currentUser)) {
    return VIEW_MODES.DELIVERY;
  }

  return VIEW_MODES.TRAINER;
};

export const getViewMode = currentUser => {
  if (!currentUser) {
    return VIEW_MODES.GUEST;
  }
  const { viewMode, organizations, selectedOrganization } = currentUser;
  // Viewing mode has been set already
  if (viewMode && Object.values(VIEW_MODES).includes(viewMode)) {
    return viewMode;
  }
  // Viewing mode has not been set and user is a member of multiple orgs (with no selections)
  if (isMultiOrgUser(currentUser) && !selectedOrganization) {
    return VIEW_MODES.MULTI_ORG;
  }
  // Viewing mode has not been set and user is org user with a single org
  if (isOrgDashboardUser(currentUser)) {
    return VIEW_MODES.ORG_USER;
  }
  // Viewing mode has not been set and user is a member of multiple orgs and one has been selected
  if (isDeliveryUser(currentUser)) {
    return VIEW_MODES.DELIVERY;
  }

  return VIEW_MODES.TRAINER;
};

export const setViewMode = (currentUser, setCurrentUser, viewMode) => {
  return setCurrentUser({
    ...currentUser,
    viewMode,
  });
};

export const setSelectedOrganization = (
  currentUser,
  setCurrentUser,
  selectedOrganization,
) => {
  const newCurrentUser = {
    ...currentUser,
    selectedOrganization,
  };
  return setCurrentUser(newCurrentUser);
};

export const setViewModeAndSelectedOrganization = (
  currentUser,
  setCurrentUser,
  viewMode,
  selectedOrganization,
) => {
  return setCurrentUser({
    ...currentUser,
    viewMode,
    selectedOrganization,
  });
};

export const viewingAsMultiOrgUser = currentUser => {
  return getViewMode(currentUser) === VIEW_MODES.MULTI_ORG;
};

export const viewingAsOrgUser = currentUser => {
  return getViewMode(currentUser) === VIEW_MODES.ORG_USER;
};

export const viewingAsTrainer = currentUser => {
  return getViewMode(currentUser) === VIEW_MODES.TRAINER;
};

export const viewingAsGuest = currentUser => {
  return getViewMode(currentUser) === VIEW_MODES.GUEST;
};

export const viewingAsDeliveryUser = currentUser => {
  return getViewMode(currentUser) === VIEW_MODES.DELIVERY;
};

export const viewingAsAdmin = currentUser =>
  currentUser && currentUser.isAdmin ? true : false;

export const canViewMultiOrg = currentUser => {
  return isMultiOrgUser(currentUser);
};

export const canViewOrgDashboard = currentUser => {
  if (isMultiOrgUser(currentUser)) {
    if (!currentUser.selectedOrganization) {
      return false;
    }

    return (
      currentUser.organizationRoles
        ?.filter(({ role }) => role === ORG_ROLES.DASHBOARD)
        .map(({ organizationUid }) => organizationUid)
        .indexOf(currentUser.selectedOrganization.uid) != -1
    );
  }

  return isOrgDashboardUser(currentUser);
};

export const canViewDeliveries = currentUser => {
  if (isMultiOrgUser(currentUser)) {
    if (!currentUser.selectedOrganization) {
      return false;
    }
    return (
      currentUser.organizationRoles
        ?.filter(({ role }) => role === ORG_ROLES.DELIVERY)
        .map(({ organizationUid }) => organizationUid)
        .indexOf(currentUser.selectedOrganization.uid) != -1
    );
  }

  return isDeliveryUser(currentUser);
};
