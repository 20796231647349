/* ===================================== AUDIT LOG ========================================= *
 * Title: Error Modal
 * Author: Faizan Omer
 * Created on: 03/29/22
 * Description: A Simple Generic Modal used for notifying users an error occured
 * ========================================================================================== */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ErrorModal = ({ heading, text, buttonMessage, onClick }) => {
  return (
    <>
      <div className="flex flex-col justify-end sm:justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mb-20 sm:mb-0 md:w-5/12 my-6 mx-auto max-w-3xl h-52 sm:w-full">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pl-3 pr-6">
            <div className="relative p-6 flex-auto flex flex-col">
              <div className="flex flex-row mt-2 items-center justify-center">

                <p className="font-bold text-xl">{heading}</p>
              </div>
              <p className="mt-3">{text}</p>
              <div className="flex flex-row justify-end mt-12">
                <button
                  className="text-white text-s font-bold rounded bg-button-green p-2 h-10 w-full"
                  onClick={onClick}
                >
                  {buttonMessage}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ErrorModal;
