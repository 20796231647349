import React from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../../constants/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddTaxRateForm from "./AddTaxRateForm";

const AdminManageTaxRate = () => {
  const navigate = useNavigate();
  const [openAddTaxRate, setOpenAddTaxRate] = React.useState(false);
  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Tax Rates</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setOpenAddTaxRate(true);
          }}
        />
      </div>
      {/* Options */}
      <div className="flex flex-col">
        <button
          className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center pl-10 font-bold"
          onClick={() => navigate(PATHS.ADMIN_TAX_ORG)}
        >
          Organization
        </button>
        <button
          className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center pl-10 font-bold"
          onClick={() => navigate(PATHS.ADMIN_TAX_PRODUCT)}
        >
          Product
        </button>
        <button
          className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center pl-10 font-bold"
          onClick={() => navigate(PATHS.ADMIN_TAX_ACTIVE_PRODUCT)}
        >
          Active Product
        </button>
        <button
          className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center pl-10 font-bold"
          onClick={() => navigate(PATHS.ADMIN_TAX_SHOP)}
        >
          Shop
        </button>
      </div>
      {openAddTaxRate && <AddTaxRateForm setOpenAddTaxRate={setOpenAddTaxRate} />}
    </div>
  );
};

export default AdminManageTaxRate;
