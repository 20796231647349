import React, { useMemo } from "react";
import Select from "react-select";
import { Option, ValueContainer } from "../FilterDropdownComponents";

// This is  custom filter UI for selecting
// a unique option from a list
const MultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    let selectOptions = [];
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    selectOptions = [...options.values()];
    selectOptions = selectOptions.map(option => {
      return { value: option, label: option.charAt(0).toUpperCase() + option.slice(1) };
    });
    return selectOptions;
  }, [id, preFilteredRows]);

  // state for opening select menu
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuRef = React.useRef();

  React.useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, []);

  const scrollHandler = () => {
    setMenuOpen(false);
  };

  React.useEffect(() => {
    // Close dropdown if clicked on outside of element
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  // sets value to filter by
  const handleMultiSelect = selected => {
    const allValues = selected.map(select => select.value);
    setFilter(allValues && allValues.length ? allValues : undefined);
  };

  // components passed to select component to be fully customized
  const components = {
    Option,
    ValueContainer,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null,
    DropdownIndicator: () => null,
  };
  // custom CSS styles for react select component
  const customStyle = {
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      //   "0px 4px 8px rgba(58, 58, 68, 0.16), 0px 8px 16px rgba(90, 91, 106, 0.16);",
      width: "100%",
    }),
    menu: base => ({
      ...base,
      zIndex: 30,
      width: "fit-content",
      overflow: "auto",
    }),
    option: base => ({
      ...base,
      width: "fit-content",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  return (
    <div ref={menuRef}>
      <Select
        className="w-full"
        options={options}
        isMulti
        components={components}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={handleMultiSelect}
        styles={customStyle}
        isSearchable={false}
      />
    </div>
  );
};

export default MultiSelectColumnFilter;
