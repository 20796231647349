import React from "react";
import { Outlet } from "react-router-dom";

import { PATHS } from "../../../constants/consts";

import {
  InternalAdminHomeScreen,
  MultiOrganizationReservationsScreen,
} from "../../screens";

import { useAuth } from "../../../contexts/AuthContext";
import { viewingAsMultiOrgUser, getViewMode } from "../../../utils/user.utils";

import { DefaultLayout } from "../../layouts";

import dashboardIcon from "../../../assets/dashboard-icon.png";
import bookmarkIcon from "../../../assets/bookmark-icon.png";
import trashIcon from "../../../assets/trash-icon.png";

const MultiOrganizationRouting = ({ hideLogo, hideBreadCrumbs }) => {
  const { currentUser } = useAuth();
  let sections;

  currentUser.isAdmin
    ? (sections = [
        {
          path: PATHS.HOME,
          icon: dashboardIcon,
          name: "My Dashboard",
        },
        {
          path: PATHS.RESERVATIONS,
          icon: bookmarkIcon,
          name: "Make Reservation",
        },
        {
          path: PATHS.TEST_STRIPE_CHECKOUT,
          icon: trashIcon,
          name: "Stripe Checkout",
        },
        {
          path: PATHS.TEST_STRIPE_CHECKOUT_FROM_ORDER,
          icon: trashIcon,
          name: "Stripe Checkout from Order",
        },
        {
          path: PATHS.TEST_STRIPE_CUSTOM,
          icon: trashIcon,
          name: "Stripe Custom",
        },
        {
          path: PATHS.ADMIN_CONSOLE,
          icon: trashIcon,
          name: "Admin Console",
        },
      ])
    : (sections = [
        {
          path: PATHS.HOME,
          icon: dashboardIcon,
          name: "My Dashboard",
        },
        {
          path: PATHS.RESERVATIONS,
          icon: bookmarkIcon,
          name: "Make Reservation",
        },
        {
          path: PATHS.TEST_STRIPE_CHECKOUT,
          icon: trashIcon,
          name: "Stripe Checkout",
        },
        {
          path: PATHS.TEST_STRIPE_CHECKOUT_FROM_ORDER,
          icon: trashIcon,
          name: "Stripe Checkout from Order",
        },
        {
          path: PATHS.TEST_STRIPE_CUSTOM,
          icon: trashIcon,
          name: "Stripe Custom",
        },
      ]);

  return (
    <>
      {viewingAsMultiOrgUser(currentUser) ? (
        <DefaultLayout
          leftNavSections={sections}
          hideLogo={hideLogo}
          hideBreadCrumbs={true}
        >
          <Outlet />
        </DefaultLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default MultiOrganizationRouting;
