import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query GetOrganizationShops($organizationUid: String!) {
    organizationShops(organizationUid: $organizationUid) {
      uid
      name
      show {
        uid
        name
      }
      startDate
      endDate
      enabled
    }
  }
`;

// Example data
// {
//   "organizationUid": "langershows"
// }
