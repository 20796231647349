import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import GetAllUsers from "../../../../apollo/query/admins/users/GetAllUsers";
import { formatDate } from "../../../../utils/date.utils";
import ListModal from "../../../shared/ListModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import UserEditRoleForm from "./UserEditRoleForm";
import UserEditProfile from "./UserEditProfile";

const AdminManageUser = () => {
  const { loading, error, data, refetch } = useQuery(GetAllUsers);

  const [userOrgs, setUserOrgs] = useState([]);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [modalHeading, setModalHeading] = useState("");
  const [showEditRoleForm, setShowEditRoleForm] = useState(false);
  const [user, setUser] = useState({});
  const [userUid, setUserUid] = useState();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  // Update user data on refetch
  React.useEffect(() => {
    if (userUid) {
      let user = data.allUsers.filter(user => user.uid === userUid);
      setUser(user[0]);
    }
  }, [data]);

  function openEditForm(user, profileEdit) {
    setUser(user);
    if (profileEdit) {
      setShowEditProfile(true);
    } else {
      setShowEditRoleForm(true);
    }
  }

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allUsers
      : data?.allUsers.filter(user => user.name.toLowerCase().includes(lowercasedFilter));

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-20 items-center justify-start">Name</p>
        <p className="flex flex-basis-20 items-center justify-center">Email</p>
        <p className="flex flex-basis-10 items-center justify-center">Verified</p>
        <p className="flex flex-basis-10 items-center justify-center">Organizations</p>
        <p className="flex flex-basis-20 items-center justify-center">Join Date</p>
        <p className="flex flex-basis-10 justify-center ">Edit Role </p>
        <p className="flex flex-basis-10 justify-start">Edit Profile </p>
      </div>
      {/* Show user data if it exists */}
      {data &&
        filteredData &&
        filteredData.length > 0 &&
        filteredData.map(user => (
          <div
            key={user.uid}
            className="bg-white h-20 rounded-md mt-2 shadow-md flex flex-col justify-center"
          >
            <ul className="flex justify-between px-5">
              {/* Name */}
              <li className="flex flex-basis-20 items-center justify-start">
                <p className="text-sm">{user.name}</p>
              </li>
              {/* Email */}
              <li className="flex flex-basis-20 items-center justify-center">
                <p className="text-sm"> {user.email}</p>
              </li>
              {/* Verification status */}
              <li className="flex flex-basis-10 items-center justify-center">
                <p
                  className={`text-sm font-semibold ${
                    user.emailVerified ? "text-button-green" : "text-warning-red"
                  }`}
                >
                  {user.emailVerified ? "Yes" : "No"}
                </p>
              </li>
              {/* Organization */}
              <li className="flex flex-basis-10 items-center justify-center">
                <button
                  className="text-sm underline font-semibold"
                  style={{ color: "#4286f4" }}
                  disabled={user.organizations.length < 1}
                  onClick={() => {
                    setUserOrgs(user.organizations);
                    setModalHeading("User Organizations");
                    setShowOrgModal(true);
                  }}
                >
                  {user.organizations.length}
                </button>
              </li>
              {/* Join Date */}
              <li className="flex flex-basis-20 items-center justify-center">
                <p className="text-sm">{formatDate(user.createdAt)}</p>
              </li>
              {/* Edit user */}
              <li className="flex flex-basis-10 items-center justify-center">
                <FontAwesomeIcon
                  icon={faEdit}
                  fixedWidth
                  className="cursor-pointer"
                  onClick={() => openEditForm(user)}
                />
              </li>
              {/* Edit user profile*/}
              <li className="flex flex-basis-10 items-center justify-center">
                <FontAwesomeIcon
                  icon={faEdit}
                  fixedWidth
                  className="cursor-pointer"
                  onClick={() => openEditForm(user, true)}
                />
              </li>
            </ul>
          </div>
        ))}
      {/* List All Organizations a user is part of */}
      {showOrgModal && userOrgs && userOrgs.length > 0 ? (
        <ListModal
          list={userOrgs}
          heading={modalHeading}
          setShowModal={setShowOrgModal}
        />
      ) : null}
      {showEditRoleForm && user ? (
        <UserEditRoleForm
          user={user}
          setShowModal={setShowEditRoleForm}
          refetchUsersData={refetch}
          setUserUid={setUserUid}
          setSearchFilter={setSearchFilter}
        />
      ) : null}
      {showEditProfile && user ? (
        <UserEditProfile
          user={user}
          setShowModal={setShowEditProfile}
          refetchUsersData={refetch}
          setUserUid={setUserUid}
          setSearchFilter={setSearchFilter}
        />
      ) : null}
    </div>
  );
};

export default AdminManageUser;
