import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { trainerTableContext } from "../../../utils/trainerTableContext";
import Edit from "../../../apollo/mutation/stallReservations/Edit";
import { useMutation } from "@apollo/client";

const EditConfirmationModal = ({
  showModal,
  setShowModal,
  userType,
  setShowSuccessModal,
  category,
  cellObj,
}) => {
  const { stallCount, setStallCount, editRowNumber } = useContext(trainerTableContext);
  const initialStallCount =
    editRowNumber !== null && editRowNumber === cellObj.row.index
      ? cellObj.row.cells.filter(cell => cell.column.Header === "# OF STALLS")[0].value
      : 0; //Get current stall count
  const [
    editStallReservation,
    { loading: approveLoading, data: approveData, error: approveError },
  ] = useMutation(Edit); //Loading graphQl mutation to update trainer table
  const [note, setNote] = useState(""); //For storing state of customer note
  const [maxCountReached, setMaxCountReached] = useState(false);
  const [textCount, setTextCount] = useState(0);

  // function to update trainer table on edit
  const handleSaveTrainerEdit = () => {
    editStallReservation({
      variables: {
        stallReservationUid: cellObj.row.values.uid,
        stallCount: parseInt(stallCount ? stallCount : initialStallCount),
        note: note,
      },
    }).then(data => {
      setShowModal(false);
      setShowSuccessModal(true);
    });
  };

  // Confirm Changes UI
  const confirmChanges = () => {
    // Box showing changes made
    const statusBox = (heading, color) => {
      return (
        <div className={` w-auto md:w-5/12 bg-box-${color} rounded-lg p-2 mt-4`}>
          <div className="flex flex-row">
            <p className="font-bold pl-1 text-lg">
              {heading}:
              <span className="pl-2 font-normal">
                {heading === "Changes" && stallCount !== undefined
                  ? stallCount
                  : initialStallCount}{" "}
                Stalls
              </span>
            </p>
          </div>
        </div>
      );
    };
    // Function to update description text count
    const updateTextCount = text => {
      if (text.length < 110) {
        setMaxCountReached(false);
        setTextCount(text.length);
      } else {
        setMaxCountReached(true);
        setTextCount(text.length);
      }
    };
    // Return Modal Design
    return (
      <div className="relative w-auto sm:w-5/12 my-6 mx-auto 2xl:w-5/12">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-4">
          <div className="relative py-6 px-2 flex-auto flex flex-col justify-center">
            {/* Heading title */}
            <div className="flex flex-row">
              <FontAwesomeIcon
                icon={faInfoCircle}
                fixedWidth
                className="bg-white opacity-50 pt-1"
                size="lg"
              />
              <p className="font-bold pl-2">Change Status</p>
            </div>
            {/* Note to user */}
            <div>
              <p className="text-medium-gray text-xs mt-2">
                A confirmation email will be sent for all changes.
              </p>
            </div>
            {/* Current no. of stalls */}
            {statusBox("Current", "gray")}
            {statusBox("Changes", "green")}
            {/* Customer Note Text Box */}
            <label className="text-medium-gray text-xs pb-1 mb-2 mt-12">
              Customer Note
              <div>
                <textarea
                  rows={4}
                  cols={35}
                  className="focus:ring-1 focus:ring-blue-500 border-2 resize-none focus:text-black  py-2 pl-2 pr-4 w-full h-24"
                  value={note}
                  maxLength="110"
                  onChange={e => {
                    setNote(e.target.value);
                    updateTextCount(e.target.value);
                  }}
                />
                {/* Clear all icon */}
                <FontAwesomeIcon
                  icon={faTimes}
                  fixedWidth
                  className="absolute right-3 bottom-32 mb-2 z-10"
                  size="lg"
                  onClick={() => setNote("")}
                />
                {/* Counter for descrption text */}
                <div className="flex w-full justify-end">
                  <p
                    className={
                      !maxCountReached
                        ? "text-xs text-medium-gray"
                        : "text-xs text-warning-red"
                    }
                  >
                    {textCount}/110
                  </p>
                </div>
              </div>
            </label>
            {/* Save/Discard Changes */}
            <div className="flex flex-row w-full justify-between md:justify-end">
              {/* Save Button */}
              <button
                className="text-white text-xs font-bold rounded bg-button-blue shadow-md px-6 h-11 mr-3"
                onClick={handleSaveTrainerEdit}
              >
                Save Changes
              </button>
              {/* Discard Button */}
              <button
                className="text-warning-red text-xs font-bold rounded bg-white border-2 border-discard-red px-6 h-11"
                onClick={() => {
                  setShowModal(false);
                  setStallCount(initialStallCount);
                }}
              >
                Discard Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            {
              //  Case: confirm changes modal is called
              category === "confirmchanges" ? confirmChanges() : null
            }
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditConfirmationModal;
