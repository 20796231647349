import React, { useState, useEffect } from 'react';
import BetterModal from '../../shared/BetterModal/BetterModal';
import Loading from '../../shared/Loading';
import { useMutation } from "@apollo/client";
import { centsToDollarStr } from '../../../utils/money.utils.js';
import { OrgAdminsOrderPartialRefund } from "../../../apollo/mutation/orgAdmins/order/PartialRefund";

const RefundConfirmationModal = ({isOpen, setIsOpen, order, refundQuantities, refundReason, isFullRefund, setSidePanelOpen, refetch}) => {
  const [modalHeading, setModalHeading] = useState(`Refund Order #${order.uid}`);
  const [extendModalClassName, setExtendModalClassName] = useState('');
  const [displayButtons, setDisplayButtons] = useState(true);
  const refundVariables = {
    variables: {
      orderUid: order.uid,
      refundLineItems: Object.keys(refundQuantities).map(lineItemUid => {
        return {
          parentUid: lineItemUid,
          quantity: refundQuantities[lineItemUid],
        };
      }),
      reason: refundReason,
    }
  };

  const [orgAdminOrderPartialRefund, {
    loading: refundLoading,
    error: refundError,
    data: refundData,
  }] = useMutation(OrgAdminsOrderPartialRefund);

  if (!isOpen) {
    return '';
  }

  // const summary =

  // console.log('\n\n\n');
  // console.log('refundQuantities', refundQuantities);
  // order.lineItems.map(li => console.log(li));
  // console.log('\n\n\n');

  const originalSubtotal = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.subtotal*li.calculatedQuantity/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const originalApplicationFee = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.applicationFee*li.calculatedQuantity/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const originalSalesTax = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.salesTax*li.calculatedQuantity/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const originalProcessingFee = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.processingFee*li.calculatedQuantity/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const originalTotal = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.total*li.calculatedQuantity/li.quantity).reduce((partialSum, a) => partialSum + a, 0));

  const refundSubtotal = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.subtotal*(refundQuantities[li.uid] || 0)/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const refundApplicationFee = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.applicationFee*(refundQuantities[li.uid] || 0)/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const refundSalesTax = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.salesTax*(refundQuantities[li.uid] || 0)/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const refundProcessingFee = Math.round(order.lineItems.filter(li => !li.isRefund).map(li => li.processingFee*(refundQuantities[li.uid] || 0)/li.quantity).reduce((partialSum, a) => partialSum + a, 0));
  const refundTotal = refundSubtotal + refundApplicationFee + refundSalesTax + refundProcessingFee;

  return (
    <BetterModal
      heading={modalHeading}
      displayActiveButton={displayButtons}
      displayCancelButton={displayButtons}
      extendModalClassName={extendModalClassName}
      activeButtonMessage="Refund"
      activeOnClick={() => {
        if (refundLoading) {
          return;
        }
        console.log("RefundConfirmationModal.activeOnClick is getting called!")
        setModalHeading('Submitting Refund');
        setDisplayButtons(false);
        setExtendModalClassName('h-1/2 w-1/2');
        orgAdminOrderPartialRefund(refundVariables).then(() => {
          setIsOpen(false);
          setSidePanelOpen(false);
          refetch();
        });
      }}
      cancelButtonMessage="Close"
      cancelButtonOnClick={() => setIsOpen(false)}
      close={() => setIsOpen(false)}
    >
      {refundLoading ?
        (
          <div className="flex flex-row justify-center mt-24 mb-24">
            <Loading inline={true} color="gray" size="2xl" />
          </div>
        ) : (
          <div className="flex flex-col w-full mt-8 py-2">
            <div className="flex justify-between text-subdued-gray mb-2">
              <p className="flex flex-basis-40 items-center text-xs justify-start">ITEM</p>
              <p className="flex flex-basis-15 items-center text-xs justify-center">ORIGINAL</p>
              <p className="flex flex-basis-15 items-center text-xs justify-center">REFUND</p>
              <p className="flex flex-basis-15 items-center text-xs justify-center">FINAL</p>
            </div>
            {order.lineItems.filter(li => !li.isRefund).map(li => (
              <div key={li.uid} className="flex justify-between py-2">
                <p className="flex flex-basis-40 items-center text-sm justify-start font-semibold">{li.activeProduct.product.name}</p>
                <p className="flex flex-basis-15 items-center text-sm justify-center font-semibold">{li.calculatedQuantity}</p>
                <p className={`flex flex-basis-15 items-center text-sm justify-center font-semibold ${refundQuantities[li.uid] !== undefined ? "text-warning-red" : ''}`}>{refundQuantities[li.uid] ? `-${refundQuantities[li.uid]}` : 0}</p>
                <p className="flex flex-basis-15 items-center text-sm justify-center font-semibold">{li.calculatedQuantity - (refundQuantities[li.uid] || 0)}</p>
              </div>
            ))}
            <div className="flex justify-between border-t pt-2 mt-2">
              <p className="flex flex-basis-40 items-center text-xs justify-end">{"Subtotal"}</p>
              <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalSubtotal)}</p>
              <p className={`flex flex-basis-15 items-center text-xs justify-end ${refundSubtotal > 0 ? "text-warning-red" : ''}`}>{refundSubtotal > 0 ? `-${centsToDollarStr(refundSubtotal)}` : centsToDollarStr(0)}</p>
              <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalSubtotal - refundSubtotal)}</p>
            </div>
            {order.applicationFee > 0 && (
              <div className="flex justify-between text-subdued-gray">
                <p className="flex flex-basis-40 items-center text-xs justify-end">{"Application Fee"}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalApplicationFee)}</p>
                <p className={`flex flex-basis-15 items-center text-xs justify-end ${refundApplicationFee > 0 ? "text-warning-red" : ''}`}>{refundApplicationFee > 0 ? `-${centsToDollarStr(refundApplicationFee)}` : centsToDollarStr(0)}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalApplicationFee - refundApplicationFee)}</p>
              </div>
            )}
            {order.processingFee > 0 && (
              <div className="flex justify-between text-subdued-gray">
                <p className="flex flex-basis-40 items-center text-xs justify-end">{"Processing Fee"}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalProcessingFee)}</p>
                <p className={`flex flex-basis-15 items-center text-xs justify-end ${refundProcessingFee > 0 ? "text-warning-red" : ''}`}>{refundProcessingFee > 0 ? `-${centsToDollarStr(refundProcessingFee)}` : centsToDollarStr(0)}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalProcessingFee - refundProcessingFee)}</p>
              </div>
            )}
            {order.salesTax > 0 && (
              <div className="flex justify-between text-subdued-gray">
                <p className="flex flex-basis-40 items-center text-xs justify-end">{"Sales Tax"}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalSalesTax)}</p>
                <p className={`flex flex-basis-15 items-center text-xs justify-end ${refundSalesTax > 0 ? "text-warning-red" : ''}`}>{refundSalesTax > 0 ? `-${centsToDollarStr(refundSalesTax)}` : centsToDollarStr(0)}</p>
                <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalSalesTax - refundSalesTax)}</p>
              </div>
            )}
            <div className="flex justify-between font-semibold border-t pt-1 mt-1">
              <p className="flex flex-basis-40 items-center text-xs justify-end">{"Total"}</p>
              <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalTotal)}</p>
              <p className={`flex flex-basis-15 items-center text-xs justify-end ${refundTotal > 0 ? "text-warning-red" : ''}`}>{refundTotal > 0 ? `-${centsToDollarStr(refundTotal)}` : centsToDollarStr(0)}</p>
              <p className="flex flex-basis-15 items-center text-xs justify-end">{centsToDollarStr(originalTotal - refundTotal)}</p>
            </div>
            {refundReason && (
              <div className="flex flex-col border-t mt-2 pt-2 border-t pt-1 mt-1">
                <div className="font-bold text-xs">Refund Reason</div>
                <div className="px-2 text-xs italic">"{refundReason}"</div>
              </div>
            )}
        </div>

      )}
    </BetterModal>
  )
};

export default RefundConfirmationModal;
