import React from "react";
import { Outlet } from "react-router-dom";

import { TopNavLayout } from "../../layouts";

import { useAuth } from "../../../contexts/AuthContext";
import { viewingAsDeliveryUser } from "../../../utils/user.utils";

const DeliveryRouting = () => {
  const { currentUser } = useAuth();

  return (
    <>
      {
        viewingAsDeliveryUser(currentUser) ? (
          <TopNavLayout onOrgSelect={() => refetch()} hideBreadCrumbs={true}>
            <Outlet />
          </TopNavLayout>
        ) : ''
      }
    </>
  );
};

export default DeliveryRouting;
