import { gql } from "@apollo/client";

export default gql`
  query GetNotificationSubscriptions {
    allShopNotifications {
      uid
      shop {
        uid
        name
      }
      user {
        uid
        name
      }
      notificationType
    }
  }
`;
