import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

const TaxRateSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { taxRate } = location.state;

  return (
    <div className="flex flex-col px-14 w-screen my-2">
      <div className="flex ml-6 items-center ">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            navigate(-1);
          }}
        />
        <p className="text-3xl font-bold ml-3">Tax Summary</p>
      </div>
      <div className="flex flex-col justify-center px-20 w-full h-full overflow-y-auto">
        <p className="font-bold mt-2">Name:</p>
        <p>{taxRate.displayName}</p>

        {/* Organization */}
        {taxRate.organization && (
          <>
            <p className="font-bold mt-3">Organization:</p>
            <p className="mt-2"> {taxRate.organization.name}</p>
          </>
        )}
        {/* Active Product */}
        {taxRate.activeProduct && (
          <>
            <p className="font-bold mt-3">Active Product:</p>
            <p className="mt-2"> {taxRate.activeProduct.product.name}</p>
          </>
        )}
        {/* Product */}
        {taxRate.product && (
          <>
            <p className="font-bold mt-3">Product:</p>
            <p className="mt-2"> {taxRate.product.name}</p>
          </>
        )}
        {/* Shop */}
        {taxRate.shop && (
          <>
            <p className="font-bold mt-3">Shop:</p>
            <p className="mt-2"> {taxRate.shop.name}</p>
          </>
        )}

        <p className="font-bold mt-3">Country:</p>
        <p className="mt-2"> {taxRate.country}</p>

        <p className="font-bold mt-3">Percentage:</p>
        <p className="mt-2"> {taxRate.percentage}</p>

        <p className="font-bold mt-3">Internal Tax Type:</p>
        <p className="mt-2"> {taxRate.internalTaxType}</p>

        <p className="font-bold mt-3">Stripe Tax Type:</p>
        <p className="mt-2"> {taxRate.stripeTaxType}</p>

        <p className="font-bold mt-3">Tax Enabled Status:</p>
        <p className="mt-2"> {taxRate.enabled.toString()}</p>

        {taxRate.description && (
          <>
            <p className="font-bold mt-3">Description:</p>
            <p className="mt-2"> {taxRate.description}</p>
          </>
        )}

        {/* Created At/Update At */}
        <p className="font-bold mt-3">Created At:</p>
        <p className="mt-2"> {taxRate.createdAt}</p>
        <p className="font-bold mt-3">Updated At:</p>
        <p className="mt-2">{taxRate.updatedAt}</p>
      </div>
    </div>
  );
};

export default TaxRateSummary;
