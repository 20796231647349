import React, { useState } from "react";

import { AUTH_SCREEN_MODES, PATHS } from "../../../constants/consts";
var classNames = require("classnames");
import logo from "../../../assets/logo-white-notext.png";

import { Link } from "react-router-dom";

import LoginForm from "./LoginForm";
import RegistrationForm from "./RegistrationForm";
import SsoLinks from "./SsoLinks";

import { useMutation } from "@apollo/client";
import FindOrCreateUser from "../../../apollo/mutation/FindOrCreateUser";

const isLogin = mode => mode === AUTH_SCREEN_MODES.LOGIN;

const AuthScreen = ({ mode }) => {
  const [findOrCreateUser, { loading, error, data }] = useMutation(FindOrCreateUser);
  const [authError, setAuthError] = useState(null);

  return (
    <>
      <div className="flex flex-col md:flex-row-reverse h-screen">
        <div
          className={classNames(
            "w-full flex nil:flex-row min-h-32  text-white px-8 py-3 bg-sign-in-blue items-center justify-start",
            "sm:px-16 ",
            "md:flex-col md:h-full md:w-1/2  md:py-16 md:justify-center",
          )}
        >
          <img className="w-32 h-1/12 sm:h-32 min-w-32" src={logo} alt="Logo" />
          <h3 className="pt-4 pl-4 font-medium text-2xl md:text-5xl leading-tight">
            Welcome to BackGate
          </h3>
        </div>
        <div className="flex flex-grow flex-col h-full w-full md:w-1/2 px-6 sm:pl-20 bg-light-gray pt-10">
          <div className="mt-3 md:mt-0 md:h-1/6" />
          <h3 className="text-2xl text-black font-bold">
            {isLogin(mode) ? "Sign in" : "Register"}
          </h3>
          {isLogin(mode) ? (
            ""
          ) : (
            <p className="text-xs pt-2 font-normal">Let's get you all set up!</p>
          )}
          {isLogin(mode) ? (
            <LoginForm
              authError={error || authError}
              setAuthError={setAuthError}
              loading={loading}
              findOrCreateUser={findOrCreateUser}
            />
          ) : (
            <RegistrationForm
              authError={error || authError}
              setAuthError={setAuthError}
              loading={loading}
              findOrCreateUser={findOrCreateUser}
            />
          )}
          <SsoLinks
            mode={mode}
            findOrCreateUser={findOrCreateUser}
            loading={loading}
            setAuthError={setAuthError}
          />
          {isLogin(mode) ? (
            <>
              <p className="text-xs pt-6 mb-24">
                Don't have an account?{" "}
                <Link to={PATHS.REGISTER} className="font-semibold text-light-blue">
                  Register Now!
                </Link>
              </p>
            </>
          ) : (
            <p className="text-xs pt-6 mb-24">
              Already have an account?{" "}
              <Link to={PATHS.LOGIN} className="font-semibold text-light-blue">
                Sign in
              </Link>
            </p>
          )}

          <p className="text-xs pt-6 mb-24">
            Forgot Password?{" "}
            <Link to={PATHS.FORGOT_PASSWORD} className="font-semibold text-light-blue">
              Click Here!
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default AuthScreen;
