import React, { useContext } from "react";
import { useState, useRef, useEffect } from "react";
import EditConfirmationModal from "../Table/EditConfirmationModal";
import { trainerTableContext } from "../../../utils/trainerTableContext.js";
import { orgTableContext } from "../../../utils/orgTableContext.js";
import Approve from "../../../apollo/mutation/stallReservations/Approve.js";
import Waitlist from "../../../apollo/mutation/stallReservations/Waitlist.js";
import Cancel from "../../../apollo/mutation/stallReservations/Cancel";
import RequestCancellation from "../../../apollo/mutation/stallReservations/RequestCancellation";
import { useMutation } from "@apollo/client";
import Select from "react-select";
import { Control } from "../CustomEditDropdown.js";
import { MODAL_MESSAGES } from "../../../constants/consts";
import NotifyModal from "../NotifyModal";

const EllipsisDropdown = ({ cellObj, type, setOpenConfirmModal }) => {
  const { editRowNumber, setEditRowNumber, week } =
    type === "organization"
      ? useContext(orgTableContext)
      : useContext(trainerTableContext); // if called from organization view use Org context else use Trainer context
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const menuRef = useRef();
  const [approveStallReservation] = useMutation(Approve);
  const [waitlistStallReservation] = useMutation(Waitlist);
  const [requestCancellationStallReservation] = useMutation(RequestCancellation); // Getting Cancel Request mutation for trainer
  const [cancelStallReservation] = useMutation(Cancel); //Getting Cancel Reservation mutation for Org

  let options = null;

  if (type === "organization") {
    options = [
      {
        value: "edit",
        label: "Edit",
      },
      {
        value: "approve",
        label: "Approve",
      },
      {
        value: "waitlist",
        label: "Waitlist",
      },
      {
        value: "delete",
        label: "Delete",
      },
    ];
  } else {
    options = [
      {
        value: "edit",
        label: "Edit",
      },
      {
        value: "delete",
        label: "Delete",
      },
    ];
  }

  useEffect(() => {
    if (editRowNumber !== null && editRowNumber === cellObj.row.index) {
      setShowSave(true);
    } else {
      setShowSave(false);
    }
  }, [editRowNumber]);

  // Approving a row
  const handleApprove = () => {
    approveStallReservation({
      variables: { stallReservationUid: cellObj.row.values.uid },
    }).then(setShowSuccessModal(true));
  };

  // Waitlist function
  const handleWaitlist = () => {
    waitlistStallReservation({
      variables: { stallReservationUid: cellObj.row.values.uid },
    }).then(setShowSuccessModal(true));
  };

  //Function to handle selection of edit menu
  const handleSelect = selected => {
    if (selected.value == "edit") {
      handleEdit();
    } else if (selected.value == "approve") {
      handleApprove();
    } else if (selected.value == "waitlist") {
      handleWaitlist();
    } else {
      handleDelete();
    }
  };

  // When user presses on confirm cancellation
  const confirmDelete = () => {
    if (cellObj.row.values.uid) {
      // Case: user is trainer
      if (type === "trainer") {
        requestCancellationStallReservation({
          variables: { stallReservationUid: cellObj.row.values.uid },
        }).then(() => window.location.reload());
      }
      // Case: user ir Organization
      else {
        cancelStallReservation({
          variables: { stallReservationUid: cellObj.row.values.uid },
        }).then(() => window.location.reload());
      }
    }
  };

  //   Function to handle when edit row is pressed
  const handleEdit = () => {
    setEditRowNumber(cellObj.row.index);
  };

  // When user presses delete button
  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleCancel = () => {
    setEditRowNumber(null);
  };

  //custom components for dropdown
  const components = {
    Control,
    SingleValue: () => null,
    IndicatorSeparator: () => null,
    LoadingIndicator: () => null,
    DropdownIndicator: () => null,
  };

  const customStyle = {
    container: (base, state) => ({
      ...base,
      zIndex: state.selectProps.menuIsOpen ? 30 : 0,
    }),
    control: base => ({
      ...base,
      border: 0,
      backgroundColor: "inherit",
      boxShadow: 0,
    }),
    valueContainer: base => ({
      ...base,
      border: 0,
      borderColor: "#FFFF",
    }),
    menu: base => ({
      ...base,
      width: "auto",
      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2);",
      margin: 0,
    }),
    menuList: base => ({
      ...base,
      textAlign: "center",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#FFFF",
      "&:hover": {
        backgroundColor: "#FFFF",
        cursor: "pointer",
      },
      borderBottom: state.data.value === "delete" ? 0 : "1px solid rgb(237, 237, 240)",
      color: state.data.value === "delete" ? "#FB3131" : "inherit",
    }),
  };

  return (
    <div className="w-full">
      <div>
        {/* Icon opens Edit/Delete dropdown */}
        {showSave ? null : (
          <div ref={menuRef}>
            <Select
              options={options}
              components={components}
              closeMenuOnSelect={true}
              hideSelectedOptions={true}
              placeholder={null}
              isClearable={false}
              menuPortalTarget={cellObj.editMenuRef.target}
              menuPosition={"fixed"}
              closeMenuOnScroll={() => true}
              isSearchable={false}
              onChange={handleSelect}
              menuShouldBlockScroll={false}
              styles={customStyle}
            />
          </div>
        )}

        {/* Showing Save/Cancel buttons */}
        {!showSave ? null : (
          <div className="mr-3 flex">
            {/* Save Button */}
            <button
              className={`text-white text-xs font-bold rounded p-2 w-6/12 h-8 mr-3 ${
                type == "organization" && !week
                  ? "filter grayscale bg-medium-gray"
                  : "bg-button-blue shadow-md"
              }`}
              onClick={() => {
                if (type === "trainer") {
                  setShowModal(true);
                } else {
                  setOpenConfirmModal(true);
                }
              }}
              disabled={type == "organization" && !week}
            >
              Save
            </button>
            {/* Cancel Button */}
            <button
              className="text-xs font-bold rounded bg-white border p-2 w-7/12 h-8"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        )}
        {/* Confirm Changes Modal */}
        {!showModal ? null : (
          <EditConfirmationModal
            cellObj={cellObj}
            showModal={showModal}
            setShowModal={setShowModal}
            setShowSuccessModal={setShowSuccessModal}
            category="confirmchanges"
            userType={type}
          />
        )}
        {/* Confirm Delete Modal */}
        {showSuccessModal || showDeleteModal ? (
          <NotifyModal
            setShowModal={showSuccessModal ? setShowSuccessModal : setShowDeleteModal}
            action={showSuccessModal ? "confirmchanges" : "deleteconfirmation"}
            text={
              showSuccessModal
                ? MODAL_MESSAGES.CONFIRMCHANGESTEXT
                : MODAL_MESSAGES.DELETERESERVATIONTEXT
            }
            heading={
              showSuccessModal
                ? MODAL_MESSAGES.CONFIRMCHANGESHEADING
                : MODAL_MESSAGES.DELETERESERVATIONHEADING
            }
            confirmDelete={confirmDelete}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EllipsisDropdown;
