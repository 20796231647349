import React from "react";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";

import { PATHS } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";
import firebase from "firebase/app";
import "firebase/auth";

const validateForm = values => {
  const errors = {};

  return errors;
};

const handleFormErrors = (errors, touched) => {
  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <>
      {Object.keys(errors).map(key => {
        if (touched.hasOwnProperty(key)) {
          return (
            <div key={key} className="text-red-400 font-semibold text-sm">
              {errors[key]}
            </div>
          );
        }
      })}
    </>
  );
};

const LoginForm = ({ authError, setAuthError, loading, findOrCreateUser }) => {
  const { setCurrentUser } = useAuth();
  const { login } = useAuth();
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col items-start mt-6">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validate={validateForm}
        onSubmit={(values, actions) => {
          return login(values.email, values.password)
            .then(({ user, additionalUserInfo }) => {
              setAuthError(null);
              return findOrCreateUser({
                variables: {
                  ...values,
                  providerId: additionalUserInfo.providerId,
                  firebaseId: user.uid,
                },
              });
            })
            .then(({ data }) => {
              setCurrentUser(data.findOrCreateUser.user);
            })
            .then(args => {
              navigate(PATHS.HOME);
            })
            .catch(e => setAuthError(e));
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit} className="w-full">
            <div className="flex flex-col items-start">
              {handleFormErrors(props.errors, props.touched)}
              {authError && (
                <div key={authError.code} className="text-red-400 font-semibold text-sm">
                  {authError.message}
                </div>
              )}
              <div className="flex flex-col w-full sm:w-3/5">
                <div className="flex flex-col mr-2 my-0.5 w-full">
                  <Field
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="email"
                    placeholder="Email"
                    className="rounded border-gray-400 shadow-sm text-xs"
                  />
                </div>
                <div className="flex flex-col mr-2 my-0.5 w-full">
                  <Field
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    name="password"
                    placeholder="Password"
                    className="rounded border-gray-400 shadow-sm text-xs"
                  />
                </div>
                <div className="flex flex-row items-center w-full my-4">
                  <Field
                    className={`rounded-sm ${
                      props.values.remember ? "bg-light-blue" : "bg-light-gray"
                    }`}
                    type="checkbox"
                    name="remember"
                  />
                  <div className="text-xs ml-2">Remember me</div>
                </div>
                <button
                  type="submit"
                  className="text-white rounded-sm shadow px-6 py-1 text-s my-4 w-full bg-light-blue"
                  onClick={() => setAuthError(null)}
                  disabled={loading || Object.keys(props.errors) > 0}
                >
                  Sign in
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
