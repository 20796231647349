import React from "react";

const ProductSelector = ({ product, setActiveProducts, activeProducts }) => {
  const [price, setPrice] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (price && isChecked) {
      const exists = activeProducts.find(
        product => product.productUid === selectedProduct,
      );
      if (exists) {
        setActiveProducts(
          activeProducts.map(product =>
            product.productUid === selectedProduct
              ? {
                  productUid: selectedProduct,
                  price: parseInt(price),
                  sortOrder: parseInt(sortOrder),
                }
              : product,
          ),
        );
      } else {
        setActiveProducts([
          ...activeProducts,
          {
            productUid: selectedProduct,
            price: parseInt(price),
            sortOrder: parseInt(sortOrder),
          },
        ]);
      }
    }
    if (!isChecked) {
      let indexToRemove;
      let updatedArray = activeProducts.map((product, index) => {
        if (product.productUid === selectedProduct) {
          indexToRemove = index;
        }
        return product;
      });
      updatedArray.splice(indexToRemove, 1);
      setActiveProducts(updatedArray);
    }
  }, [price, isChecked]);

  const handleCheck = e => {
    setIsChecked(e.target.checked);
    setSelectedProduct(e.target.value);
  };
  return (
    <div className="flex w-full items-center">
      {/* Checkbox */}
      <input
        type="checkbox"
        id={product.uid}
        name={product.name}
        value={product.uid}
        className="mr-5"
        onChange={handleCheck}
      />
      <img src={product.imageUrl} className="w-10 h-10 rounded-full" />
      <label htmlFor={product.uid} className="flex-basis-30 ml-3">
        {product.name}
      </label>
      {/* Sort Order */}
      <label
        className={
          isChecked && !sortOrder ? "flex-basis-20 text-warning-red" : "flex-basis-40"
        }
      >
        Order:
        <input
          type="number"
          className="ml-5 border-0 border-b w-3/6"
          value={sortOrder}
          onChange={e => setSortOrder(e.target.value)}
        />
      </label>
      {/* Price */}
      <label
        className={
          isChecked && !price ? "flex-basis-40 text-warning-red" : "flex-basis-40"
        }
      >
        Enter Price &#40; in Cents &#41;:
        <input
          type="number"
          className="ml-5 border-0 border-b"
          min={51}
          value={price}
          onChange={e => setPrice(e.target.value)}
        />
      </label>
    </div>
  );
};

export default ProductSelector;
