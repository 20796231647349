/* ===================================== AUDIT LOG ========================================= *
 * Title: My Show Card
 * Author: Faizan Omer
 * Created on: 04/4/22
 * Description: Component used to display shows information in a card
 * ========================================================================================== */

import React from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants/consts";

const MyShowCard = ({ show, image, shopUid, date }) => {
  const navigate = useNavigate();

  // Background image styles
  const backgroundImage = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    opacity: 0.9,
  };

  return (
    <div
      className="h-36 w-full md:w-72 md:h-48 rounded rounded-md p-3 relative cursor-pointer"
      style={backgroundImage}
      onClick={() => navigate(PATHS.STOREFRONT.replace(":shopUid", shopUid))}
    >
      {/* Show Details   */}
      <div className="flex flex-col w-7/12 justify-content items-start">
        <p className="text-white text-lg font-bold">{show.name}</p>
        <p className="text-white text-sm">{date}</p>
        <p className="text-white text-sm">{show.venue.name}</p>
      </div>
    </div>
  );
};

export default MyShowCard;
