import {gql} from '@apollo/client'
// FIXME: This should be a .gql file
export default gql`
  mutation BulkApproveStallReservation(
      $stallReservationUids: [String!]!,
      $note: String
  ) {
    bulkApproveStallReservation(input: {
      stallReservationUids: $stallReservationUids,
      note: $note
    }) {
      stallReservations {
        uid
        note
        state
      }
      errors
    }
  }
`
