const calculateReservationPrice = (stallCount, stallType, showSegmentUids, show) => {
  const { circuitPrice, weeklyPrice } = stallType;
  const { showSegments } = show;
  const rate =
    showSegmentUids.length === showSegments.length
      ? circuitPrice
      : weeklyPrice * showSegmentUids.length;
  return stallCount * rate;
};

export default calculateReservationPrice;
