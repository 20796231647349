import { gql } from "@apollo/client";

export default gql`
  query GetAllOrganizations {
    allOrganizations {
      uid
      name
      url
      createdAt
      updatedAt
      contacts {
        uid
        name
        email
        phone
      }
      deliveryTimes {
        uid
        name
      }
    }
  }
`;
