import { gql } from "@apollo/client";

export const AdminAddShowSegment = gql`
  mutation AdminAddShowSegment(
    $name: String!
    $showSegmentStart: ISO8601Date!
    $showSegmentEnd: ISO8601Date!
    $showUid: String!
  ) {
    adminAddShowSegment(
      input: {
        name: $name
        showSegmentStart: $showSegmentStart
        showSegmentEnd: $showSegmentEnd
        showUid: $showUid
      }
    ) {
      showSegment {
        uid
      }
      errors
    }
  }
`;
