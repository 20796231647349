import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivacyPolicyScreen, TermsAndConditionsScreen } from "../../screens";
import PrivateRoute from "./PrivateRoute";
import TrainerRouting from "./TrainerRouting";
import DeliveryRouting from "./DeliveryRouting";
import OrganizationRouting from "./OrganizationRouting";
import MultiOrganizationRouting from "./MultiOrganizationRouting";
import { TopNavLayout } from "../../layouts";
import AuthRouting from "./AuthRouting";
import ForgotPasswordForm from "../../screens/AuthScreen/ForgotPasswordForm";
import { AUTH_SCREEN_MODES, PATHS } from "../../../constants/consts";
import {
  AccountScreen,
  DelivererHomeScreen,
  InternalAdminHomeScreen,
  MultiOrganizationReservationsScreen,
  OrganizationHomeScreen,
  OrganizationReservationsScreen,
  OrganizationOrdersScreen,
  TrainerHomeScreen,
  TrainerReservationsScreen,
  TestStripeCheckout,
  TestStripeCheckoutFromOrder,
  TestStripeCustom,
  ConfigureStripeAccountRedirect,
} from "../../screens";

import {
  viewingAsMultiOrgUser,
  viewingAsOrgUser,
  viewingAsTrainer,
  viewingAsDeliveryUser,
  viewingAsGuest,
  viewingAsAdmin,
} from "../../../utils/user.utils";
import StoreFront from "../../screens/StoreFront";
import TrainerCheckout from "../../screens/TrainerCheckout";
import { useAuth } from "../../../contexts/AuthContext";
import StoreSelectionScreen from "../../screens/TrainerHomeScreen/StoreSelectionScreen";
import PasswordReset from "../../screens/PasswordReset";
import Loader from "./Loader";

import AdminNavigation from "../../screens/AdminConsole/AdminNavigation";
import AdminManageOrgs from "../../screens/AdminConsole/Organizations/AdminManageOrgs";
import AdminManageUser from "../../screens/AdminConsole/Users/AdminManageUser";
import AdminManageProducts from "../../screens/AdminConsole/Products/AdminManageProducts";
import AdminManageShops from "../../screens/AdminConsole/Shops/AdminManageShops";
import AdminManageActiveProducts from "../../screens/AdminConsole/ActiveProducts/AdminManageActiveProducts";
import AdminManageShows from "../../screens/AdminConsole/Shows/AdminManageShows";
import AdminManageVenues from "../../screens/AdminConsole/Venues/AdminManageVenues";
import AdminManageTaxRate from "../../screens/AdminConsole/TaxRate/AdminManageTaxRate";
import AdminManageShopSubscriptions from "../../screens/AdminConsole/ShopSubscriptions/AdminManageShopSubscriptions";

import AdminTaxOrganization from "../../screens/AdminConsole/TaxRate/AdminTaxOrganization";
import AdminTaxProduct from "../../screens/AdminConsole/TaxRate/AdminTaxProduct";
import AdminTaxShop from "../../screens/AdminConsole/TaxRate/AdminTaxShop";
import AdminTaxActiveProduct from "../../screens/AdminConsole/TaxRate/AdminTaxActiveProduct";

import OrganizationSummary from "../../screens/AdminConsole/Organizations/OrganizationSummary";
import ProductSummary from "../../screens/AdminConsole/Products/ProductSummary";
import ShowsSummary from "../../screens/AdminConsole/Shows/ShowsSummary";
import ShopSummary from "../../screens/AdminConsole/Shops/ShopSummary";
import VenueSummary from "../../screens/AdminConsole/Venues/VenueSummary";
import TaxRateSummary from "../../screens/AdminConsole/TaxRate/TaxRateSummary";

const Routing = () => {
  const { currentUser } = useAuth();

  return (
    <Router>
      <Routes>
        {/* public routes */}
        <Route
          path={PATHS.LOGIN}
          element={<AuthRouting mode={AUTH_SCREEN_MODES.LOGIN} />}
        />
        {/* TODO: Add proper routing paths and protections once screen is completed */}
        <Route path={PATHS.PLACEHOLDER1} element={<StoreFront />} />
        <Route
          path={PATHS.REGISTER}
          element={<AuthRouting mode={AUTH_SCREEN_MODES.REGISTER} />}
        />

        <Route path={PATHS.TERMS} element={<TermsAndConditionsScreen />} />
        <Route path={PATHS.PRIVACY} element={<PrivacyPolicyScreen />} />
        <Route path={PATHS.FORGOT_PASSWORD} element={<ForgotPasswordForm />} />
        <Route path={PATHS.RESET_PASSWORD} element={<PasswordReset />} />

        <Route
          element={
            <TopNavLayout
              hideBreadCrumbs={true}
              topNavClassName={""}
              hideOrgSelect={true}
            />
          }
        >
          <Route path={PATHS.STOREFRONT} element={<StoreFront />} />
        </Route>

        {(viewingAsGuest(currentUser) || viewingAsTrainer(currentUser)) && (
          <Route element={<TopNavLayout hideBreadCrumbs={true} hideOrgSelect={true} />}>
            <Route path={PATHS.HOME} element={<StoreSelectionScreen />} />
          </Route>
        )}

        {/* private routes: user needs to be authenticated */}
        <Route element={<PrivateRoute />}>
          {/* The route below renders a layout and wraps different routes inside it */}
          {/* <Route element={renderViewMode()}> */}
          <Route element={<Loader />}>
            {viewingAsMultiOrgUser(currentUser) && (
              <Route
                element={
                  <MultiOrganizationRouting hideLogo={true} hideBreadCrumbs={true} />
                }
              >
                <Route path={PATHS.HOME} element={<InternalAdminHomeScreen />} />
              </Route>
            )}
            {viewingAsOrgUser(currentUser) && (
              <>
                <Route
                  element={<TopNavLayout hideBreadCrumbs={true} hideOrgSelect={true} />}
                >
                  <Route path={PATHS.HOME} element={<OrganizationOrdersScreen />} />
                </Route>
                <Route element={<OrganizationRouting />}>
                  <Route
                    path={PATHS.RESERVATIONS}
                    element={<OrganizationReservationsScreen />}
                  />
                </Route>
                <Route
                  path={PATHS.CONFIGURE_STRIPE_ACCOUNT}
                  element={<ConfigureStripeAccountRedirect />}
                />
              </>
            )}
            {/* If user is admin grant access to admin console */}
            {viewingAsAdmin(currentUser) && (
              <Route element={<TopNavLayout hideBreadCrumbs={true} hideOrgSelect={true} />}>
                <Route path={PATHS.ADMIN_CONSOLE} element={<AdminNavigation />}>
                  <Route index element={<AdminManageUser />} />
                  <Route path={PATHS.ADMIN_SHOPS} element={<AdminManageShops />} />
                  <Route path={PATHS.ADMIN_PRODUCTS} element={<AdminManageProducts />} />
                  <Route path={PATHS.ADMIN_ORGS} element={<AdminManageOrgs />} />
                  <Route
                    path={PATHS.ADMIN_ACTIVE_PRODUCTS}
                    element={<AdminManageActiveProducts />}
                  />
                  <Route
                    path={PATHS.ADMIN_SHOP_SUBSCRIPTION}
                    element={<AdminManageShopSubscriptions />}
                  />
                  <Route path={PATHS.ADMIN_SHOWS} element={<AdminManageShows />} />
                  <Route path={PATHS.ADMIN_VENUES} element={<AdminManageVenues />} />
                  <Route path={PATHS.ADMIN_TAX_RATE}>
                    <Route index element={<AdminManageTaxRate />} />
                    <Route path={PATHS.ADMIN_TAX_ORG} element={<AdminTaxOrganization />} />
                    <Route path={PATHS.ADMIN_TAX_PRODUCT} element={<AdminTaxProduct />} />
                    <Route
                      path={PATHS.ADMIN_TAX_ACTIVE_PRODUCT}
                      element={<AdminTaxActiveProduct />}
                    />
                    <Route path={PATHS.ADMIN_TAX_SHOP} element={<AdminTaxShop />} />
                  </Route>
                </Route>
              </Route>
            )}
            {/* Admin summary pages */}
            {viewingAsAdmin(currentUser) && (
              <Route path={PATHS.SUMMARY}>
                <Route index element={<OrganizationSummary />} />
                <Route path={PATHS.PRODUCT_SUMMARY} element={<ProductSummary />} />
                <Route path={PATHS.SHOW_SUMMARY} element={<ShowsSummary />} />
                <Route path={PATHS.SHOP_SUMMARY} element={<ShopSummary />} />
                <Route path={PATHS.VENUE_SUMMARY} element={<VenueSummary />} />
                <Route path={PATHS.TAX_SUMMARY} element={<TaxRateSummary />} />
              </Route>
            )}
            {viewingAsDeliveryUser(currentUser) && (
              <Route element={<DeliveryRouting />}>
                <Route path={PATHS.HOME} element={<DelivererHomeScreen />} />
              </Route>
            )}
            <Route path={PATHS.HOME} element={<StoreSelectionScreen />} />
            {/* <Route path={PATHS.PLACEHOLDER3} element={<DelivererHome />} /> */}
            {/* <Route path={PATHS.RESERVATIONS} element={renderReservationSecreen()} /> */}

            <Route element={<TopNavLayout hideBreadCrumbs={true} hideOrgSelect={true} />}>
              <Route path={PATHS.ACCOUNT} element={<AccountScreen />} />
              <Route path={PATHS.CHECKOUT} element={<TrainerCheckout />} />
            </Route>
          </Route>
          {/* </Route> */}
        </Route>

        {/* catch all path */}
        <Route path="*" element={<div className="flex justify-center">Not found</div>} />
      </Routes>
    </Router>
  );
};

export default Routing;
