// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'
import App from "App/App"
import '../css/application.scss'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: "https://99a32205b40c4678b22d7aaa098c7e66@o1145641.ingest.sentry.io/6213206",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    maxBreadcrumbs: 50,
    debug: false,
  });


document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('main');
  const config = JSON.parse(el.getAttribute('data'));
  ReactDOM.render(
    <App name="React" config={config}/>,
    document.body.appendChild(el),
  )
})
