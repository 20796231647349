import { gql } from "@apollo/client";

export default gql`
  query getUsersByOrganization($organizationUid: String!) {
    organizationUsers(organizationUid: $organizationUid) {
      uid
      name
    }
  }
`;
