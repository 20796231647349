import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../constants/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const NotifyModal = ({ setShowModal, action, heading, text, confirmDelete }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-3xl h-52 sm:w-full">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col h-full w-full bg-white outline-none focus:outline-none pl-3 pr-6">
            <div className="relative p-6 flex-auto flex flex-col">
              <div className="flex flex-row mt-2">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  fixedWidth
                  className={`${
                    action !== "deleteconfirmation" || action !== "errormessage"
                      ? "bg-white opacity-30"
                      : "text-discard-red"
                  } pt-1`}
                  size="lg"
                />
                <p className="font-bold pl-2">{heading}</p>
              </div>
              <p className="mt-3">{text}</p>
              <div className="flex flex-row justify-end mt-12">
                {action === "confirmreservation" ||
                action === "confirmchanges" ||
                action === "errormessage" ? (
                  <div
                    className={`text-white text-xs text-center font-bold rounded ${
                      action == "errormessage" ? "bg-discard-red" : "bg-button-blue"
                    } p-2 w-2/12 h-8 `}
                  >
                    {action === "confirmchanges" || action === "errormessage" ? (
                      <button onClick={() => window.location.reload()}>OK</button>
                    ) : (
                      <Link to={PATHS.HOME}>OK</Link>
                    )}
                  </div>
                ) : action === "deleteconfirmation" ? (
                  <div className="flex flex-row justify-end">
                    {/* Delete Button */}
                    <button
                      className="text-white text-xs font-bold rounded bg-discard-red p-2 h-8 mr-3"
                      onClick={confirmDelete}
                    >
                      Request cancellation
                    </button>
                    {/* Cancel Button */}
                    <button
                      className="text-xs font-bold rounded bg-white p-2 h-8"
                      onClick={() => setShowModal(false)}
                    >
                      Go back
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default NotifyModal;
