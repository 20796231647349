import { gql } from "@apollo/client";
// FIXME: This should be a .gql file
export default gql`
  mutation EditStallReservation(
    $stallReservationUid: String!
    $stallCount: Int!
    $note: String!
    $showSegmentUids: [String!]
    $stallTypeUid: String
  ) {
    editStallReservation(
      input: {
        stallReservationUid: $stallReservationUid
        stallCount: $stallCount
        note: $note
        showSegmentUids: $showSegmentUids
        stallTypeUid: $stallTypeUid
      }
    ) {
      stallReservation {
        uid
        state
        stallCount
        note
        stallType {
          uid
          name
        }
        showSegments {
          uid
          name
        }
      }
      errors
    }
  }
`;
