import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query GetDeliveryOrdersByOrg($organizationUid: String!, $deliveryDate: ISO8601Date!) {
    deliveryOrdersByOrg(organizationUid: $organizationUid, deliveryDate: $deliveryDate) {
      uid
      deliveryStatus
      paymentStatus
      completedAt
      stablingWith
      deliveredBy {
        uid
        displayName
      }
      shop {
        uid
      }
      user {
        uid
        displayName
        firstName
        lastName
        name
      }
      deliveryDate
      deliveryTime {
        uid
        name
      }
      lineItems {
        uid
        quantity
        isRefund
        calculatedQuantity
        delivered
        deliveredAt

        activeProduct {
          uid
          displayPrice
          price
          product {
            uid
            name
          }
        }
      }
    }
  }
`;

// Example data
// {
//   "$organizationUid": "langershows",
//   "deliveryDate": "2022-07-06"
// }
