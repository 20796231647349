import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { PATHS, VIEW_MODES } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import BulkActionSelect from "./BulkActionSelect";
import ReservationCounter from "../ReservationCounter";
import OrgConfirmationModal from "../../screens/OrganizationReservationsScreen/OrgConfirmationModal";
import { getViewMode } from "../../../utils/user.utils";
import GlobalFilter from "./GlobalFilter";

const Table = ({ columns, data, getRowProps, type }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // .apply() is used to call the function with conditional parameters in this case it will generate the select checkboxes if user is viewing as an organization
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds },
    state,
  } = useTable.apply(
    this,
    type === "organization"
      ? [
          {
            columns,
            data,
            initialState: {
              hiddenColumns: ["uid"], // Hiding the uid column this will be used to pass uid's to mutations
            },
            type, //passed to send user type prop to custom cells
          },
          useFilters,
          useGlobalFilter,
          useSortBy,
          useRowSelect,
          hooks => {
            hooks.visibleColumns.push(columns => [
              // Let's make a column for selection
              {
                id: "selection",
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <div>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to the render a checkbox
                Cell: ({ row }) => (
                  <div>
                    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ]);
          },
        ]
      : [
          {
            columns,
            data,
            initialState: {
              hiddenColumns: ["uid"], // Hiding the uid column this will be used to pass uid's to mutations
            },
            type, //passed to send user type prop to custom cells
          },
          useFilters,
          useGlobalFilter,
          useSortBy,
        ],
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState(false); //To trigger Confirmation Modal for Org User
  const [selectedOption, setSelectedOption] = useState(null); //Bulk Selected option

  const showSegmentSelectRef = React.createRef();
  const stallTypeSelectRef = React.createRef();
  const editMenuRef = React.createRef();

  const shouldRenderTableBody = () => Array.isArray(rows) && rows.length !== 0;
  const shouldRenderTablePlaceholder = () => {
    // Only return tablePlaceholder if its a trainer and the rows aren't being filtered to 0
    return (
      !shouldRenderTableBody() &&
      VIEW_MODES.TRAINER === getViewMode(currentUser) &&
      data.length < 1
    );
  };

  const nonEmptyTable = () => {
    return (
      <div className="py-3 align-middle inline-block min-w-full w-full">
        <div className="shadow bg-white border-b border-gray-200 sm:rounded-lg ">
          {/* Reservations heading start here */}
          <div className="bg-white flex flex-col md:flex-row justify-between items-start md:items-center pt-6">
            <h6 className="text-xl sm:text-2xl font-semibold ml-5 lg:ml-10">
              Reservations
            </h6>
            <div className="flex flex-row justify-center items-center mt-3 px-3 md:px-0 md:mt-0">
              <div id="filter" className="mt-0">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
              {headerGroups.map(headerGroup =>
                headerGroup.headers.map(column =>
                  column.Filter ? (
                    <div key={column.id} className="w-28 lg:w-44 mt-0 sm:mt-12 ">
                      {column.render("Filter")}
                    </div>
                  ) : null,
                ),
              )}
            </div>
          </div>
          {/* Selecting Bulk Actions in Org View */}
          {type === "organization" ? (
            <div className="flex flex-row min-w-full">
              {/* Bulk Action select dropdown which will set confirmation modal to trigger if option is selecterd */}
              <BulkActionSelect
                setShowConfirmationModal={setShowConfirmationModal}
                setSelectedOption={setSelectedOption}
                isDisabled={!Object.keys(selectedRowIds).length > 0 ? true : false}
              />
              {/* Get the Reservations selected rows */}
              {Object.keys(selectedRowIds).length > 0 ? (
                <ReservationCounter count={Object.keys(selectedRowIds).length} />
              ) : null}
              {/* Confirm changes Modal */}
              {showConfirmationModal ? (
                <OrgConfirmationModal
                  isBulk={true}
                  showModal={showConfirmationModal}
                  setShowModal={setShowConfirmationModal}
                  selectedRows={selectedFlatRows}
                  selectedOption={selectedOption}
                />
              ) : null}
            </div>
          ) : null}
          <div className="mx-0 bg-white mt-8 max-h-98 h-auto overflow-auto">
            {/* Table starts here */}
            <table
              {...getTableProps()}
              className={`min-w-full table-fixed ${
                type === "organization" ? "w-auto 2xl:w-100%" : "nil:w-auto lg:w-full"
              }`}
              style={{ borderCollapse: "separate" }}
            >
              <thead className="bg-white z-20 sticky">
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      // Add the sorting props to control sorting. For this example
                      // we can add them into the header props
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs bg-white border-b border-gray-200 sticky top-0 z-20 font-medium text-gray-700 uppercase tracking-wider max-w-xs xl:max-w-xl "
                        style={{
                          minWidth: column.minWidth,
                          width: column.width,
                          maxWidth: column.maxWidth,
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        // {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render("Header")}
                        {/* Add a sort direction indicator */}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="bg-white">
                {/* Replace the below with `rows` when not using pagination */}
                {shouldRenderTableBody() &&
                  rows.map(row => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps(getRowProps(row))}>
                        {row.cells.map(cell => {
                          if (cell.column.Header === "STALLS") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 max-w-xs xl:max-w-xl"
                                ref={stallTypeSelectRef}
                              >
                                {cell.render("Cell", { stallTypeSelectRef })}
                              </td>
                            );
                          } else if (cell.column.Header === "WEEK") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 max-w-xs xl:max-w-xl"
                                ref={showSegmentSelectRef}
                              >
                                {cell.render("Cell", { showSegmentSelectRef })}
                              </td>
                            );
                          } else if (cell.column.id === "edit") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 max-w-xs xl:max-w-xl"
                                ref={editMenuRef}
                              >
                                {cell.render("Cell", { editMenuRef })}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-2 max-w-xs xl:max-w-xl"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!shouldRenderTableBody() && (
              <div className="border-t-2 border-gray-200 flex flex-row justify-center py-6 font-bold text-lg">
                No Stall Reservations were returned
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const tablePlaceholder = () => {
    return (
      <div className="py-2 align-middle w-full inline-block min-w-full sm:px-6 lg:px-8">
        <div
          className="py-2 flex flex-col min-w-full sm:px-6 lg:px-8 h-96 items-center justify-center"
          style={{ backgroundColor: "rgba(41, 121, 255, 0.1)" }}
        >
          <button
            className="rounded-full text-white bg-button-blue"
            style={{ height: 40, width: 230 }}
            onClick={() => navigate(PATHS.RESERVATIONS)}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="pr-2" />
            Make a reservation
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex gap-x-2"></div>
      <div className="mt-2 flex flex-col ">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-auto">
          {shouldRenderTablePlaceholder() ? tablePlaceholder() : nonEmptyTable()}
        </div>
      </div>
    </>
  );
};

export default Table;
