/* ===================================== AUDIT LOG ========================================= *
 * Title: AdminManageVenues
 * Author: Faizan Omer
 * Created on: 05/31/22
 * Description: The main screen for the management for venues
 * ========================================================================================== */

import React from "react";
import { useQuery } from "@apollo/client";
import GetAllVenues from "../../../../apollo/query/admins/venues/GetAllVenues";
import VenueCard from "./VenueCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../shared/Loading";
import AddVenueForm from "./AddVenueForm";

const AdminManageVenues = () => {
  const { data, loading, error, refetch } = useQuery(GetAllVenues);

  const [addVenueForm, setAddVenueForm] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allVenues
      : data?.allVenues.filter(
          venue =>
            venue.name.toLowerCase().includes(lowercasedFilter) ||
            venue.organization.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Venues</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setAddVenueForm(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-30 items-center justify-start">Name</p>
        <p className="flex flex-basis-30 items-center justify-center">Organization</p>
        <p className="flex flex-basis-30 items-center justify-center">Created At</p>
        <p className="flex flex-basis-5 items-center justify-center"> </p>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Display all Venues */}
      {data &&
        !loading &&
        filteredData &&
        filteredData.map(venue => (
          <div key={venue.uid}>
            <VenueCard venue={venue} />
          </div>
        ))}
      {/* Add Venue Form */}
      {addVenueForm && (
        <AddVenueForm setVenueForm={setAddVenueForm} refetchVenueList={refetch} />
      )}
    </div>
  );
};

export default AdminManageVenues;
