/* ===================================== AUDIT LOG ========================================= *
 * Title: Expand order
 * Author: Faizan Omer
 * Created on: 03/26/22
 * Description: Individual product delivery status is shown in a card format
 * ========================================================================================== */

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { DeliveryUpdateOrder } from "../../../apollo/mutation/deliverers/order/update";
import { MODAL_MESSAGES } from "../../../constants/consts";
import ConfirmDeliveryModal from "./ConfirmDeliveryModal";
import Loading from "../../shared/Loading";
import ErrorModal from "../../shared/ErrorModal";

const ExpandOrder = ({
  lineProduct,
  orderUid,
  setOrder,
  finalDelivery,
  userName,
  disableButton,
  setDisableButton,
}) => {
  const [showConfirmDelivery, setShowConfirmDelivery] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [checkboxBackgroundColor, setCheckboxBackgroundColor] = useState('#E0E0E0')
  const [checkboxColor, setCheckboxColor] = useState('black')

  const productName = lineProduct.activeProduct.product.name;

  const [deliveryUpdateOrder, { loading, error, data }] =
    useMutation(DeliveryUpdateOrder);

  useEffect(() => {
    if (!data) { return; }
    if (!data.deliveryUpdateOrder || data.deliveryUpdateOrder.errors.length > 0) {
      return setShowErrorModal(true);
    }
    setOrder(data.deliveryUpdateOrder.order);
  }, [data]);

  useEffect(() => {
    if (disableButton) {
      setCheckboxBackgroundColor('#F7F9FA')
      setCheckboxColor('E0E0E0')
    }
    else {
      setCheckboxBackgroundColor('#E0E0E0')
      setCheckboxColor('black')
    }
  }, [disableButton]);

  // This function handles the order delivery when line item is clicked
  const handleOrderDelivery = () => {
    if (disableButton) { return; }
    finalDelivery() && !lineProduct.delivered ? setShowConfirmDelivery(true) : toggleDelivered();
  };

  //   This function marks the order status to delivered for the specific line item
  const toggleDelivered = (confirmDelivery = false) => {
    setDisableButton(true);
    deliveryUpdateOrder({
      variables: {
        orderUid,
        confirmDelivery,
        lineItem: { uid: lineProduct.uid, delivered: !lineProduct.delivered },
      },
    }).then(() => {setDisableButton(false)});
  }

  return (
    <div
      className="h-14 rounded-md flex flex-row items-center w-full px-2 py-2 mb-2"
      style={{ backgroundColor: "#F7F9FA" }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleOrderDelivery();
      }}
    >
      <p className="font-bold text-sm flex flex-basis-30 justify-start items-center">
        {productName}
      </p>
      <p className="text-sm flex flex-basis-30 justify-start items-center text-center">
        x{lineProduct.calculatedQuantity}
      </p>
      {/* Delivery Status */}
      <div
        className="h-12 w-14 rounded-md flex justify-center items-center"
        style={
          lineProduct.delivered
            ? { backgroundColor: '#A6EA8A' }
            : { backgroundColor: checkboxBackgroundColor }
        }
      >
        {/* Show loading if api call in progress */}
        {!loading ? (
          <FontAwesomeIcon
            fixedWidth
            icon={faCheck}
            style={lineProduct.delivered ? { color: "#036D19" } : { color: checkboxColor }}
          />
        ) : (
          <Loading size="lg" color="black" inline={true} />
        )}
      </div>
      {showConfirmDelivery && (
        <ConfirmDeliveryModal
          handleConfirmDelivery={() => {
            setShowConfirmDelivery(false)
            toggleDelivered(true)
          }}
          closeModal={() => {
            setShowConfirmDelivery(false)
          }}
          userName={userName}
        />
      )}
      {(error || showErrorModal) && (
        <ErrorModal
          buttonMessage={MODAL_MESSAGES.BUTTONRELOAD}
          onClick={() => window.location.reload()}
          heading={MODAL_MESSAGES.ERRORHEADING}
          text={MODAL_MESSAGES.ERRORTEXT}
        />
      )}
    </div>
  );
};

export default ExpandOrder;
