/* ===================================== AUDIT LOG ========================================= *
 * Title: Render Order
 * Author: Faizan Omer
 * Created on: 04/4/22
 * Description: This subcomponent of the Account Screen, component is used to render the order in
 * a card and expand with line items on click
 * ========================================================================================== */

import React from "react";
import { centsToDollarStr } from "../../../utils/money.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { default as _ } from "lodash";
import ExpandOrder from "./ExpandOrder";

const RenderOrder = ({ order }) => {
  const [expandOrder, setExpandOrder] = React.useState(false);

  function onExpandOrder() {
    setExpandOrder(!expandOrder);
  }
  return (
    <>
      <div
        className="flex flex-row w-full mt-4 sm:mt-0 items-center h-20 md:h-12 cursor-pointer"
        onClick={onExpandOrder}
      >
        {/* Name and order time */}
        <div className="flex flex-col flex-basis-30 text-left items-start justify-center">
          <p className="font-bold text-sm">{order.shop.name}</p>
          <p className="text-xs" style={{ color: "#9C9C9C" }}>
            {order.deliveryTime.name}
          </p>
        </div>
        {/* Order Total */}
        <div className="font-bold text-sm flex-basis-30 flex text-center items-center justify-center">
          {centsToDollarStr(order.total)}
        </div>
        {/* Delivery Status */}
        <div className="flex-basis-30 flex text-right items-center justify-end">
          <FontAwesomeIcon
            fixedWidth
            icon={faCheckCircle}
            size="lg"
            style={
              order.deliveryStatus === "completed"
                ? { color: "black" }
                : { color: "#EDEDF0" }
            }
          />
        </div>
      </div>
      {expandOrder
        ? _.sortBy(order.lineItems, ["activeProduct.product.name"]).map(item => (
            <ExpandOrder key={item.uid} lineProduct={item} />
          ))
        : null}
    </>
  );
};

export default RenderOrder;
