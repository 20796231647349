import React from "react";
import classNames from "../../utils/classNames";

import { getInitials } from "../../utils/user.utils";

const AvatarCircle = ({
  currentUser,
  containerClassName,
  innerClassName,
  size,
  borderSize
}) => (
  <div className="flex">
    <div
      className={classNames(
        `relative h-${size || 8} w-${size || 8} rounded-full`,
        containerClassName,
      )}
    >
      {currentUser.avatarUrl ? (
        <img
          src={currentUser.avatarUrl}
          alt="Profile photo"
          className={classNames(
            "object-cover rounded-full",
            innerClassName,
          )}
        />
      ) : (
        <div
          className={classNames(
            innerClassName || "bg-light-gray text-black font-bold text-5xl",
            " rounded-full",
          )}
        >
          {getInitials(currentUser)}
        </div>
      )}
    </div>
  </div>
);

export default AvatarCircle;
