import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import EditActiveProductForm from "./EditActiveProductForm";

const ActiveProductCard = ({ activeProduct, refetchActiveProductList }) => {
  const [openEditActiveProduct, setOpenEditActiveProduct] = React.useState(false);
  return (
    <div className="bg-white h-20 rounded-md mt-4 shadow-md flex flex-col justify-center">
      <ul className="flex justify-between px-5">
        {/* Image */}
        <li className="flex flex-basis-5 items-center justify-start">
          <img
            src={activeProduct.product.imageUrl}
            className="w-10 h-10 object-cover rounded-xl  bg-white shadow"
          />
        </li>
        {/* Name */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{activeProduct.product.name}</p>
        </li>
        {/* Shop */}
        <li className="flex flex-basis-25 items-center justify-start">
          <p className="text-sm">{activeProduct.shop.name}</p>
        </li>
        {/* Price */}
        <li className="flex flex-basis-15 items-center justify-start">
          <p className="text-sm">{activeProduct.displayPrice}</p>
        </li>
        {/* Enabled*/}
        <li className="flex flex-basis-5 items-center justify-center">
          <p className="text-sm italic font-semibold">
            Enabled:{" "}
            <span
              className={`text-sm not-italic font-semibold ${
                activeProduct.enabled ? "text-button-green" : "text-warning-red"
              }`}
            >
              {activeProduct.enabled.toString()}
            </span>
          </p>
        </li>
        {/* Edit active product */}
        <li className="flex flex-basis-5 items-center justify-center">
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="cursor-pointer"
            onClick={() => setOpenEditActiveProduct(true)}
          />
        </li>
      </ul>

      {/* Edit Active Product */}
      {openEditActiveProduct && (
        <EditActiveProductForm
          activeProduct={activeProduct}
          setEditActiveProduct={setOpenEditActiveProduct}
          refetchActiveProductList={refetchActiveProductList}
        />
      )}
    </div>
  );
};

export default ActiveProductCard;
