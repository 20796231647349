import React from "react";
import './BetterModal.scss';

const BetterModal = ({
  heading,
  text,
  activeOnClick,
  extendModalClassName = '',
  displayActiveButton = true,
  activeButtonMessage,
  disableActiveButton = false,
  displayCancelButton = true,
  cancelButtonMessage,
  cancelButtonOnClick,
  close,
  children
}) => {
  const onClose = () => {
    close && close();
  };

  return (
    <>
      <div className="flex flex-col justify-end sm:justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="opacity-50 fixed inset-0 z-45 bg-black" onClick={() => onClose()}></div>
        <div className={["relative w-auto mb-20 sm:mb-0 md:w-1/2 lg:w-2/3 my-6 mx-auto overflow-y-auto no-scrollbar", extendModalClassName].join(' ')}>
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none pl-3 pr-6">
            <div className="relative p-6 flex-auto flex flex-col">
              <div className="flex flex-row mt-2 items-center justify-center">
                <p className="font-bold text-xl">{heading}</p>
              </div>
              {children ? children : <p className="mt-3">{text}</p>}
              <div className="flex flex-row justify-end mt-12 self.end">
                {displayActiveButton && (
                  <button
                    className={[
                      "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md z-13",
                      "bg-button-green text-white hover:bg-gray-50 hover:text-gray-700",
                    ].join(' ')}
                    onClick={() => activeOnClick()}
                    disabled={disableActiveButton}
                  >
                    {activeButtonMessage}
                  </button>
                )}

                {displayCancelButton && (
                  <button
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    onClick={() => ((cancelButtonOnClick || close)())}
                  >
                    {cancelButtonMessage}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BetterModal;
