import React, { useState, useContext } from "react";
import { orgTableContext } from "../../../utils/orgTableContext";
import Select from "react-select";
import { element } from "prop-types";

const MultiPill = ({ value, row: { index }, data, showSegmentSelectRef }) => {
  const { editRowNumber, setWeek, setReservationUid, setShowData, setShowSegmentNames } =
    useContext(orgTableContext);
  let defaultOption = null;

  let initialWeeks = null;
  if (index === editRowNumber) {
    initialWeeks =
      editRowNumber === index
        ? data[index].showSegments.map(segment => segment.uid)
        : null;
    defaultOption = data[index].showSegments
      .filter(element => data[index].showSegmentNames.includes(element.name))
      .map(item => {
        return { label: item.name, value: item.uid };
      });
  }

  React.useEffect(() => {
    if (index === editRowNumber) {
      setReservationUid(data[index].uid);
      setWeek(initialWeeks ? initialWeeks : null);
      setShowData(data[index]);
      setShowSegmentNames(data[index].showSegmentNames);
    }
  }, [editRowNumber]);

  // Options to feed the select dropdown
  const options = data[index].show.showSegments.map(showSegment => {
    return { value: showSegment.uid, label: showSegment.name };
  });

  // On selecting a dropdown option
  const handleSelect = selected => {
    const allValues = selected.map(select => select.value);
    const allNames = selected.map(select => select.label);
    setWeek(allValues && allValues.length ? allValues : undefined);
    setShowSegmentNames(allNames && allNames.length ? allNames : undefined);
  };

  if (index === editRowNumber) {
    return (
      <div className="flex flex-wrap">
        <Select
          className="w-full"
          options={options}
          isMulti
          isSearchable={false}
          defaultValue={defaultOption}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          closeMenuOnScroll={() => true}
          isClearable={false}
          onChange={handleSelect}
          menuPortalTarget={showSegmentSelectRef.target}
          components={{
            IndicatorSeparator: () => null,
          }}
          menuPosition={"fixed"}
        />
      </div>
    );
  } else {
    return (
      <div className="flex flex-row flex-wrap">
        {value.map(item => (
          <div key={item} className="bg-gray-200 rounded-md px-2 my-1 mx-1">
            {item}
          </div>
        ))}
      </div>
    );
  }
};

export default MultiPill;
