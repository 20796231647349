import React from "react";
import Select from "react-select";
import { AdminEditActiveProduct } from "../../../../apollo/mutation/admins/active_products/AdminEditActiveProduct";
import { useMutation } from "@apollo/client";

const EditActiveProductForm = ({
  activeProduct,
  setEditActiveProduct,
  refetchActiveProductList,
}) => {
  const [adminEditActiveProduct] = useMutation(AdminEditActiveProduct);
  const [enabledStatus, setEnabledStatus] = React.useState(activeProduct.enabled);
  const options = [
    { value: false, label: "Disabled" },
    { value: true, label: "Enabled" },
  ];
  const editActiveProductAction = () => {
    adminEditActiveProduct({
      variables: {
        activeProductUid: activeProduct.uid,
        enabledStatus: enabledStatus,
      },
    })
      .then(res => {
        refetchActiveProductList();
        setEditActiveProduct(false);
      })
      .catch(e => console.error(e));
  };
  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            <div className="flex flex-col px-10">
              <p className="mt-4 self-center font-bold text-3xl">Edit Active Product</p>
              <div className="flex mt-4">
                <img
                  src={activeProduct.product.imageUrl}
                  className="w-20 h-20 rounded-full"
                />
                <p className="font-bold ml-3">{activeProduct.product.name}</p>
              </div>
              {/* Select status */}
              <Select
                className="flex-basis-30 mt-4"
                defaultValue={{
                  label: activeProduct.enabled ? "Enabled" : "Disabled",
                  value: activeProduct.enabled,
                }}
                options={options}
                isSearchable={false}
                closeMenuOnSelect={true}
                closeMenuOnScroll={() => true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => setEnabledStatus(selected.value)}
              />
            </div>
            <button
              type="submit"
              className="mt-5 text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
              onClick={editActiveProductAction}
            >
              Edit Active Product
            </button>
          </div>
          <button
            onClick={() => setEditActiveProduct(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default EditActiveProductForm;
