import React from 'react'
import classNames from '../../../utils/classNames'
import getStallReservationPillFormat from '../../../utils/getStallReservationPillFormat'

const StatePill = ({value}) => {
  const status = value ? value.toLowerCase() : "unknown"

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        getStallReservationPillFormat(status),
      )}
    >
      {status}
    </span>
  )
}

export default StatePill
