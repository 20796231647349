/* ===================================== AUDIT LOG ========================================= *
 * Title: Cart Basket
 * Author: Faizan Omer
 * Created on: 03/02/22
 * Description: A cart component for for displaying items that are going to checkout
 * ========================================================================================== */

import React, { useState, useEffect } from "react";
import { centsToDollarStr } from "../../utils/money.utils";

const CartBasket = ({
  cartItems,
  subTotal,
  handleCheckout,
  deliveryTime,
  stablingWith,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [validCart, setValidCart] = useState(false)

  useEffect(() => {
    // Check if being viewed on mobile or desktop/laptop screen
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  // Setting a maximum width for mobile devices
  // let isMobile = width <= 820;
  let isMobile = width < 768;

  useEffect(() => {
    const isValidCart = subTotal > 0
      && stablingWith
      && stablingWith.length > 0
      && (
        cartItems.filter(ci => ci.itemType !== 'Stall').length > 0
          ? deliveryTime
          : true
      );
    console.log(`cartItems: ${JSON.stringify(cartItems)}`)
    console.log(`stablingWith: ${JSON.stringify(stablingWith)}`)
    console.log(`isValidCart: ${isValidCart}`)
    setValidCart(isValidCart);
  }, [cartItems, subTotal, stablingWith]);

  return (
    <div
      className={[
        "flex h-16 fixed bottom-0 z-10 w-full rounded items-center",
        "md:static md:h-auto md:flex-col md:mt-3 md:overflow-y-visible md:mb-6 md:pb-3",
        "md:z-0",
        "2xl:rounded-md",
        isMobile ? "bg-cart-gray-mobile" : "bg-cart-gray-desktop",
      ].join(" ")}
    >
      {/* If mobile only show checkout button and subtotal else show details */}
      {isMobile ? (
        <div className="flex w-full px-2">
          <p className="text-white text-lg">Subtotal:</p>
          <p className="text-white text-lg ml-5">${subTotal}</p>
        </div>
      ) : (
        <div className="flex flex-col h-full w-full p-3 2xl:p-10 md:z-10">
          <p className="font-bold md:text-xl xl:text-2xl">Cart</p>
          {/* Item details */}
          <div className="flex w-full md:px-3 mt-10 text-sm 2xl:text-base">
            <div className="flex flex-basis-50 justify-start items-center">Item</div>
            <div className="flex flex-basis-15 justify-center items-center">Quantity</div>
            <div className="flex flex-basis-25 justify-end items-center">Cost</div>
          </div>
          {/* Display selected cart Items */}
          <div className="md:px-3">
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map(item => (
                <div key={item.uid}>
                  <div key={item.uid} className="flex w-full mt-3">
                    <li className="flex flex-col justify-start items-start font-semibold flex-basis-50 text-left text-sm 2xl:text-lg">
                      {item.name}
                    </li>
                    <li className="flex justify-center items-center font-semibold flex-basis-15 w-8 text-center text-sm 2xl:text-lg">
                      {item.qty}
                    </li>
                    <li className="flex justify-end items-center font-semibold flex-basis-25 text-center text-sm 2xl:text-lg">
                      {centsToDollarStr(item.price * item.qty)}
                    </li>
                  </div>
                  <ul key={`sub-${item.uid}`} className="flex w-full mt-1">
                    <li
                      key="sd"
                      className="flex flex-col justify-start items-start font-semibold flex-basis-50 text-left text-xs 2xl:text-md text-cart-gray-mobile"
                    >
                      {item.shortDescription}
                    </li>
                    <li
                      key="nil"
                      className="flex justify-center items-center flex-basis-15 w-8 text-center text-sm 2xl:text-lg"
                    >
                      {""}
                    </li>
                    <li
                      key="dp"
                      className="flex justify-end items-center flex-basis-25 text-center text-xs 2xl:text-md text-cart-gray-mobile"
                    >
                      {item.qty > 1 ? `${item.displayPrice} each` : ""}
                    </li>
                  </ul>
                </div>
              ))}
          </div>
          {/* Display subtotal */}
          <div className="flex w-full justify-between mt-auto px-5 md:pt-6 md:border-t-1 md:border-cart-gray-mobile">
            <p className="font-bold xl:text-lg 2xl:text-xl">Subtotal:</p>
            <p className="font-bold xl:text-lg 2xl:text-xl">${subTotal}</p>
          </div>
        </div>
      )}
      {/* Checkout Button */}
      <div
        className={[
          "h-9 w-56 mx-3 md:mx-0 md:px-3 rounded text-center font-bold md:h-20 md:min-h-20 lg:h-24 2xl:h-28 md:w-full",
          "md:flex md:flex-col md:justify-end md:z-1",
          isMobile ? "bg-cart-gray-mobile" : "bg-cart-gray-desktop",
        ].join(" ")}
      >
        <button
          onClick={handleCheckout}
          disabled={!validCart}
          style={
            validCart
              ? { backgroundColor: "#036D19", color: "#FFFF" }
              : isMobile
              ? { backgroundColor: "#EDEDF0", color: "#9696A0" }
              : { backgroundColor: "#ECF7ED", color: "#87C289" }
          }
          className="h-9 w-28 sm:w-56 rounded text-center font-bold md:h-16 lg:h-24 2xl:h-28 md:w-full"
        >
          Checkout
        </button>
      </div>
    </div>
  );
};

export default CartBasket;
