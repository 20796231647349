import { gql } from "@apollo/client";

export const AdminUpdateUser = gql`
  mutation AdminUpdateUser($userUid: String!, $displayName: String, $isAdmin: Boolean) {
    adminUpdateUser(
      input: { userUid: $userUid, displayName: $displayName, isAdmin: $isAdmin }
    ) {
      user {
        uid
        isAdmin
        displayName
      }
      errors
    }
  }
`;
