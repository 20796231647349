/* ===================================== AUDIT LOG ========================================= *
 * Title: Render Order
 * Author: Faizan Omer
 * Created on: 03/25/22
 * Description: This subcomponent of the OrderstoDeliver component is used to render the order in
 * a card and expand with line items on click
 * ========================================================================================== */

import React, { useState, useEffect } from "react";
import ExpandOrder from "./ExpandOrder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { default as _ } from "lodash";

const RenderOrder = ({ initialOrder }) => {
  const [expandOrder, setExpandOrder] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [order, setOrder] = useState(initialOrder);

  // Enables button when order list updates
  useEffect(() => {
    setDisableButton(false);
  }, [order]);

  function onExpandOrder() {
    setExpandOrder(!expandOrder);
  }

  //   This function checks if the last delivery in an order is left
  function checkFinalDelivery() {
    return order.lineItems.filter(item => item.delivered === false).length === 1;
  }

  return (
    <>
      <div
        className="flex flex-row w-full items-center h-auto py-2"
        onClick={e => {
          e.preventDefault();
          onExpandOrder();
        }}
      >
        {/* Name and order time */}
        <div className="flex flex-col flex-basis-30 text-left items-start justify-center">
          <p className="font-bold text-sm">{order.user.name}</p>
          <p className="text-xs" style={{ color: "#9C9C9C" }}>
            {order.deliveryTime.name}
          </p>
        </div>
        {/* Stabling With */}
        <div className="text-sm flex-basis-30 flex text-center items-center justify-center">
          {order.stablingWith}
        </div>
        {/* Delivery Status */}
        <div className="flex-basis-30 flex text-right items-center justify-end">
          <FontAwesomeIcon
            fixedWidth
            icon={faCheckCircle}
            size="lg"
            style={
              order.deliveryStatus === "completed"
                ? { color: "black" }
                : { color: "#EDEDF0" }
            }
          />
        </div>
      </div>
      {expandOrder
        ? _.sortBy(order.lineItems, ["activeProduct.product.name"]).map(item => (
            <ExpandOrder
              key={item.uid}
              lineProduct={item}
              order={order}
              orderUid={order.uid}
              setOrder={setOrder}
              userName={order.user.name}
              finalDelivery={checkFinalDelivery}
              disableButton={disableButton}
              setDisableButton={setDisableButton}
            />
          ))
        : null}
    </>
  );
};

export default RenderOrder;
