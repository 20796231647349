import React from "react";
import { useMutation } from "@apollo/client";
import { AddOrganization } from "../../../../apollo/mutation/admins/organizations/AddOrganization";

const AddOrganizationForm = ({ setShowForm, refetchOrganizationList }) => {
  const [name, setName] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [deliveryTime, setDeliveryTime] = React.useState("");

  const [addOrganization] = useMutation(AddOrganization);

  function addNewOrganization(e) {
    e.preventDefault();

    addOrganization({
      variables: {
        name: name,
        url: url,
        deliveryTime: deliveryTime,
      },
    })
      .then(res => {
        setShowForm(false);
        refetchOrganizationList();
      })
      .catch(e => console.error(e));
  }

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <form className="flex flex-col w-full px-10" onSubmit={addNewOrganization}>
            <label htmlFor="name" className="flex flex-col mt-4">
              Name
              <input type="text" id="name" onChange={e => setName(e.target.value)} />
            </label>
            <label htmlFor="url" className="flex flex-col mt-4">
              URL
              <input type="text" id="url" onChange={e => setUrl(e.target.value)} />
            </label>
            <label htmlFor="delivery-time" className="flex flex-col mt-4">
              Delivery Time
              <input
                type="text"
                id="delivery-time"
                onChange={e => setDeliveryTime(e.target.value)}
              />
            </label>
            <button
              type="submit"
              className="mt-5 text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
              disabled={name.length < 1}
            >
              Add Organization
            </button>
          </form>
          <button
            onClick={() => setShowForm(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddOrganizationForm;
