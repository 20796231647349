import React, { useState } from "react";
import UpdateUserProfile from "../../../apollo/mutation/UpdateUserProfile";
import { useMutation } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../contexts/AuthContext";
import { displayableName } from "../../../utils/user.utils";

// TODO: Remove this function and use user.utils instead
const getInitials = ({ firstName, lastName, displayName }) => {
  if (firstName && lastName) {
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  }
  return displayName
    .split(" ")
    .map(s => s.charAt(0))
    .join("")
    .toUpperCase();
};

const TrainerCard = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const [toggleEdit, setToggleEdit] = useState(false);
  const [name, setName] = useState(displayableName(currentUser));
  const [barn, setBarn] = useState(currentUser.barnName ? currentUser.barnName : "");
  const [bio, setBio] = useState(currentUser.bio ? currentUser.bio : "");
  const [textCount, setTextCount] = useState(
    currentUser.bio ? currentUser.bio.length : 0,
  );
  const [maxCountReached, setMaxCountReached] = useState(false);
  const [nameNull, setNameNull] = useState(false);
  const [
    updateUserProfile,
    { loading: approveLoading, data: approveData, error: approveError },
  ] = useMutation(UpdateUserProfile);

  //Function called on pressing save changes to update profile
  const handleSubmit = () => {
    // if name not provided throw error
    if (name.length === 0) {
      setNameNull(true);
      return;
    } else {
      updateUserProfile({
        variables: { displayName: name, barnName: barn, bio: bio },
      }).then(data => {
        const userData = data.data.updateUserProfile.user;
        userData.viewMode = "trainer";
        setCurrentUser(userData);
        setToggleEdit(false);
      });
      return;
    }
  };

  // Function to update description text count
  const updateTextCount = text => {
    if (text.length < 110) {
      setMaxCountReached(false);
      setTextCount(text.length);
    } else {
      setMaxCountReached(true);
      setTextCount(text.length);
    }
  };

  // To reset name null error
  const resetNameNull = text => {
    if (text.length > 0) {
      setNameNull(false);
      return;
    }
  };

  return (
    <div
      className={`flex flex-row bg-white rounded p-6 items-center ${
        toggleEdit
          ? "lg:h-85 lg:w-full xl:w-9/12 h-96"
          : "flex flex-row bg-white rounded p-6 items-center lg:w-full xl:w-8/12 2xl:w-7/12"
      }`}
    >
      {/* Image section */}
      <div
        className={
          toggleEdit
            ? "sm:w-1/5 pb-14 w-3/12 md:inline hidden"
            : "w-3/12 hidden md:inline"
        }
      >
        {currentUser.avatarUrl ? (
          <img
            src={currentUser.avatarUrl}
            alt="Profile photo"
            className="lg:h-32 lg:w-32 sm:w-16 sm:h-16 md:w-24 md:h-24 object-cover rounded-full flex items-center justify-center"
          />
        ) : (
          <div className="bg-light-gray text-black font-bold text-5xl h-32 w-32 rounded-full flex items-center justify-center">
            {getInitials(currentUser)}
          </div>
        )}
      </div>
      {/* User info section */}
      <div
        className={`flex flex-col justify-start pl-2 h-full ${
          toggleEdit ? "xl:w-5/12 lg:w-4/12 sm:w-3/12" : "lg:w-7/12 w-9/12"
        } `}
      >
        <div className={toggleEdit ? "pt-4" : ""}>
          {!toggleEdit ? (
            <>
              {/* Name */}
              <div className="text-medium-gray text-xs pb-1">TRAINER</div>
              <div
                className="text-black text-md pb-1"
                style={{ overflowWrap: "break-word" }}
              >
                {displayableName(currentUser)}
              </div>
              {/* Barn */}
              {currentUser.barnName && (
                <div>
                  <div className="text-medium-gray text-xs pb-1">BARN</div>
                  <div
                    className="text-black text-md pb-1"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {currentUser.barnName}
                  </div>
                </div>
              )}
              {/* Description */}
              {currentUser.bio && (
                <div>
                  <div className="text-medium-gray text-xs pb-1">DESCRIPTION</div>
                  <div
                    className="text-black text-md pb-1"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {currentUser.bio}
                  </div>
                </div>
              )}
            </>
          ) : (
            // Form to edit user info
            <form className="flex flex-col items-start">
              {/* Name */}
              <label className="text-medium-gray text-xs pb-1 mb-2" htmlFor="name">
                NAME
                <div className="relative">
                  <input
                    id="name"
                    type="text"
                    className="focus:ring-2 focus:ring-blue-500 focus:text-black rounded pl-2 pr-3 h-9"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                      resetNameNull(e.target.value);
                    }}
                  />
                  {/* warning icon */}
                  {nameNull ? (
                    <span className="absolute text-warning-red top-1/2 left-full transform -translate-x-full -translate-y-2/4">
                      <FontAwesomeIcon icon={faExclamationCircle} fixedWidth size="lg" />
                    </span>
                  ) : null}
                </div>
              </label>
              {/* Barn */}
              <label className="text-medium-gray text-xs pb-1 mb-2" htmlFor="barn">
                BARN
                <div>
                  <input
                    id="barn"
                    type="text"
                    className="focus:ring-2 focus:ring-blue-500 focus:text-black rounded pl-2 pr-3 h-9"
                    value={barn}
                    onChange={e => setBarn(e.target.value)}
                  />
                </div>
              </label>
              {/* Description */}
              <div className="flex flex-col relative w-56 lg:w-96 xl:w-97 sm:w-52">
                <label className="text-medium-gray text-xs pb-1 mb-2" htmlFor="bio">
                  DESCRIPTION
                  <div>
                    <input
                      id="bio"
                      type="text"
                      className="focus:ring-2 focus:ring-blue-500 focus:text-black pl-2 pr-8 absolute lg:w-96 xl:w-97 rounded h-9 sm:w-52"
                      value={bio}
                      maxLength="110"
                      onChange={e => {
                        setBio(e.target.value);
                        updateTextCount(e.target.value);
                      }}
                    />
                    {/* warning icon */}
                    {maxCountReached ? (
                      <span
                        data-testid="exclamation"
                        className="absolute text-warning-red top-1/2 left-full transform -translate-x-full -translate-y-3/4"
                      >
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          fixedWidth
                          size="lg"
                        />
                      </span>
                    ) : null}
                  </div>
                </label>
                {/* Counter for descrption text */}
                <p
                  className={
                    !maxCountReached
                      ? "text-medium-gray mt-4 self-end "
                      : "text-warning-red mt-4 self-end"
                  }
                >
                  {textCount}/110
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
      {/* Edit and Save buttons */}
      {!toggleEdit ? (
        // Edit button
        <div className="self-start justify-end flex flex-row sm:w-max w-3/12 text-edit-profile">
          <button
            name="edit"
            className="font-medium text-base w-max"
            onClick={() => setToggleEdit(true)}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
            Edit Profile
          </button>
        </div>
      ) : (
        <div className="flex flex-row self-end justify-end p-2 flex-grow-0 mt-5 relative right-56 sm:static lg:w-72 xl:w-5/12 2xl:w-4/12">
          {/* Cancel button */}
          <button
            name="cancel"
            className="bg-gray-300 text-white text-sm justify-self-end px-3 py-1 rounded mr-2 lg:w-48 h-9"
            onClick={() => setToggleEdit(false)}
          >
            Cancel
          </button>
          {/* Save button */}
          <button
            name="save"
            className="bg-light-blue text-white text-sm justify-self-end px-3 py-1 rounded w-32 md:w-48 h-9"
            onClick={handleSubmit}
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default TrainerCard;
