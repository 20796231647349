/* ===================================== AUDIT LOG ========================================= *
 * Title: Store Selection Screen
 * Author: Faizan Omer
 * Created on: 03/15/22
 * Description: Allows user's to enter their email for changing their passwords
 * ========================================================================================== */

import React from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../constants/consts";
import { useAuth } from "../../../contexts/AuthContext";

const ForgotPasswordForm = () => {
  const [email, setEmail] = React.useState("");
  const { resetPassword } = useAuth();
  const navigate = useNavigate();

  //   Function to send password change email
  async function submitForgetPasswordRequest(e) {
    e.preventDefault();
    try {
      resetPassword(email).then(() => navigate(PATHS.LOGIN));
    } catch (e) {
      console.error(e);
    }
  }
  // Sets email state based off of user input
  function handleInputChange(e) {
    const input = e.target.value;
    setEmail(input);
  }
  return (
    <div className="w-full h-full flex flex-col p-6 justify-center">

      <form
        onSubmit={e => submitForgetPasswordRequest(e)}
        className="h-full flex flex-col justify-between"
      >
        <p className="font-bold text-2xl 2xl:text-4xl">Password Reset</p>
        {/* Email Field */}
        <label htmlFor="email">
          Enter your Email:{" "}
          <input
            type="email"
            id="email"
            name="email"
            className="rounded border-gray-400 shadow-sm text-xs"
            onChange={e => handleInputChange(e)}
          />
        </label>
        {/* Submit Button */}
        <button
          type="submit"
          className="text-white rounded-sm shadow px-6 py-1 text-s w-full bg-button-green"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
