import { gql } from "@apollo/client";
// FIXME: This should be a .gql file

export default gql`
  query AdminOrderStatsByDate(
    $organizationUid: String
    $shopUid: String
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    adminOrderStatsByDay(
      organizationUid: $organizationUid
      shopUid: $shopUid
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      stats {
        grossRevenue
        organizationNetRevenue
        backgateNetRevenue
        stripeNetRevenue
        refundTotal
      }
    }
  }
`;

// Example data
// {
//   "startDate": "2023-01-01",
//   "endDate": "2023-03-15",
//   "organizationUid": "hdhp",
//   "shopUid": "hdhp_1_shop",
// }
