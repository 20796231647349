import React, { useState } from "react";
import Select from "react-select";
import { useQuery, useMutation } from "@apollo/client";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import GetShopsByOrganization from "../../../../apollo/query/admins/organizations/GetShopsByOrganization";
import OrganizationProducts from "../../../../apollo/query/admins/organizations/OrganizationProducts";
import { AdminAddActiveProduct } from "../../../../apollo/mutation/admins/active_products/AdminAddActiveProduct";
import ProductSelector from "../Shops/ProductSelector";

const AddActiveProductForm = ({ setAddActiveProduct, refetchActiveProductList }) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedShop, setSelectedShop] = useState("");
  const [activeProducts, setActiveProducts] = useState([]);

  const { data, loading, error } = useQuery(GetAllOrganizations);

  const [adminAddActiveProduct] = useMutation(AdminAddActiveProduct);

  const {
    data: shopData,
    loading: shopLoading,
    error: shopError,
  } = useQuery(GetShopsByOrganization, {
    variables: {
      organizationUid: selectedOrganization ? selectedOrganization : null,
    },
  });

  const {
    loading: productsLoading,
    data: products,
    error: productsError,
  } = useQuery(OrganizationProducts, {
    variables: {
      organizationUid: selectedOrganization.length > 0 ? selectedOrganization : null,
    },
  });

  const orgOptions =
    !loading && data
      ? data.allOrganizations.map(org => {
          return { value: org.uid, label: org.name };
        })
      : null;

  const shopOptions =
    !shopLoading && shopData
      ? shopData.organizationShops.map(shop => {
          return { value: shop.uid, label: shop.name };
        })
      : null;

  const addNewActiveProduct = e => {
    e.preventDefault();
    adminAddActiveProduct({
      variables: {
        shopUid: selectedShop,
        products: activeProducts,
      },
    })
      .then(res => {
        setAddActiveProduct(false);
        refetchActiveProductList();
      })
      .catch(e => console.error(e));
  };
  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4 overflow-y-auto">
          <p className="font-bold text-3xl mt-4">Add Active Product</p>
          <form className="flex flex-col w-full px-10" onSubmit={addNewActiveProduct}>
            <p className="font-bold text-xl mt-4">Select Organization</p>
            {!loading && data && (
              <Select
                placeholder={"Select Organization"}
                className="mt-3"
                options={orgOptions && orgOptions}
                isSearchable={true}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => {
                  setSelectedOrganization(selected.value);
                }}
              />
            )}
            {selectedOrganization && !shopLoading && shopData && (
              <>
                <p className="font-bold text-xl mt-4">Select Shop</p>
                <Select
                  placeholder={"Select Shop"}
                  className="mt-3"
                  options={shopOptions && shopOptions}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={selected => {
                    setSelectedShop(selected.value);
                  }}
                />
              </>
            )}
            {!productsLoading && selectedShop && products && (
              <div>
                <p className="font-bold text-xl mt-4">Select Products</p>
                <div className="h-48 overflow-y-auto">
                  {products.organizationProducts.map(product => (
                    <div key={product.uid} className="mt-3">
                      <ProductSelector
                        product={product}
                        setActiveProducts={setActiveProducts}
                        activeProducts={activeProducts}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <button
              type="submit"
              className="mt-5 text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
              disabled={selectedShop.length < 1 && activeProducts.length < 1}
            >
              Add Active Product
            </button>
          </form>
          <button
            onClick={() => setAddActiveProduct(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddActiveProductForm;
