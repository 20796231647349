import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import OrganizationShows from "../../../../apollo/query/admins/organizations/OrganizationShows";
import OrganizationProducts from "../../../../apollo/query/admins/organizations/OrganizationProducts";
import Select from "react-select";
import ProductSelector from "./ProductSelector";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import parseISO from "date-fns/parseISO";
import { DirectUpload } from "@rails/activestorage";
import { DIRECT_UPLOAD_URL } from "../../../../constants/consts";
import { AdminAddShop } from "../../../../apollo/mutation/admins/shops/AdminAddShop";

const AddShopForm = ({ setAddShopForm, refetchShopList }) => {
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedShow, setSelectedShow] = useState("");
  const [selectedContact, setSelectedContact] = useState(null);
  const [shopUid, setShopUid] = useState("");
  const [shopDescription, setShopDescription] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [activeProducts, setActiveProducts] = useState([]);
  const [shopStartDate, setShopStartDate] = useState(new Date());
  const [shopEndDate, setShopEndDate] = useState(new Date());
  const [blob, setBlob] = useState({});
  const [shopName, setShopName] = useState("");
  const [sameDay, setSameDay] = useState(true);
  const [withholdFee, setWithholdFee] = useState(true);

  const isBlobPresent = Object.keys(blob).length > 0;

  const [adminAddShop] = useMutation(AdminAddShop);

  const {
    loading: organizationLoading,
    data: organizations,
    error: getOrganizationsError,
  } = useQuery(GetAllOrganizations);

  const {
    loading: showsLoading,
    data: shows,
    error: showsError,
  } = useQuery(OrganizationShows, {
    variables: {
      organizationUid: selectedOrganization.length > 0 ? selectedOrganization : null,
    },
  });

  const {
    loading: productsLoading,
    data: products,
    error: productsError,
  } = useQuery(OrganizationProducts, {
    variables: {
      organizationUid: selectedOrganization.length > 0 ? selectedOrganization : null,
    },
  });

  const orgOptions =
    !organizationLoading && organizations
      ? organizations.allOrganizations.map(org => {
          return { value: org.uid, label: org.name };
        })
      : null;

  const showOptions =
    !showsLoading && shows
      ? shows.organizationShows.map(show => {
          return { value: show.uid, label: show.name };
        })
      : null;

  //   Upload image to cloud/storage
  function handleShopImageUpload(e) {
    const file = e.target.files[0];
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
      } else {
        setBlob(blob);
      }
    });
  }

  // Sends request to add new shop
  const handleAddShop = () => {
    adminAddShop({
      variables: {
        uid: shopUid,
        name: shopName,
        showUid: selectedShow,
        organizationUid: selectedOrganization,
        startDate: moment(shopStartDate).tz("America/Los_Angeles").format("YYYY-MM-DD"),
        endDate: moment(shopEndDate).tz("America/Los_Angeles").format("YYYY-MM-DD"),
        products: activeProducts,
        enabled: enabled,
        image: isBlobPresent ? blob.signed_id : null,
        description: shopDescription,
        sameDay: sameDay,
        withholdFee: withholdFee,
        contactUid: selectedContact,
      },
    })
      .then(res => {
        setAddShopForm(false);
        refetchShopList();
      })
      .catch(e => console.error(e));
  };

  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            {/* Heading */}
            <p className="text-3xl font-bold ">Add Shop</p>
            <div className="flex flex-col">
              <label htmlFor="name" className="flex flex-col mt-4 font-bold text-xl">
                Name
                <input
                  type="text"
                  id="name"
                  className="font-normal text-base"
                  value={shopName}
                  onChange={e => setShopName(e.target.value)}
                />
              </label>
              <label
                htmlFor="uid"
                className="flex flex-col mt-4 font-bold text-xl"
              >
                Shop UID/URL Slug
                <input
                  type="text"
                  id="uid"
                  className="font-normal text-base"
                  value={shopUid}
                  onChange={e => setShopUid(e.target.value)}
                />
              </label>
              <p className="font-bold text-xl mt-4">Select Organization</p>
              {!organizationLoading && organizations && (
                <Select
                  placeholder={"Select Organization"}
                  className="mt-3"
                  options={orgOptions}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  isClearable={false}
                  onChange={selected => {
                    setSelectedOrganization(selected.value);
                    setSelectedShow("");
                  }}
                />
              )}
              {!organizationLoading && organizations && selectedOrganization && (
                <>
                  <p className="font-bold text-xl mt-4">Select Contact</p>
                  <Select
                    placeholder={"Select Contact"}
                    className="mt-3"
                    options={organizations.allOrganizations.filter(o => o.uid === selectedOrganization)[0].contacts.map(contact => ({value: contact.uid, label: contact.name}))}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => {
                      setSelectedContact(selected.value);
                    }}
                  />
                </>
              )}
              {!showsLoading && selectedOrganization && shows && (
                <>
                  <p className="font-bold text-xl mt-4">Select Show</p>
                  <Select
                    placeholder={"Select Show"}
                    className="mt-3"
                    options={showOptions && showOptions}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => setSelectedShow(selected.value)}
                  />
                </>
              )}
              <div className="flex justify-between w-6/12">
                <div className="flex flex-col mt-3">
                  Shop Start Date
                  <DatePicker
                    className="w-full"
                    selected={shopStartDate}
                    maxDate={parseISO(shopEndDate)}
                    onChange={date => setShopStartDate(date)}
                  />
                </div>
                <div className="flex flex-col mt-3">
                  Shop End Date
                  <DatePicker
                    className="w-full"
                    minDate={shopStartDate}
                    selected={shopEndDate}
                    onChange={date => setShopEndDate(date)}
                  />
                </div>
              </div>
              <label
                htmlFor="description"
                className="flex flex-col mt-4 font-bold text-xl"
              >
                Description
                <input
                  type="text"
                  id="description"
                  className="font-normal text-base"
                  value={shopDescription}
                  onChange={e => setShopDescription(e.target.value)}
                />
              </label>
              {/* Enabled */}
              <label htmlFor="enabled" className="flex mt-4 items-center">
                Enabled
                <input
                  type="checkbox"
                  id="enabled"
                  className="ml-3"
                  value={enabled}
                  onClick={() => setEnabled(!enabled)}
                />
              </label>
              {/* Same Day Delivery */}
              <label htmlFor="same-day" className="flex mt-4 items-center">
                Same Day Delivery
                <input
                  type="checkbox"
                  id="same-day"
                  className="ml-3"
                  value={sameDay /*  */}
                  defaultChecked={sameDay}
                  onClick={() => setSameDay(!sameDay)}
                />
              </label>
              {/* Withold fee */}
              <label htmlFor="withold-fee" className="flex mt-4 items-center">
                Withold Stripe Fee
                <input
                  type="checkbox"
                  id="withold-fee"
                  className="ml-3"
                  value={withholdFee}
                  defaultChecked={withholdFee}
                  onClick={() => setWithholdFee(!withholdFee)}
                />
              </label>
              {/* Image */}
              <label className="mt-4">
                <input type="file" accept="images/*" onChange={handleShopImageUpload} />
              </label>
              {!productsLoading && selectedShow && products && (
                <>
                  <p className="font-bold text-xl mt-4">Select Products</p>
                  {products.organizationProducts.map(product => (
                    <div key={product.uid} className="mt-3">
                      <ProductSelector
                        product={product}
                        setActiveProducts={setActiveProducts}
                        activeProducts={activeProducts}
                      />
                    </div>
                  ))}
                </>
              )}
              <button
                className="mt-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                onClick={handleAddShop}
              >
                Add Shop
              </button>
            </div>
          </div>
          <button
            onClick={() => setAddShopForm(false)}
            className="bg-black h-12 text-white mt-auto mb-5 w-full"
          >
            Close
          </button>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddShopForm;
