import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const Loading = ({ size, color, inline }) => {
  return (
    <div
      className={
        inline
          ? ""
          : " w-screen h-screen flex justify-center items-center my-auto mx-auto"
      }
    >
      <FontAwesomeIcon
        icon={faCircleNotch}
        size={size}
        fixedWidth
        spin
        style={{ color: color }}
      />
    </div>
  );
};

export default Loading;
