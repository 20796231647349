import React from "react";
import Select from "react-select";
import GetShops from "../../../../apollo/query/admins/shops/GetShops";
import GetUsersByOrganization from "../../../../apollo/query/admins/organizations/GetUsersByOrganization";
import GetAllOrganizations from "../../../../apollo/query/admins/organizations/GetAllOrganizations";
import { useQuery, useMutation } from "@apollo/client";
import { AddSubscription } from "../../../../apollo/mutation/admins/shop_notification_subscriptions/AddSubscritpion";

const AddSubscriptionForm = ({ setAddSubscription, refetchSubscriptionList }) => {
  const [selectedUser, setSelectedUser] = React.useState("");
  const [selectedShop, setSelectedShop] = React.useState("");
  const [selectedOrganization, setSelectedOrganization] = React.useState("");
  const [notificationType, setNotificationType] = React.useState("");

  const { loading: shopLoading, data: shops, error: shopError } = useQuery(GetShops);
  const {
    loading: orgLoading,
    data: orgData,
    error: orgError,
  } = useQuery(GetAllOrganizations);
  const {
    loading: userLoading,
    data: users,
    error: userError,
  } = useQuery(GetUsersByOrganization, {
    variables: {
      organizationUid: selectedOrganization.length > 0 ? selectedOrganization : null,
    },
  });

  const [adminAddSubscription] = useMutation(AddSubscription);

  const shopOptions =
    !shopLoading &&
    shops.allShops.map(shop => {
      return {
        value: shop.uid,
        label: (
          <div className="flex">
            <img src={shop.imageUrl} className="w-10 h-10 rounded-full" />
            <div className="flex flex-col ml-5">
              <p className="font-bold">{shop.name}</p>
              <p className="italic">Org: {shop.organization.name}</p>
              <p className="italic">Show: {shop.show.name}</p>
            </div>
          </div>
        ),
      };
    });

  const userOptions =
    !userLoading && users && users.organizationUsers
      ? users.organizationUsers.map(user => {
          return {
            value: user.uid,
            label: user.name,
          };
        })
      : null;

  const orgOptions =
    !orgLoading &&
    orgData.allOrganizations.map(organization => {
      return {
        value: organization.uid,
        label: organization.name,
      };
    });

  const notificationOptions = [
    {
      value: "order_created",
      label: "Order Created",
    },
    {
      value: "order_delivered",
      label: "Order Delivered",
    },
  ];

  const addNewSubscription = () => {
    adminAddSubscription({
      variables: {
        shopUid: selectedShop,
        userUid: selectedUser,
        notificationType: notificationType,
      },
    })
      .then(res => {
        setAddSubscription(false);
        refetchSubscriptionList();
      })
      .catch(e => console.error(e));
  };
  return (
    <>
      <div className="w-10/12  h-97 xl:h-98 sm:h-5/6 px-4 bg-white absolute shadow rounded-lg  bottom-8 top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4 z-50 outline-none focus:outline-none">
        <div className="flex flex-col w-full h-full justify-center items-center mt-4">
          <div className="w-full px-8 h-5/6 overflow-y-auto">
            {/* Heading */}
            <p className="text-3xl font-bold ">Add Subscription</p>
            <div className="flex flex-col">
              <p className="font-bold text-xl mt-4">Select Organization</p>
              <Select
                placeholder={"Select Organization"}
                className="mt-3"
                options={orgOptions && orgOptions}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                isClearable={false}
                onChange={selected => setSelectedOrganization(selected.value)}
              />
              {selectedOrganization && (
                <>
                  <p className="font-bold text-xl mt-4">Select User</p>
                  <Select
                    placeholder={"Select User"}
                    className="mt-3"
                    options={userOptions && userOptions}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => setSelectedUser(selected.value)}
                  />
                </>
              )}
              {selectedUser && (
                <>
                  <p className="font-bold text-xl mt-4">Select Shop</p>
                  <Select
                    placeholder={"Select Shop"}
                    className="mt-3"
                    options={shopOptions && shopOptions}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => setSelectedShop(selected.value)}
                  />
                </>
              )}
              {selectedUser && selectedShop && (
                <>
                  <p className="font-bold text-xl mt-4">Select Notification Type</p>
                  <Select
                    placeholder={"Select Notification Type"}
                    className="mt-3"
                    options={notificationOptions && notificationOptions}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    isClearable={false}
                    onChange={selected => setNotificationType(selected.value)}
                  />
                </>
              )}
              <button
                className="mt-3 mb-3 w-full text-white rounded h-10 md:h-14 shadow px-6 py-1  md:text-base 2xl:text-lg font-bold  bg-button-green"
                onClick={addNewSubscription}
              >
                Add Subscription
              </button>
            </div>
            <button
              onClick={() => setAddSubscription(false)}
              className="bg-black h-12 text-white mt-auto mb-5 w-full"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddSubscriptionForm;
