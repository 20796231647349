import { gql } from "@apollo/client";

export const AdminAddActiveProduct = gql`
  mutation adminAddActiveProduct(
    $shopUid: String!
    $products: [CreateActiveProductInput!]!
  ) {
    adminAddActiveProduct(input: { shopUid: $shopUid, products: $products }) {
      activeProducts {
        uid
      }
      errors
    }
  }
`;
