import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../shared/Loading";
import { useQuery } from "@apollo/client";
import GetShops from "../../../../apollo/query/admins/shops/GetShops";
import ShopCard from "./ShopCard";
import AddShopForm from "./AddShopForm";

const AdminManageShops = () => {
  const { loading, error, data, refetch } = useQuery(GetShops);
  const [addShopForm, setAddShopForm] = React.useState(false);
  const [searchFilter, setSearchFilter] = React.useState("");

  const lowercasedFilter = searchFilter.toLowerCase();

  const filteredData =
    !searchFilter || searchFilter == ""
      ? data?.allShops
      : data?.allShops.filter(
          shop =>
            shop.name.toLowerCase().includes(lowercasedFilter) ||
            shop.organization.name.toLowerCase().includes(lowercasedFilter),
        );

  const handleSearch = e => {
    setSearchFilter(e.target.value);
  };

  return (
    <div className="flex flex-col w-full px-10 mt-12">
      <div className="flex w-full justify-between">
        <p className="font-bold text-xl">All Shops</p>
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="2xl"
          fixedWidth
          className="cursor-pointer text-blue-600"
          onClick={() => {
            setAddShopForm(true);
          }}
        />
      </div>
      {/* Search bar */}
      <div className="flex relative items-center">
        <span
          data-testid="search"
          className="flex inset-y-0 left-0 text-search-gray items-center pl-2 py-1"
        ></span>
        <input
          className="flex items-center border-3 px-2 mr-14 w-full md:w-auto focus:outline-none py-1"
          name="search"
          placeholder="Search"
          onChange={handleSearch}
        />
      </div>
      <div className="flex justify-between z-20 sticky mt-3 px-5 font-bold">
        <p className="flex flex-basis-25 items-center justify-start">Name</p>
        <p className="flex flex-basis-25 items-center justify-center">Organization</p>
        <p className="flex flex-basis-5 items-center justify-center">Enabled</p>
        <p className="flex flex-basis-20 items-center justify-center">
          Same Day Delivery
        </p>
        <p className="flex flex-basis-5 items-center justify-end"></p>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-full w-full">
          <Loading inline={true} color="gray" size="xl" />
        </div>
      )}
      {/* Display all Shops */}
      {data &&
        !loading &&
        filteredData &&
        filteredData.length > 0 &&
        filteredData.map(shop => (
          <div key={shop.uid}>
            <ShopCard shop={shop} />
          </div>
        ))}
      {/* Opens add shop form */}
      {addShopForm && (
        <AddShopForm setAddShopForm={setAddShopForm} refetchShopList={refetch} />
      )}
    </div>
  );
};

export default AdminManageShops;
